/* 
  TODO: Could be funny to show special messages for the following words:
  * wordle
  * the n word, but with the soft 'a' (we're not allowing that, i just don't want to deal with it)
*/
const dictionaryWords: { [key: string]: boolean } = {
	aahed: true,
	aalii: true,
	aargh: true,
	abaca: true,
	abaci: true,
	aback: true,
	abaft: true,
	abaka: true,
	abamp: true,
	abase: true,
	abash: true,
	abate: true,
	abaya: true,
	abbas: true,
	abbes: true,
	abbey: true,
	abbot: true,
	abeam: true,
	abele: true,
	abets: true,
	abhor: true,
	abide: true,
	abled: true,
	abler: true,
	ables: true,
	abmho: true,
	abode: true,
	abohm: true,
	aboil: true,
	aboma: true,
	aboon: true,
	abort: true,
	about: true,
	above: true,
	abris: true,
	abuse: true,
	abuts: true,
	abuzz: true,
	abyes: true,
	abysm: true,
	abyss: true,
	acais: true,
	acari: true,
	acerb: true,
	aceta: true,
	ached: true,
	aches: true,
	achoo: true,
	acids: true,
	acidy: true,
	acing: true,
	acini: true,
	ackee: true,
	acmes: true,
	acmic: true,
	acned: true,
	acnes: true,
	acock: true,
	acold: true,
	acorn: true,
	acred: true,
	acres: true,
	acrid: true,
	acros: true,
	acted: true,
	actin: true,
	actor: true,
	acute: true,
	acyls: true,
	adage: true,
	adapt: true,
	adbot: true,
	addax: true,
	added: true,
	adder: true,
	addle: true,
	adeem: true,
	adept: true,
	adieu: true,
	adios: true,
	adits: true,
	adman: true,
	admen: true,
	admin: true,
	admit: true,
	admix: true,
	adobe: true,
	adobo: true,
	adopt: true,
	adore: true,
	adorn: true,
	adown: true,
	adoze: true,
	adult: true,
	adunc: true,
	adust: true,
	adyta: true,
	adzed: true,
	adzes: true,
	aecia: true,
	aedes: true,
	aegis: true,
	aeons: true,
	aerie: true,
	afars: true,
	affix: true,
	afire: true,
	afoot: true,
	afore: true,
	afoul: true,
	afrit: true,
	afros: true,
	after: true,
	again: true,
	agama: true,
	agape: true,
	agars: true,
	agate: true,
	agave: true,
	agaze: true,
	agene: true,
	agent: true,
	agers: true,
	agger: true,
	aggie: true,
	aggro: true,
	aghas: true,
	agile: true,
	aging: true,
	agios: true,
	agism: true,
	agist: true,
	agita: true,
	aglee: true,
	aglet: true,
	agley: true,
	agloo: true,
	aglow: true,
	aglus: true,
	agmas: true,
	agone: true,
	agons: true,
	agony: true,
	agora: true,
	agree: true,
	agria: true,
	agros: true,
	agued: true,
	agues: true,
	ahead: true,
	ahing: true,
	ahold: true,
	ahull: true,
	aided: true,
	aider: true,
	aides: true,
	ailed: true,
	aimed: true,
	aimer: true,
	aioli: true,
	aired: true,
	airer: true,
	airns: true,
	airth: true,
	airts: true,
	aisle: true,
	aitch: true,
	aiver: true,
	aiyee: true,
	ajies: true,
	ajiva: true,
	ajuga: true,
	akees: true,
	akela: true,
	akene: true,
	alack: true,
	alamo: true,
	aland: true,
	alane: true,
	alang: true,
	alans: true,
	alant: true,
	alarm: true,
	alary: true,
	alate: true,
	albas: true,
	album: true,
	alcid: true,
	alder: true,
	aldol: true,
	alecs: true,
	alefs: true,
	aleph: true,
	alert: true,
	alfas: true,
	algae: true,
	algal: true,
	algas: true,
	algid: true,
	algin: true,
	algor: true,
	algum: true,
	alias: true,
	alibi: true,
	alien: true,
	alifs: true,
	align: true,
	alike: true,
	aline: true,
	alist: true,
	alive: true,
	aliya: true,
	alkie: true,
	alkyd: true,
	alkyl: true,
	allay: true,
	allee: true,
	alley: true,
	allod: true,
	allot: true,
	allow: true,
	alloy: true,
	allyl: true,
	almah: true,
	almas: true,
	almeh: true,
	almes: true,
	almud: true,
	almug: true,
	aloes: true,
	aloft: true,
	aloha: true,
	aloin: true,
	alone: true,
	along: true,
	aloof: true,
	aloud: true,
	alpha: true,
	altar: true,
	alter: true,
	altho: true,
	altos: true,
	alula: true,
	alums: true,
	alvar: true,
	alway: true,
	amahs: true,
	amain: true,
	amass: true,
	amaze: true,
	amber: true,
	ambit: true,
	amble: true,
	ambos: true,
	ambry: true,
	ameba: true,
	ameer: true,
	amend: true,
	amens: true,
	ament: true,
	amias: true,
	amice: true,
	amici: true,
	amide: true,
	amido: true,
	amids: true,
	amies: true,
	amiga: true,
	amigo: true,
	amine: true,
	amino: true,
	amins: true,
	amirs: true,
	amiss: true,
	amity: true,
	ammos: true,
	amnia: true,
	amnic: true,
	amnio: true,
	amoks: true,
	amole: true,
	among: true,
	amort: true,
	amour: true,
	amped: true,
	ample: true,
	amply: true,
	ampul: true,
	amrit: true,
	amuck: true,
	amuse: true,
	amyls: true,
	ancho: true,
	ancon: true,
	andro: true,
	anear: true,
	anele: true,
	anent: true,
	angas: true,
	angel: true,
	anger: true,
	angle: true,
	anglo: true,
	angry: true,
	angst: true,
	anile: true,
	anils: true,
	anima: true,
	anime: true,
	animi: true,
	anion: true,
	anise: true,
	ankhs: true,
	ankle: true,
	ankus: true,
	anlas: true,
	annal: true,
	annas: true,
	annex: true,
	annoy: true,
	annul: true,
	anoas: true,
	anode: true,
	anole: true,
	anomy: true,
	ansae: true,
	antae: true,
	antas: true,
	anted: true,
	antes: true,
	antic: true,
	antis: true,
	antra: true,
	antre: true,
	antsy: true,
	anura: true,
	anvil: true,
	anyon: true,
	aorta: true,
	apace: true,
	apart: true,
	apeak: true,
	apeek: true,
	apers: true,
	apery: true,
	aphid: true,
	aphis: true,
	apian: true,
	aping: true,
	apish: true,
	apnea: true,
	apods: true,
	aport: true,
	appal: true,
	appel: true,
	apple: true,
	apply: true,
	apres: true,
	apron: true,
	apses: true,
	apsis: true,
	apter: true,
	aptly: true,
	aquae: true,
	aquas: true,
	araks: true,
	arame: true,
	arbor: true,
	arced: true,
	archi: true,
	arcus: true,
	ardeb: true,
	ardor: true,
	areae: true,
	areal: true,
	areas: true,
	areca: true,
	areic: true,
	arena: true,
	arene: true,
	arepa: true,
	arete: true,
	argal: true,
	argil: true,
	argle: true,
	argol: true,
	argon: true,
	argot: true,
	argue: true,
	argus: true,
	arhat: true,
	arias: true,
	ariel: true,
	arils: true,
	arise: true,
	arles: true,
	armed: true,
	armer: true,
	armet: true,
	armor: true,
	aroid: true,
	aroma: true,
	arose: true,
	arpen: true,
	arras: true,
	array: true,
	arris: true,
	arrow: true,
	arroz: true,
	arses: true,
	arsis: true,
	arson: true,
	artal: true,
	artel: true,
	artsy: true,
	arums: true,
	arval: true,
	arvos: true,
	aryls: true,
	asana: true,
	ascon: true,
	ascot: true,
	ascus: true,
	asdic: true,
	ashed: true,
	ashen: true,
	ashes: true,
	aside: true,
	asked: true,
	asker: true,
	askew: true,
	askoi: true,
	askos: true,
	aspen: true,
	asper: true,
	aspic: true,
	aspis: true,
	assai: true,
	assay: true,
	asses: true,
	asset: true,
	aster: true,
	astir: true,
	asura: true,
	aswim: true,
	asyla: true,
	ataps: true,
	ataxy: true,
	atigi: true,
	atilt: true,
	atlas: true,
	atman: true,
	atmas: true,
	atoll: true,
	atoms: true,
	atomy: true,
	atone: true,
	atony: true,
	atopy: true,
	atria: true,
	atrip: true,
	attar: true,
	attic: true,
	audad: true,
	audio: true,
	audit: true,
	auger: true,
	aught: true,
	augur: true,
	aulic: true,
	aunts: true,
	aunty: true,
	aurae: true,
	aural: true,
	aurar: true,
	auras: true,
	aurei: true,
	aures: true,
	auric: true,
	auris: true,
	aurum: true,
	autos: true,
	auxin: true,
	avail: true,
	avant: true,
	avast: true,
	avens: true,
	avers: true,
	avert: true,
	avgas: true,
	avian: true,
	avion: true,
	aviso: true,
	avoid: true,
	avows: true,
	await: true,
	awake: true,
	award: true,
	aware: true,
	awash: true,
	awful: true,
	awing: true,
	awned: true,
	awoke: true,
	awols: true,
	axels: true,
	axial: true,
	axile: true,
	axils: true,
	axing: true,
	axiom: true,
	axion: true,
	axite: true,
	axled: true,
	axles: true,
	axman: true,
	axmen: true,
	axone: true,
	axons: true,
	ayahs: true,
	ayaya: true,
	ayins: true,
	azans: true,
	azide: true,
	azido: true,
	azine: true,
	azlon: true,
	azoic: true,
	azole: true,
	azons: true,
	azote: true,
	azoth: true,
	azuki: true,
	azure: true,
	baaed: true,
	baals: true,
	babas: true,
	babel: true,
	babes: true,
	babka: true,
	baboo: true,
	babul: true,
	babus: true,
	bacca: true,
	baccy: true,
	backs: true,
	bacon: true,
	baddy: true,
	badge: true,
	badly: true,
	baffs: true,
	baffy: true,
	bagel: true,
	baggy: true,
	bahts: true,
	bails: true,
	bairn: true,
	baith: true,
	baits: true,
	baiza: true,
	baize: true,
	baked: true,
	baker: true,
	bakes: true,
	balas: true,
	balds: true,
	baldy: true,
	baled: true,
	baler: true,
	bales: true,
	balks: true,
	balky: true,
	balls: true,
	bally: true,
	balms: true,
	balmy: true,
	balsa: true,
	balti: true,
	banal: true,
	banco: true,
	bancs: true,
	banda: true,
	bands: true,
	bandy: true,
	baned: true,
	banes: true,
	bangs: true,
	banjo: true,
	banks: true,
	banns: true,
	banty: true,
	barbe: true,
	barbs: true,
	barca: true,
	barde: true,
	bards: true,
	bared: true,
	barer: true,
	bares: true,
	barfi: true,
	barfs: true,
	barge: true,
	baric: true,
	barks: true,
	barky: true,
	barms: true,
	barmy: true,
	barns: true,
	barny: true,
	baron: true,
	barre: true,
	barry: true,
	barye: true,
	basal: true,
	based: true,
	baser: true,
	bases: true,
	basic: true,
	basil: true,
	basin: true,
	basis: true,
	basks: true,
	bassi: true,
	basso: true,
	bassy: true,
	baste: true,
	basts: true,
	batch: true,
	bated: true,
	bates: true,
	bathe: true,
	baths: true,
	batik: true,
	baton: true,
	batts: true,
	battu: true,
	batty: true,
	bauds: true,
	baulk: true,
	bawds: true,
	bawdy: true,
	bawks: true,
	bawls: true,
	bawns: true,
	bawty: true,
	bayed: true,
	bayer: true,
	bayou: true,
	bazar: true,
	bazoo: true,
	beach: true,
	beads: true,
	beady: true,
	beaks: true,
	beaky: true,
	beals: true,
	beams: true,
	beamy: true,
	beano: true,
	beans: true,
	beard: true,
	bears: true,
	beast: true,
	beats: true,
	beaus: true,
	beaut: true,
	beaux: true,
	bebop: true,
	becap: true,
	becks: true,
	bedad: true,
	bedel: true,
	bedew: true,
	bedim: true,
	beech: true,
	beedi: true,
	beefs: true,
	beefy: true,
	beeps: true,
	beers: true,
	beery: true,
	beets: true,
	befit: true,
	befog: true,
	begad: true,
	began: true,
	begat: true,
	begem: true,
	beget: true,
	begin: true,
	begot: true,
	begum: true,
	begun: true,
	beige: true,
	beigy: true,
	being: true,
	belay: true,
	belch: true,
	belga: true,
	belie: true,
	belle: true,
	bells: true,
	belly: true,
	belon: true,
	below: true,
	belts: true,
	bemas: true,
	bemix: true,
	bench: true,
	bends: true,
	bendy: true,
	benes: true,
	benne: true,
	benni: true,
	benny: true,
	bento: true,
	bents: true,
	beret: true,
	bergs: true,
	berks: true,
	berme: true,
	berms: true,
	berry: true,
	berth: true,
	beryl: true,
	beses: true,
	beset: true,
	besom: true,
	besot: true,
	bests: true,
	betas: true,
	betel: true,
	beths: true,
	beton: true,
	betta: true,
	bevel: true,
	bevor: true,
	bevvy: true,
	bewig: true,
	bezel: true,
	bezil: true,
	bhaji: true,
	bhang: true,
	bhoot: true,
	bhuts: true,
	biali: true,
	bialy: true,
	bibbs: true,
	bibes: true,
	bible: true,
	bicep: true,
	bices: true,
	biddy: true,
	bided: true,
	bider: true,
	bides: true,
	bidet: true,
	bidis: true,
	bield: true,
	biers: true,
	biffs: true,
	biffy: true,
	bifid: true,
	biggy: true,
	bight: true,
	bigly: true,
	bigos: true,
	bigot: true,
	bijou: true,
	biked: true,
	biker: true,
	bikes: true,
	bikie: true,
	bilbo: true,
	bilby: true,
	biles: true,
	bilge: true,
	bilgy: true,
	bilks: true,
	bills: true,
	billy: true,
	bimah: true,
	bimas: true,
	bimbo: true,
	binal: true,
	bindi: true,
	binds: true,
	biner: true,
	bines: true,
	binge: true,
	bingo: true,
	binit: true,
	bints: true,
	biogs: true,
	biome: true,
	biont: true,
	biota: true,
	biped: true,
	bipod: true,
	birch: true,
	birds: true,
	birks: true,
	birle: true,
	birls: true,
	birrs: true,
	birse: true,
	birth: true,
	bises: true,
	bisks: true,
	bison: true,
	bitch: true,
	biter: true,
	bites: true,
	bitsy: true,
	bitts: true,
	bitty: true,
	bizes: true,
	blabs: true,
	black: true,
	blade: true,
	blaff: true,
	blags: true,
	blahs: true,
	blain: true,
	blame: true,
	blams: true,
	bland: true,
	blank: true,
	blare: true,
	blase: true,
	blast: true,
	blate: true,
	blats: true,
	blawn: true,
	blaws: true,
	blaze: true,
	bleak: true,
	blear: true,
	bleat: true,
	blebs: true,
	blech: true,
	bleed: true,
	bleep: true,
	blend: true,
	blent: true,
	bless: true,
	blest: true,
	blets: true,
	blimp: true,
	blimy: true,
	blind: true,
	bling: true,
	blini: true,
	blink: true,
	bliny: true,
	blips: true,
	bliss: true,
	blite: true,
	blitz: true,
	bloat: true,
	blobs: true,
	block: true,
	blocs: true,
	blogs: true,
	bloke: true,
	blond: true,
	blood: true,
	bloom: true,
	bloop: true,
	blots: true,
	blown: true,
	blows: true,
	blowy: true,
	blubs: true,
	blued: true,
	bluer: true,
	blues: true,
	bluet: true,
	bluey: true,
	bluff: true,
	blume: true,
	blunt: true,
	blurb: true,
	blurs: true,
	blurt: true,
	blush: true,
	blype: true,
	board: true,
	boars: true,
	boart: true,
	boast: true,
	boats: true,
	bobby: true,
	bobos: true,
	bocce: true,
	bocci: true,
	bocks: true,
	boded: true,
	bodes: true,
	boeuf: true,
	boffo: true,
	boffs: true,
	bogan: true,
	bogey: true,
	boggy: true,
	bogie: true,
	bogle: true,
	bogus: true,
	bohea: true,
	bohos: true,
	boils: true,
	boing: true,
	boite: true,
	bokeh: true,
	bolar: true,
	bolas: true,
	bolds: true,
	boles: true,
	bolls: true,
	bolos: true,
	bolts: true,
	bolus: true,
	bombe: true,
	bombs: true,
	bonce: true,
	bonds: true,
	boned: true,
	boner: true,
	bones: true,
	boney: true,
	bongo: true,
	bongs: true,
	bonks: true,
	bonne: true,
	bonny: true,
	bonus: true,
	bonze: true,
	boobs: true,
	booby: true,
	booed: true,
	boogy: true,
	books: true,
	booms: true,
	boomy: true,
	boons: true,
	boors: true,
	boost: true,
	booth: true,
	boots: true,
	booty: true,
	booze: true,
	boozy: true,
	boppy: true,
	boral: true,
	boras: true,
	borax: true,
	bored: true,
	borer: true,
	bores: true,
	boric: true,
	borks: true,
	borne: true,
	boron: true,
	borts: true,
	borty: true,
	bortz: true,
	bosks: true,
	bosky: true,
	bosom: true,
	boson: true,
	bossy: true,
	bosun: true,
	botas: true,
	botch: true,
	botel: true,
	bothy: true,
	botts: true,
	bough: true,
	boule: true,
	boult: true,
	bound: true,
	bourg: true,
	bourn: true,
	bouse: true,
	bousy: true,
	bouts: true,
	bovid: true,
	bowed: true,
	bowel: true,
	bower: true,
	bowls: true,
	bowse: true,
	boxed: true,
	boxer: true,
	boxes: true,
	boxla: true,
	boyar: true,
	boyla: true,
	boyos: true,
	bozos: true,
	brace: true,
	brach: true,
	bract: true,
	brads: true,
	braes: true,
	brags: true,
	braid: true,
	brail: true,
	brain: true,
	brake: true,
	braky: true,
	brand: true,
	brank: true,
	brans: true,
	brant: true,
	brash: true,
	brass: true,
	brats: true,
	brava: true,
	brave: true,
	bravi: true,
	bravo: true,
	brawl: true,
	brawn: true,
	braws: true,
	braxy: true,
	brays: true,
	braza: true,
	braze: true,
	bread: true,
	break: true,
	bream: true,
	brede: true,
	breed: true,
	brees: true,
	brens: true,
	brent: true,
	breve: true,
	brews: true,
	briar: true,
	bribe: true,
	brick: true,
	bride: true,
	brief: true,
	brier: true,
	bries: true,
	brigs: true,
	brill: true,
	brims: true,
	brine: true,
	bring: true,
	brink: true,
	brins: true,
	briny: true,
	brios: true,
	brisk: true,
	briss: true,
	brith: true,
	brits: true,
	britt: true,
	broad: true,
	broch: true,
	brock: true,
	broil: true,
	broke: true,
	brome: true,
	bromo: true,
	bronc: true,
	brood: true,
	brook: true,
	broom: true,
	broos: true,
	brose: true,
	brosy: true,
	broth: true,
	brown: true,
	brows: true,
	brugh: true,
	bruin: true,
	bruit: true,
	brume: true,
	brung: true,
	brunt: true,
	brush: true,
	brusk: true,
	brute: true,
	bruts: true,
	bubal: true,
	bubbe: true,
	bubus: true,
	bucko: true,
	bucks: true,
	buddy: true,
	budge: true,
	buffi: true,
	buffo: true,
	buffs: true,
	buffy: true,
	buggy: true,
	bugle: true,
	buhls: true,
	buhrs: true,
	build: true,
	built: true,
	bulbs: true,
	bulge: true,
	bulgy: true,
	bulks: true,
	bulky: true,
	bulla: true,
	bulls: true,
	bully: true,
	bumfs: true,
	bumph: true,
	bumps: true,
	bumpy: true,
	bunas: true,
	bunch: true,
	bunco: true,
	bunds: true,
	bundt: true,
	bungs: true,
	bunko: true,
	bunks: true,
	bunns: true,
	bunny: true,
	bunts: true,
	bunya: true,
	buoys: true,
	buppy: true,
	buran: true,
	buras: true,
	burbs: true,
	burds: true,
	buret: true,
	burfi: true,
	burgh: true,
	burgs: true,
	burin: true,
	burka: true,
	burke: true,
	burks: true,
	burls: true,
	burly: true,
	burns: true,
	burnt: true,
	burps: true,
	burqa: true,
	burro: true,
	burrs: true,
	burry: true,
	bursa: true,
	burse: true,
	burst: true,
	busby: true,
	bused: true,
	buses: true,
	bushy: true,
	busks: true,
	busts: true,
	busty: true,
	butch: true,
	buteo: true,
	butes: true,
	butle: true,
	butoh: true,
	butte: true,
	butts: true,
	butty: true,
	butut: true,
	butyl: true,
	buxom: true,
	buyer: true,
	buzzy: true,
	bwana: true,
	bylaw: true,
	byres: true,
	byrls: true,
	byssi: true,
	bytes: true,
	byway: true,
	cabal: true,
	cabby: true,
	caber: true,
	cabin: true,
	cable: true,
	cabob: true,
	cacao: true,
	cacas: true,
	cache: true,
	cacti: true,
	caddy: true,
	cades: true,
	cadet: true,
	cadge: true,
	cadgy: true,
	cadis: true,
	cadre: true,
	caeca: true,
	cafes: true,
	caffs: true,
	caged: true,
	cager: true,
	cages: true,
	cagey: true,
	cahow: true,
	caids: true,
	cains: true,
	caird: true,
	cairn: true,
	cajon: true,
	caked: true,
	cakes: true,
	cakey: true,
	calfs: true,
	calif: true,
	calix: true,
	calks: true,
	calla: true,
	calls: true,
	calms: true,
	calos: true,
	calve: true,
	calyx: true,
	camas: true,
	camel: true,
	cameo: true,
	cames: true,
	camis: true,
	camos: true,
	campi: true,
	campo: true,
	camps: true,
	campy: true,
	canal: true,
	candy: true,
	caned: true,
	caner: true,
	canes: true,
	canid: true,
	canna: true,
	canny: true,
	canoe: true,
	canon: true,
	canso: true,
	canst: true,
	canto: true,
	cants: true,
	canty: true,
	caped: true,
	caper: true,
	capes: true,
	caphs: true,
	capiz: true,
	capon: true,
	capos: true,
	capot: true,
	capri: true,
	caput: true,
	carat: true,
	carbo: true,
	carbs: true,
	cards: true,
	cared: true,
	carer: true,
	cares: true,
	caret: true,
	carex: true,
	cargo: true,
	carks: true,
	carle: true,
	carls: true,
	carns: true,
	carny: true,
	carob: true,
	carol: true,
	carom: true,
	carpi: true,
	carps: true,
	carrs: true,
	carry: true,
	carse: true,
	carte: true,
	carts: true,
	carve: true,
	casas: true,
	cased: true,
	cases: true,
	casks: true,
	casky: true,
	caste: true,
	casts: true,
	casus: true,
	catch: true,
	cater: true,
	cates: true,
	catty: true,
	cauld: true,
	caulk: true,
	cauls: true,
	cauri: true,
	cause: true,
	cavas: true,
	caved: true,
	caver: true,
	caves: true,
	cavie: true,
	cavil: true,
	cawed: true,
	cease: true,
	cebid: true,
	cecal: true,
	cecum: true,
	cedar: true,
	ceded: true,
	ceder: true,
	cedes: true,
	cedis: true,
	ceiba: true,
	ceili: true,
	ceils: true,
	celeb: true,
	cella: true,
	celli: true,
	cello: true,
	cells: true,
	celom: true,
	celts: true,
	cense: true,
	cento: true,
	cents: true,
	centu: true,
	ceorl: true,
	cepes: true,
	cerci: true,
	cered: true,
	ceres: true,
	ceria: true,
	ceric: true,
	ceros: true,
	certs: true,
	cesta: true,
	cesti: true,
	cetes: true,
	chads: true,
	chafe: true,
	chaff: true,
	chain: true,
	chair: true,
	chais: true,
	chalk: true,
	champ: true,
	chams: true,
	chana: true,
	chang: true,
	chant: true,
	chaos: true,
	chape: true,
	chaps: true,
	chapt: true,
	chard: true,
	chare: true,
	chark: true,
	charm: true,
	charr: true,
	chars: true,
	chart: true,
	chary: true,
	chase: true,
	chasm: true,
	chats: true,
	chaws: true,
	chays: true,
	cheap: true,
	cheat: true,
	check: true,
	cheek: true,
	cheep: true,
	cheer: true,
	chefs: true,
	chela: true,
	chemo: true,
	chems: true,
	chert: true,
	chess: true,
	chest: true,
	cheth: true,
	chevy: true,
	chews: true,
	chewy: true,
	chiao: true,
	chias: true,
	chica: true,
	chick: true,
	chico: true,
	chics: true,
	chide: true,
	chief: true,
	chiel: true,
	child: true,
	chile: true,
	chili: true,
	chill: true,
	chimb: true,
	chime: true,
	chimp: true,
	china: true,
	chine: true,
	ching: true,
	chink: true,
	chino: true,
	chins: true,
	chips: true,
	chirk: true,
	chirm: true,
	chiro: true,
	chirp: true,
	chirr: true,
	chiru: true,
	chits: true,
	chive: true,
	chivy: true,
	chock: true,
	chocs: true,
	choil: true,
	choir: true,
	choke: true,
	choky: true,
	choli: true,
	chomp: true,
	chook: true,
	chops: true,
	chord: true,
	chore: true,
	chose: true,
	chott: true,
	chows: true,
	chubs: true,
	chuck: true,
	chufa: true,
	chuff: true,
	chugs: true,
	chump: true,
	chums: true,
	chunk: true,
	churl: true,
	churn: true,
	churr: true,
	chuse: true,
	chute: true,
	chyle: true,
	chyme: true,
	cibol: true,
	cider: true,
	cigar: true,
	ciggy: true,
	cilia: true,
	cimex: true,
	cinch: true,
	cines: true,
	cinqs: true,
	cions: true,
	circa: true,
	circs: true,
	cires: true,
	cirri: true,
	cisco: true,
	cissy: true,
	cists: true,
	cited: true,
	citer: true,
	cites: true,
	civet: true,
	civic: true,
	civie: true,
	civil: true,
	civvy: true,
	clach: true,
	clack: true,
	clade: true,
	clads: true,
	clags: true,
	claim: true,
	clamp: true,
	clams: true,
	clang: true,
	clank: true,
	clans: true,
	claps: true,
	clapt: true,
	claro: true,
	clary: true,
	clash: true,
	clasp: true,
	class: true,
	clast: true,
	clave: true,
	clavi: true,
	claws: true,
	clays: true,
	clean: true,
	clear: true,
	cleat: true,
	cleek: true,
	clefs: true,
	cleft: true,
	clegs: true,
	clepe: true,
	clept: true,
	clerk: true,
	clews: true,
	click: true,
	cliff: true,
	clift: true,
	climb: true,
	clime: true,
	cline: true,
	cling: true,
	clink: true,
	clint: true,
	clips: true,
	clipt: true,
	cloak: true,
	clock: true,
	clods: true,
	clogs: true,
	clomb: true,
	clomp: true,
	clone: true,
	clonk: true,
	clons: true,
	cloot: true,
	clops: true,
	close: true,
	cloth: true,
	clots: true,
	cloud: true,
	clour: true,
	clout: true,
	clove: true,
	clown: true,
	cloys: true,
	cloze: true,
	clubs: true,
	cluck: true,
	clued: true,
	clues: true,
	clump: true,
	clung: true,
	clunk: true,
	cnida: true,
	coach: true,
	coact: true,
	coady: true,
	coala: true,
	coals: true,
	coaly: true,
	coapt: true,
	coast: true,
	coati: true,
	coats: true,
	cobbs: true,
	cobby: true,
	cobia: true,
	coble: true,
	cobra: true,
	cocas: true,
	cocci: true,
	cocks: true,
	cocky: true,
	cocoa: true,
	cocos: true,
	codas: true,
	codec: true,
	coded: true,
	coden: true,
	coder: true,
	codes: true,
	codex: true,
	codon: true,
	coeds: true,
	coffs: true,
	cogon: true,
	cohoe: true,
	cohog: true,
	cohos: true,
	coifs: true,
	coign: true,
	coils: true,
	coins: true,
	coirs: true,
	coked: true,
	cokes: true,
	colas: true,
	colby: true,
	colds: true,
	coled: true,
	coles: true,
	colic: true,
	colin: true,
	colly: true,
	colog: true,
	colon: true,
	color: true,
	colts: true,
	colza: true,
	comae: true,
	comal: true,
	comas: true,
	combe: true,
	combi: true,
	combo: true,
	combs: true,
	comer: true,
	comes: true,
	comet: true,
	comfy: true,
	comic: true,
	comix: true,
	comma: true,
	commo: true,
	comms: true,
	commy: true,
	compo: true,
	comps: true,
	compt: true,
	comte: true,
	conch: true,
	condo: true,
	coned: true,
	cones: true,
	coney: true,
	conga: true,
	conge: true,
	congo: true,
	conic: true,
	conin: true,
	conks: true,
	conky: true,
	conns: true,
	conte: true,
	conto: true,
	conus: true,
	cooch: true,
	cooed: true,
	cooee: true,
	cooer: true,
	cooey: true,
	coofs: true,
	cooks: true,
	cooky: true,
	cools: true,
	cooly: true,
	coomb: true,
	coons: true,
	coops: true,
	coopt: true,
	coots: true,
	copal: true,
	copay: true,
	coped: true,
	copen: true,
	coper: true,
	copes: true,
	copra: true,
	copse: true,
	copsy: true,
	coqui: true,
	coral: true,
	corby: true,
	cords: true,
	cored: true,
	corer: true,
	cores: true,
	corgi: true,
	coria: true,
	corks: true,
	corky: true,
	corms: true,
	corns: true,
	cornu: true,
	corny: true,
	corps: true,
	corse: true,
	cosec: true,
	coses: true,
	coset: true,
	cosey: true,
	cosie: true,
	costa: true,
	costs: true,
	cotan: true,
	coted: true,
	cotes: true,
	cotta: true,
	couch: true,
	coude: true,
	cough: true,
	could: true,
	count: true,
	coupe: true,
	coups: true,
	court: true,
	couth: true,
	coved: true,
	coven: true,
	cover: true,
	coves: true,
	covet: true,
	covey: true,
	covin: true,
	cowed: true,
	cower: true,
	cowls: true,
	cowry: true,
	coxae: true,
	coxal: true,
	coxed: true,
	coxes: true,
	coyau: true,
	coyed: true,
	coyer: true,
	coyly: true,
	coypu: true,
	cozen: true,
	cozes: true,
	cozey: true,
	cozie: true,
	craal: true,
	crabs: true,
	crack: true,
	craft: true,
	crags: true,
	crake: true,
	cramp: true,
	crams: true,
	crane: true,
	crank: true,
	crape: true,
	craps: true,
	crash: true,
	crass: true,
	crate: true,
	crave: true,
	crawl: true,
	craws: true,
	craze: true,
	crazy: true,
	creak: true,
	cream: true,
	credo: true,
	creds: true,
	creed: true,
	creek: true,
	creel: true,
	creep: true,
	creme: true,
	crepe: true,
	crept: true,
	crepy: true,
	cress: true,
	crest: true,
	crews: true,
	cribs: true,
	crick: true,
	cried: true,
	crier: true,
	cries: true,
	crime: true,
	crimp: true,
	cripe: true,
	crisp: true,
	crits: true,
	croak: true,
	croci: true,
	crock: true,
	crocs: true,
	croft: true,
	crone: true,
	crony: true,
	crook: true,
	croon: true,
	crops: true,
	crore: true,
	cross: true,
	croup: true,
	crowd: true,
	crown: true,
	crows: true,
	croze: true,
	cruck: true,
	crude: true,
	crudo: true,
	cruds: true,
	cruel: true,
	cruet: true,
	cruft: true,
	crumb: true,
	crump: true,
	crunk: true,
	cruor: true,
	crura: true,
	cruse: true,
	crush: true,
	crust: true,
	crwth: true,
	cryer: true,
	crypt: true,
	cubby: true,
	cubeb: true,
	cubed: true,
	cuber: true,
	cubes: true,
	cubic: true,
	cubit: true,
	cuddy: true,
	cuffs: true,
	cuifs: true,
	cuing: true,
	cuish: true,
	cukes: true,
	culch: true,
	culet: true,
	culex: true,
	culls: true,
	cully: true,
	culms: true,
	culpa: true,
	culti: true,
	cults: true,
	cumin: true,
	cunit: true,
	cupel: true,
	cupid: true,
	cuppa: true,
	cuppy: true,
	curbs: true,
	curch: true,
	curds: true,
	curdy: true,
	cured: true,
	curer: true,
	cures: true,
	curet: true,
	curfs: true,
	curia: true,
	curie: true,
	curio: true,
	curls: true,
	curly: true,
	curns: true,
	currs: true,
	curry: true,
	curse: true,
	curst: true,
	curve: true,
	curvy: true,
	cusec: true,
	cushy: true,
	cusks: true,
	cusps: true,
	cusso: true,
	cutch: true,
	cuter: true,
	cutes: true,
	cutey: true,
	cutie: true,
	cutin: true,
	cutis: true,
	cutty: true,
	cutup: true,
	cuvee: true,
	cyano: true,
	cyans: true,
	cyber: true,
	cycad: true,
	cycas: true,
	cycle: true,
	cyclo: true,
	cyder: true,
	cylix: true,
	cymae: true,
	cymar: true,
	cymas: true,
	cymes: true,
	cymol: true,
	cynic: true,
	cysts: true,
	cyton: true,
	czars: true,
	daces: true,
	dacha: true,
	dadas: true,
	daddy: true,
	dados: true,
	daffs: true,
	daffy: true,
	dagga: true,
	dahls: true,
	daily: true,
	dairy: true,
	daisy: true,
	dales: true,
	dally: true,
	daman: true,
	damar: true,
	dames: true,
	damns: true,
	damps: true,
	dance: true,
	dandy: true,
	dangs: true,
	danio: true,
	darbs: true,
	dared: true,
	darer: true,
	dares: true,
	daric: true,
	darks: true,
	darns: true,
	darts: true,
	dashi: true,
	dashy: true,
	dated: true,
	dater: true,
	dates: true,
	datos: true,
	datto: true,
	datum: true,
	daube: true,
	daubs: true,
	dauby: true,
	daunt: true,
	dauts: true,
	daven: true,
	davit: true,
	dawed: true,
	dawen: true,
	dawks: true,
	dawns: true,
	dawts: true,
	dazed: true,
	dazes: true,
	deads: true,
	deair: true,
	deals: true,
	dealt: true,
	deans: true,
	dears: true,
	deary: true,
	deash: true,
	death: true,
	deave: true,
	debag: true,
	debar: true,
	debit: true,
	debts: true,
	debug: true,
	debur: true,
	debut: true,
	debye: true,
	decaf: true,
	decal: true,
	decan: true,
	decay: true,
	decks: true,
	decor: true,
	decos: true,
	decoy: true,
	decry: true,
	dedal: true,
	deeds: true,
	deedy: true,
	deems: true,
	deeps: true,
	deers: true,
	deets: true,
	defat: true,
	defer: true,
	defis: true,
	defog: true,
	degas: true,
	degum: true,
	deice: true,
	deify: true,
	deign: true,
	deils: true,
	deism: true,
	deist: true,
	deity: true,
	deked: true,
	dekes: true,
	dekko: true,
	delay: true,
	deled: true,
	deles: true,
	delfs: true,
	delft: true,
	delis: true,
	dells: true,
	delly: true,
	delta: true,
	delts: true,
	delve: true,
	demes: true,
	demic: true,
	demit: true,
	demob: true,
	demoi: true,
	demon: true,
	demos: true,
	demur: true,
	denar: true,
	denes: true,
	denim: true,
	dense: true,
	dents: true,
	deoxy: true,
	depot: true,
	depth: true,
	derat: true,
	deray: true,
	derby: true,
	derma: true,
	derms: true,
	derry: true,
	desex: true,
	deshi: true,
	desis: true,
	desks: true,
	deter: true,
	detox: true,
	deuce: true,
	devas: true,
	devel: true,
	devil: true,
	devis: true,
	devon: true,
	dewan: true,
	dewar: true,
	dewax: true,
	dewed: true,
	dexes: true,
	dexie: true,
	dhaks: true,
	dhals: true,
	dhikr: true,
	dhobi: true,
	dhole: true,
	dholl: true,
	dhoti: true,
	dhows: true,
	dhuti: true,
	dials: true,
	diary: true,
	diazo: true,
	diced: true,
	dicer: true,
	dices: true,
	dicey: true,
	dicks: true,
	dicky: true,
	dicot: true,
	dicta: true,
	dicty: true,
	didie: true,
	didos: true,
	didst: true,
	diene: true,
	diets: true,
	diffs: true,
	dight: true,
	digit: true,
	diked: true,
	diker: true,
	dikes: true,
	dildo: true,
	dills: true,
	dilly: true,
	dimer: true,
	dimes: true,
	dimly: true,
	dinar: true,
	dined: true,
	diner: true,
	dines: true,
	dinge: true,
	dingo: true,
	dings: true,
	dingy: true,
	dinks: true,
	dinky: true,
	dinos: true,
	dints: true,
	diode: true,
	diols: true,
	dippy: true,
	dipso: true,
	diram: true,
	direr: true,
	dirge: true,
	dirks: true,
	dirls: true,
	dirts: true,
	dirty: true,
	disci: true,
	disco: true,
	discs: true,
	dishy: true,
	disks: true,
	disme: true,
	ditas: true,
	ditch: true,
	dites: true,
	ditsy: true,
	ditto: true,
	ditty: true,
	ditzy: true,
	divan: true,
	divas: true,
	dived: true,
	diver: true,
	dives: true,
	divot: true,
	divvy: true,
	diwan: true,
	dixit: true,
	dizen: true,
	dizzy: true,
	djinn: true,
	djins: true,
	doats: true,
	dobby: true,
	dobes: true,
	dobie: true,
	dobla: true,
	dobra: true,
	docks: true,
	dodge: true,
	dodgy: true,
	dodos: true,
	doers: true,
	doest: true,
	doeth: true,
	doffs: true,
	doges: true,
	dogey: true,
	doggo: true,
	doggy: true,
	dogie: true,
	dogma: true,
	doily: true,
	doing: true,
	doits: true,
	dojos: true,
	dolce: true,
	dolci: true,
	doled: true,
	doles: true,
	dolls: true,
	dolly: true,
	dolma: true,
	dolor: true,
	dolts: true,
	domal: true,
	domed: true,
	domes: true,
	domic: true,
	donas: true,
	donee: true,
	donga: true,
	dongs: true,
	donna: true,
	donne: true,
	donor: true,
	donsy: true,
	donut: true,
	doobs: true,
	doody: true,
	dooly: true,
	dooms: true,
	doomy: true,
	doors: true,
	doozy: true,
	dopas: true,
	doped: true,
	doper: true,
	dopes: true,
	dopey: true,
	dores: true,
	dorks: true,
	dorky: true,
	dorms: true,
	dormy: true,
	dorps: true,
	dorrs: true,
	dorsa: true,
	dorty: true,
	dosai: true,
	dosas: true,
	dosed: true,
	doser: true,
	doses: true,
	dosha: true,
	dotal: true,
	doted: true,
	doter: true,
	dotes: true,
	dotty: true,
	doubt: true,
	douce: true,
	dough: true,
	doula: true,
	douma: true,
	doums: true,
	doura: true,
	douse: true,
	douts: true,
	doven: true,
	doves: true,
	dowdy: true,
	dowed: true,
	dowel: true,
	dower: true,
	dowie: true,
	downs: true,
	downy: true,
	dowry: true,
	dowse: true,
	doxie: true,
	doyen: true,
	doyly: true,
	dozed: true,
	dozen: true,
	dozer: true,
	dozes: true,
	drabs: true,
	draff: true,
	draft: true,
	drags: true,
	drail: true,
	drain: true,
	drake: true,
	drama: true,
	drams: true,
	drank: true,
	drape: true,
	drats: true,
	drave: true,
	drawl: true,
	drawn: true,
	draws: true,
	drays: true,
	dread: true,
	dream: true,
	drear: true,
	dreck: true,
	dreed: true,
	drees: true,
	dregs: true,
	dreks: true,
	dress: true,
	drest: true,
	dribs: true,
	dried: true,
	drier: true,
	dries: true,
	drift: true,
	drill: true,
	drily: true,
	drink: true,
	drips: true,
	dript: true,
	drive: true,
	droid: true,
	droit: true,
	droke: true,
	droll: true,
	drone: true,
	drool: true,
	droop: true,
	drops: true,
	dropt: true,
	dross: true,
	drouk: true,
	drove: true,
	drown: true,
	drubs: true,
	drugs: true,
	druid: true,
	drums: true,
	drunk: true,
	drupe: true,
	druse: true,
	dryad: true,
	dryas: true,
	dryer: true,
	dryly: true,
	duads: true,
	duals: true,
	ducal: true,
	ducat: true,
	duces: true,
	duchy: true,
	ducks: true,
	ducky: true,
	ducts: true,
	duddy: true,
	duded: true,
	dudes: true,
	duels: true,
	duets: true,
	duffs: true,
	dufus: true,
	duits: true,
	duked: true,
	dukes: true,
	dulce: true,
	dulia: true,
	dulls: true,
	dully: true,
	dulse: true,
	dumas: true,
	dumbo: true,
	dumbs: true,
	dumka: true,
	dumky: true,
	dummy: true,
	dumps: true,
	dumpy: true,
	dunam: true,
	dunce: true,
	dunch: true,
	dunes: true,
	dungs: true,
	dungy: true,
	dunks: true,
	dunts: true,
	duomi: true,
	duomo: true,
	duped: true,
	duper: true,
	dupes: true,
	duple: true,
	dural: true,
	duras: true,
	dured: true,
	dures: true,
	durns: true,
	duroc: true,
	duros: true,
	durra: true,
	durrs: true,
	durst: true,
	durum: true,
	dusks: true,
	dusky: true,
	dusts: true,
	dusty: true,
	dutch: true,
	duvet: true,
	dwale: true,
	dwarf: true,
	dweeb: true,
	dwell: true,
	dwelt: true,
	dwine: true,
	dyads: true,
	dyers: true,
	dying: true,
	dyked: true,
	dykes: true,
	dynel: true,
	dynes: true,
	eager: true,
	eagle: true,
	eagre: true,
	eared: true,
	earls: true,
	early: true,
	earns: true,
	earth: true,
	eased: true,
	easel: true,
	easer: true,
	eases: true,
	easts: true,
	eaten: true,
	eater: true,
	eaved: true,
	eaves: true,
	ebbed: true,
	ebbet: true,
	ebons: true,
	ebony: true,
	ebook: true,
	eched: true,
	eches: true,
	echos: true,
	eclat: true,
	ecrus: true,
	edema: true,
	edged: true,
	edger: true,
	edges: true,
	edict: true,
	edify: true,
	edile: true,
	edits: true,
	educe: true,
	educt: true,
	eejit: true,
	eensy: true,
	eerie: true,
	egads: true,
	egers: true,
	egest: true,
	eggar: true,
	egged: true,
	egger: true,
	egret: true,
	eider: true,
	eidos: true,
	eight: true,
	eikon: true,
	eject: true,
	ejido: true,
	eking: true,
	ekkas: true,
	elain: true,
	eland: true,
	elans: true,
	elate: true,
	elbow: true,
	elder: true,
	elect: true,
	elegy: true,
	elemi: true,
	elfin: true,
	elide: true,
	elint: true,
	elite: true,
	eloin: true,
	elope: true,
	elude: true,
	elute: true,
	elven: true,
	elver: true,
	elves: true,
	email: true,
	embar: true,
	embay: true,
	embed: true,
	ember: true,
	embow: true,
	emcee: true,
	emeer: true,
	emend: true,
	emerg: true,
	emery: true,
	emeus: true,
	emics: true,
	emirs: true,
	emits: true,
	emmer: true,
	emmet: true,
	emoji: true,
	emote: true,
	empty: true,
	emyde: true,
	emyds: true,
	enact: true,
	enate: true,
	ended: true,
	ender: true,
	endow: true,
	endue: true,
	enema: true,
	enemy: true,
	enjoy: true,
	ennui: true,
	enoki: true,
	enols: true,
	enorm: true,
	enows: true,
	enrol: true,
	ensky: true,
	ensue: true,
	enter: true,
	entia: true,
	entry: true,
	enure: true,
	envoi: true,
	envoy: true,
	enzym: true,
	eosin: true,
	epact: true,
	epees: true,
	ephah: true,
	ephas: true,
	ephod: true,
	ephor: true,
	epics: true,
	epoch: true,
	epode: true,
	epoxy: true,
	equal: true,
	eques: true,
	equid: true,
	equip: true,
	erase: true,
	erect: true,
	ergot: true,
	erica: true,
	ernes: true,
	erode: true,
	erose: true,
	erred: true,
	error: true,
	erses: true,
	eruct: true,
	erugo: true,
	erupt: true,
	eruvs: true,
	ervil: true,
	escar: true,
	escot: true,
	eskar: true,
	esker: true,
	esnes: true,
	essay: true,
	esses: true,
	ester: true,
	estop: true,
	etape: true,
	ether: true,
	ethic: true,
	ethos: true,
	ethyl: true,
	etics: true,
	etnas: true,
	etude: true,
	etuis: true,
	etwee: true,
	etyma: true,
	euros: true,
	evade: true,
	evens: true,
	event: true,
	evert: true,
	every: true,
	evict: true,
	evils: true,
	evite: true,
	evoke: true,
	ewers: true,
	exact: true,
	exalt: true,
	exams: true,
	excel: true,
	execs: true,
	exert: true,
	exile: true,
	exine: true,
	exing: true,
	exist: true,
	exits: true,
	exome: true,
	exons: true,
	expat: true,
	expel: true,
	expos: true,
	extol: true,
	extra: true,
	exude: true,
	exult: true,
	exurb: true,
	eyass: true,
	eyers: true,
	eying: true,
	eyras: true,
	eyres: true,
	eyrie: true,
	eyrir: true,
	fable: true,
	faced: true,
	facer: true,
	faces: true,
	facet: true,
	facia: true,
	facta: true,
	facts: true,
	faddy: true,
	faded: true,
	fader: true,
	fades: true,
	fadge: true,
	fados: true,
	faena: true,
	faery: true,
	faffs: true,
	fagin: true,
	fagot: true,
	fails: true,
	faint: true,
	fairs: true,
	fairy: true,
	faith: true,
	faked: true,
	faker: true,
	fakes: true,
	fakey: true,
	fakie: true,
	fakir: true,
	falls: true,
	false: true,
	famed: true,
	fames: true,
	fancy: true,
	fanes: true,
	fanga: true,
	fangs: true,
	fanny: true,
	fanon: true,
	fanos: true,
	fanum: true,
	faqir: true,
	farad: true,
	farce: true,
	farci: true,
	farcy: true,
	fards: true,
	fared: true,
	farer: true,
	fares: true,
	farle: true,
	farls: true,
	farms: true,
	faros: true,
	farro: true,
	farts: true,
	fasts: true,
	fatal: true,
	fated: true,
	fates: true,
	fatly: true,
	fatty: true,
	fatwa: true,
	faugh: true,
	fauld: true,
	fault: true,
	fauna: true,
	fauns: true,
	fauve: true,
	favas: true,
	faves: true,
	favor: true,
	favus: true,
	fawns: true,
	fawny: true,
	faxed: true,
	faxes: true,
	fayed: true,
	fazed: true,
	fazes: true,
	fears: true,
	fease: true,
	feast: true,
	feats: true,
	feaze: true,
	fecal: true,
	feces: true,
	fecks: true,
	fedex: true,
	feebs: true,
	feeds: true,
	feels: true,
	feeze: true,
	feign: true,
	feint: true,
	feist: true,
	felid: true,
	fella: true,
	fells: true,
	felly: true,
	felon: true,
	felts: true,
	felty: true,
	femes: true,
	femme: true,
	femur: true,
	fence: true,
	fends: true,
	fenny: true,
	feods: true,
	feoff: true,
	feral: true,
	feres: true,
	feria: true,
	ferly: true,
	fermi: true,
	ferns: true,
	ferny: true,
	ferry: true,
	fesse: true,
	festa: true,
	fests: true,
	fetal: true,
	fetas: true,
	fetch: true,
	feted: true,
	fetes: true,
	fetid: true,
	fetor: true,
	fetus: true,
	feuar: true,
	feuds: true,
	feued: true,
	fever: true,
	fewer: true,
	feyer: true,
	feyly: true,
	fezes: true,
	fezzy: true,
	fiars: true,
	fiats: true,
	fiber: true,
	fibre: true,
	fices: true,
	fiche: true,
	fichu: true,
	ficin: true,
	ficus: true,
	fidge: true,
	fidos: true,
	fiefs: true,
	field: true,
	fiend: true,
	fiery: true,
	fifed: true,
	fifer: true,
	fifes: true,
	fifth: true,
	fifty: true,
	fight: true,
	filar: true,
	filch: true,
	filed: true,
	filer: true,
	files: true,
	filet: true,
	filks: true,
	fille: true,
	fillo: true,
	fills: true,
	filly: true,
	filmi: true,
	films: true,
	filmy: true,
	filos: true,
	filth: true,
	filum: true,
	final: true,
	finca: true,
	finch: true,
	finds: true,
	fined: true,
	finer: true,
	fines: true,
	finis: true,
	finks: true,
	finny: true,
	finos: true,
	fiord: true,
	fique: true,
	fired: true,
	firer: true,
	fires: true,
	firms: true,
	firns: true,
	firry: true,
	first: true,
	firth: true,
	fiscs: true,
	fishy: true,
	fists: true,
	fitch: true,
	fitly: true,
	fiver: true,
	fives: true,
	fixed: true,
	fixer: true,
	fixes: true,
	fixit: true,
	fizzy: true,
	fjeld: true,
	fjord: true,
	flabs: true,
	flack: true,
	flags: true,
	flail: true,
	flair: true,
	flake: true,
	flaky: true,
	flame: true,
	flams: true,
	flamy: true,
	flank: true,
	flans: true,
	flaps: true,
	flare: true,
	flash: true,
	flask: true,
	flats: true,
	flava: true,
	flaws: true,
	flawy: true,
	flaxy: true,
	flays: true,
	fleam: true,
	fleas: true,
	fleck: true,
	fleer: true,
	flees: true,
	fleet: true,
	flesh: true,
	flews: true,
	fleys: true,
	flick: true,
	flics: true,
	flied: true,
	flier: true,
	flies: true,
	fling: true,
	flint: true,
	flips: true,
	flirs: true,
	flirt: true,
	flite: true,
	flits: true,
	float: true,
	flock: true,
	flocs: true,
	floes: true,
	flogs: true,
	flong: true,
	flood: true,
	floor: true,
	flops: true,
	flora: true,
	floss: true,
	flota: true,
	flour: true,
	flout: true,
	flown: true,
	flows: true,
	flubs: true,
	flued: true,
	flues: true,
	fluff: true,
	fluid: true,
	fluke: true,
	fluky: true,
	flume: true,
	flump: true,
	flung: true,
	flunk: true,
	fluor: true,
	flush: true,
	flute: true,
	fluty: true,
	fluyt: true,
	flyby: true,
	flyer: true,
	flyte: true,
	foals: true,
	foams: true,
	foamy: true,
	focal: true,
	focus: true,
	foehn: true,
	fogey: true,
	foggy: true,
	fogie: true,
	fohns: true,
	foils: true,
	foins: true,
	foist: true,
	folds: true,
	foley: true,
	folia: true,
	folic: true,
	folio: true,
	folks: true,
	folky: true,
	folly: true,
	fonds: true,
	fondu: true,
	fonts: true,
	foods: true,
	fools: true,
	foots: true,
	footy: true,
	foram: true,
	foray: true,
	forbs: true,
	forby: true,
	force: true,
	fordo: true,
	fords: true,
	fores: true,
	forex: true,
	forge: true,
	forgo: true,
	forks: true,
	forky: true,
	forme: true,
	forms: true,
	forte: true,
	forth: true,
	forts: true,
	forty: true,
	forum: true,
	fossa: true,
	fosse: true,
	fouls: true,
	found: true,
	fount: true,
	fours: true,
	fovea: true,
	fowls: true,
	foxed: true,
	foxes: true,
	foyer: true,
	frack: true,
	frags: true,
	frail: true,
	frame: true,
	franc: true,
	frank: true,
	fraps: true,
	frass: true,
	frats: true,
	fraud: true,
	frays: true,
	freak: true,
	freed: true,
	freer: true,
	frees: true,
	fremd: true,
	frena: true,
	frere: true,
	fresh: true,
	frets: true,
	friar: true,
	fried: true,
	frier: true,
	fries: true,
	frigs: true,
	frill: true,
	frise: true,
	frisk: true,
	frith: true,
	frits: true,
	fritt: true,
	fritz: true,
	frizz: true,
	frock: true,
	froes: true,
	frogs: true,
	frond: true,
	frons: true,
	front: true,
	frore: true,
	frosh: true,
	frost: true,
	froth: true,
	frown: true,
	frows: true,
	froze: true,
	frugs: true,
	fruit: true,
	frump: true,
	fryer: true,
	fubsy: true,
	fucus: true,
	fucks: true,
	fuddy: true,
	fudge: true,
	fudgy: true,
	fuels: true,
	fugal: true,
	fuggy: true,
	fugio: true,
	fugle: true,
	fugue: true,
	fugus: true,
	fujis: true,
	fulls: true,
	fully: true,
	fumed: true,
	fumer: true,
	fumes: true,
	fumet: true,
	fundi: true,
	funds: true,
	fungi: true,
	fungo: true,
	funks: true,
	funky: true,
	funny: true,
	furan: true,
	furls: true,
	furor: true,
	furry: true,
	furze: true,
	furzy: true,
	fused: true,
	fusee: true,
	fusel: true,
	fuses: true,
	fusil: true,
	fussy: true,
	fusty: true,
	futon: true,
	fuzed: true,
	fuzee: true,
	fuzes: true,
	fuzil: true,
	fuzzy: true,
	fyces: true,
	fykes: true,
	fytte: true,
	gabba: true,
	gabby: true,
	gable: true,
	gaddi: true,
	gadid: true,
	gadis: true,
	gaffe: true,
	gaffs: true,
	gaged: true,
	gager: true,
	gages: true,
	gaily: true,
	gains: true,
	gaits: true,
	galah: true,
	galas: true,
	galax: true,
	galea: true,
	galed: true,
	gales: true,
	galls: true,
	gally: true,
	galop: true,
	gamas: true,
	gamay: true,
	gamba: true,
	gambe: true,
	gambs: true,
	gamed: true,
	gamer: true,
	games: true,
	gamey: true,
	gamic: true,
	gamin: true,
	gamma: true,
	gammy: true,
	gamps: true,
	gamut: true,
	ganef: true,
	ganev: true,
	gangs: true,
	ganja: true,
	ganof: true,
	gaols: true,
	gaped: true,
	gaper: true,
	gapes: true,
	gappy: true,
	garbs: true,
	garda: true,
	garni: true,
	garth: true,
	gases: true,
	gasps: true,
	gassy: true,
	gasts: true,
	gatch: true,
	gated: true,
	gater: true,
	gates: true,
	gator: true,
	gauch: true,
	gauds: true,
	gaudy: true,
	gauge: true,
	gault: true,
	gaums: true,
	gaunt: true,
	gaurs: true,
	gauss: true,
	gauze: true,
	gauzy: true,
	gavel: true,
	gavot: true,
	gawks: true,
	gawky: true,
	gawps: true,
	gawsy: true,
	gayal: true,
	gayer: true,
	gayly: true,
	gazar: true,
	gazed: true,
	gazer: true,
	gazes: true,
	gears: true,
	gecko: true,
	gecks: true,
	geeks: true,
	geeky: true,
	geese: true,
	geest: true,
	gelds: true,
	gelee: true,
	gelid: true,
	gelly: true,
	gelts: true,
	gemma: true,
	gemmy: true,
	gemot: true,
	genes: true,
	genet: true,
	genic: true,
	genie: true,
	genii: true,
	genip: true,
	genoa: true,
	genom: true,
	genre: true,
	genro: true,
	gents: true,
	genua: true,
	genus: true,
	geode: true,
	geoid: true,
	gerah: true,
	germs: true,
	germy: true,
	gesso: true,
	geste: true,
	gests: true,
	getas: true,
	getup: true,
	geums: true,
	ghast: true,
	ghats: true,
	ghaut: true,
	ghazi: true,
	ghees: true,
	ghost: true,
	ghoul: true,
	ghyll: true,
	giant: true,
	gibed: true,
	giber: true,
	gibes: true,
	giddy: true,
	gifts: true,
	gigas: true,
	gighe: true,
	gigot: true,
	gigue: true,
	gilds: true,
	gills: true,
	gilly: true,
	gilts: true,
	gimel: true,
	gimme: true,
	gimps: true,
	gimpy: true,
	ginch: true,
	ginks: true,
	ginny: true,
	gipon: true,
	gipsy: true,
	girds: true,
	girls: true,
	girly: true,
	girns: true,
	giron: true,
	giros: true,
	girsh: true,
	girth: true,
	girts: true,
	gismo: true,
	gists: true,
	gitch: true,
	gites: true,
	given: true,
	giver: true,
	gives: true,
	gizmo: true,
	glace: true,
	glade: true,
	glads: true,
	glady: true,
	glair: true,
	glams: true,
	gland: true,
	glans: true,
	glare: true,
	glary: true,
	glass: true,
	glaze: true,
	glazy: true,
	gleam: true,
	glean: true,
	gleba: true,
	glebe: true,
	glede: true,
	gleds: true,
	gleed: true,
	gleek: true,
	glees: true,
	gleet: true,
	glens: true,
	gleys: true,
	glial: true,
	glias: true,
	glide: true,
	gliff: true,
	glime: true,
	glims: true,
	glint: true,
	glitz: true,
	gloam: true,
	gloat: true,
	globe: true,
	globs: true,
	glogg: true,
	gloms: true,
	gloom: true,
	gloop: true,
	glops: true,
	glory: true,
	gloss: true,
	glost: true,
	glout: true,
	glove: true,
	glows: true,
	gloze: true,
	glued: true,
	gluer: true,
	glues: true,
	gluey: true,
	glugs: true,
	glume: true,
	glums: true,
	gluon: true,
	glute: true,
	gluts: true,
	glyph: true,
	gnarl: true,
	gnarr: true,
	gnars: true,
	gnash: true,
	gnats: true,
	gnawn: true,
	gnaws: true,
	gnome: true,
	goads: true,
	goals: true,
	goats: true,
	goaty: true,
	goban: true,
	gobos: true,
	godet: true,
	godly: true,
	goers: true,
	goest: true,
	goeth: true,
	gofer: true,
	gogos: true,
	going: true,
	gojis: true,
	golds: true,
	golem: true,
	golfs: true,
	golly: true,
	gombo: true,
	gomer: true,
	gonad: true,
	gonch: true,
	gonef: true,
	goner: true,
	gongs: true,
	gonia: true,
	gonif: true,
	gonof: true,
	gonzo: true,
	goods: true,
	goody: true,
	gooey: true,
	goofs: true,
	goofy: true,
	gooks: true,
	gooky: true,
	goons: true,
	goony: true,
	goops: true,
	goopy: true,
	goose: true,
	goosy: true,
	gopik: true,
	goral: true,
	gored: true,
	gores: true,
	gorge: true,
	gorms: true,
	gorps: true,
	gorse: true,
	gorsy: true,
	gotch: true,
	goths: true,
	gouge: true,
	gourd: true,
	gouts: true,
	gouty: true,
	gowan: true,
	gowds: true,
	gowks: true,
	gowns: true,
	goxes: true,
	graal: true,
	grabs: true,
	grace: true,
	grade: true,
	grads: true,
	graft: true,
	grail: true,
	grain: true,
	grama: true,
	gramp: true,
	grams: true,
	grana: true,
	grand: true,
	grans: true,
	grant: true,
	grape: true,
	graph: true,
	grapy: true,
	grasp: true,
	grass: true,
	grate: true,
	grave: true,
	gravy: true,
	grays: true,
	graze: true,
	great: true,
	grebe: true,
	greed: true,
	greek: true,
	green: true,
	grees: true,
	greet: true,
	grego: true,
	greys: true,
	gride: true,
	grids: true,
	grief: true,
	griff: true,
	grift: true,
	grigs: true,
	grill: true,
	grime: true,
	grimy: true,
	grind: true,
	grins: true,
	griot: true,
	gripe: true,
	grips: true,
	gript: true,
	gripy: true,
	grist: true,
	grith: true,
	grits: true,
	groan: true,
	groat: true,
	grody: true,
	grogs: true,
	groin: true,
	chode: true,
	groks: true,
	groom: true,
	grope: true,
	gross: true,
	grosz: true,
	grots: true,
	group: true,
	grout: true,
	grove: true,
	grovy: true,
	growl: true,
	grown: true,
	grows: true,
	grrrl: true,
	grubs: true,
	gruel: true,
	grues: true,
	gruff: true,
	grume: true,
	grump: true,
	grunt: true,
	guaco: true,
	guano: true,
	guans: true,
	guard: true,
	guars: true,
	guava: true,
	gucks: true,
	gudes: true,
	guess: true,
	guest: true,
	guffs: true,
	guide: true,
	guids: true,
	guild: true,
	guile: true,
	guilt: true,
	guiro: true,
	guise: true,
	gulag: true,
	gular: true,
	gulch: true,
	gules: true,
	gulfs: true,
	gulfy: true,
	gulls: true,
	gully: true,
	gulps: true,
	gulpy: true,
	gumbo: true,
	gumma: true,
	gummi: true,
	gummy: true,
	gunge: true,
	gungy: true,
	gunks: true,
	gunky: true,
	gunny: true,
	guppy: true,
	gurdy: true,
	gurge: true,
	gurry: true,
	gursh: true,
	gurus: true,
	gushy: true,
	gussy: true,
	gusto: true,
	gusts: true,
	gusty: true,
	gutsy: true,
	gutta: true,
	gutty: true,
	guyed: true,
	guyot: true,
	gwine: true,
	gybed: true,
	gybes: true,
	gynie: true,
	gynos: true,
	gyoza: true,
	gypos: true,
	gyppo: true,
	gypsy: true,
	gyral: true,
	gyred: true,
	gyres: true,
	gyron: true,
	gyros: true,
	gyrus: true,
	gyved: true,
	gyves: true,
	haafs: true,
	haars: true,
	habit: true,
	habus: true,
	hacek: true,
	hacks: true,
	hadal: true,
	haded: true,
	hades: true,
	hadji: true,
	hadst: true,
	haems: true,
	haets: true,
	hafiz: true,
	hafts: true,
	hahas: true,
	haick: true,
	haika: true,
	haiks: true,
	haiku: true,
	hails: true,
	haint: true,
	hairs: true,
	hairy: true,
	hajes: true,
	hajis: true,
	hajji: true,
	hakes: true,
	hakim: true,
	hakus: true,
	halal: true,
	haled: true,
	haler: true,
	hales: true,
	halid: true,
	hallo: true,
	halls: true,
	halma: true,
	halms: true,
	halon: true,
	halos: true,
	halts: true,
	halva: true,
	halve: true,
	halwa: true,
	hamal: true,
	hames: true,
	hammy: true,
	hamza: true,
	hance: true,
	hands: true,
	handy: true,
	hangs: true,
	hanks: true,
	hanky: true,
	hansa: true,
	hanse: true,
	hants: true,
	hapax: true,
	haply: true,
	happi: true,
	happy: true,
	haram: true,
	hards: true,
	hardy: true,
	hared: true,
	harem: true,
	hares: true,
	harks: true,
	harls: true,
	harms: true,
	harps: true,
	harpy: true,
	harry: true,
	harsh: true,
	harts: true,
	hasps: true,
	haste: true,
	hasty: true,
	hatch: true,
	hated: true,
	hater: true,
	hates: true,
	haugh: true,
	haulm: true,
	hauls: true,
	haunt: true,
	haute: true,
	haven: true,
	haver: true,
	haves: true,
	havoc: true,
	hawed: true,
	hawks: true,
	hawse: true,
	hayed: true,
	hayer: true,
	hayey: true,
	hazan: true,
	hazed: true,
	hazel: true,
	hazer: true,
	hazes: true,
	heads: true,
	heady: true,
	heals: true,
	heaps: true,
	heapy: true,
	heard: true,
	hears: true,
	heart: true,
	heath: true,
	heats: true,
	heave: true,
	heavy: true,
	hecks: true,
	heder: true,
	hedge: true,
	hedgy: true,
	heeds: true,
	heels: true,
	heeze: true,
	hefts: true,
	hefty: true,
	heigh: true,
	heils: true,
	heirs: true,
	heist: true,
	helio: true,
	helix: true,
	hello: true,
	hells: true,
	helms: true,
	helos: true,
	helot: true,
	helps: true,
	helve: true,
	hemal: true,
	hemes: true,
	hemic: true,
	hemin: true,
	hemps: true,
	hempy: true,
	hence: true,
	henge: true,
	henna: true,
	henry: true,
	hents: true,
	herbs: true,
	herby: true,
	herds: true,
	heres: true,
	herls: true,
	herma: true,
	herms: true,
	herns: true,
	heron: true,
	heros: true,
	herry: true,
	hertz: true,
	hests: true,
	heths: true,
	heuch: true,
	heugh: true,
	hevea: true,
	hewed: true,
	hewer: true,
	hexad: true,
	hexed: true,
	hexer: true,
	hexes: true,
	hexyl: true,
	hicks: true,
	hided: true,
	hider: true,
	hides: true,
	highs: true,
	hight: true,
	hijab: true,
	hijra: true,
	hiked: true,
	hiker: true,
	hikes: true,
	hilar: true,
	hillo: true,
	hills: true,
	hilly: true,
	hilts: true,
	hilum: true,
	hilus: true,
	himbo: true,
	hinds: true,
	hinge: true,
	hinky: true,
	hinny: true,
	hints: true,
	hiply: true,
	hippo: true,
	hippy: true,
	hired: true,
	hiree: true,
	hirer: true,
	hires: true,
	hissy: true,
	hists: true,
	hitch: true,
	hived: true,
	hives: true,
	hoagy: true,
	hoard: true,
	hoars: true,
	hoary: true,
	hobby: true,
	hobos: true,
	hocks: true,
	hocus: true,
	hodad: true,
	hoers: true,
	hogan: true,
	hoggs: true,
	hoick: true,
	hoise: true,
	hoist: true,
	hoked: true,
	hokes: true,
	hokey: true,
	hokku: true,
	hokum: true,
	holds: true,
	holed: true,
	holes: true,
	holey: true,
	holks: true,
	holla: true,
	hollo: true,
	holly: true,
	holme: true,
	holms: true,
	holos: true,
	holts: true,
	homas: true,
	homed: true,
	homer: true,
	homes: true,
	homey: true,
	homie: true,
	homos: true,
	honan: true,
	honda: true,
	honed: true,
	honer: true,
	hones: true,
	honey: true,
	hongi: true,
	hongs: true,
	honks: true,
	honor: true,
	hooch: true,
	hoods: true,
	hoody: true,
	hooey: true,
	hoofs: true,
	hooka: true,
	hooks: true,
	hooky: true,
	hooly: true,
	hoops: true,
	hoots: true,
	hooty: true,
	hopak: true,
	hoped: true,
	hoper: true,
	hopes: true,
	hoppy: true,
	horah: true,
	horal: true,
	horas: true,
	horde: true,
	horks: true,
	horns: true,
	horny: true,
	horse: true,
	horst: true,
	horsy: true,
	hosed: true,
	hosel: true,
	hosen: true,
	hoser: true,
	hoses: true,
	hosey: true,
	hosta: true,
	hosts: true,
	hotch: true,
	hotel: true,
	hotly: true,
	hotty: true,
	hound: true,
	houri: true,
	hours: true,
	house: true,
	hovel: true,
	hover: true,
	howdy: true,
	howes: true,
	howff: true,
	howfs: true,
	howks: true,
	howls: true,
	hoyas: true,
	hoyle: true,
	hubby: true,
	hucks: true,
	huffs: true,
	huffy: true,
	huger: true,
	huggy: true,
	hulas: true,
	hulks: true,
	hulky: true,
	hullo: true,
	hulls: true,
	human: true,
	humic: true,
	humid: true,
	humor: true,
	humph: true,
	humps: true,
	humpy: true,
	humus: true,
	hunch: true,
	hunks: true,
	hunky: true,
	hunts: true,
	hurds: true,
	hurls: true,
	hurly: true,
	hurry: true,
	hurst: true,
	hurts: true,
	husks: true,
	husky: true,
	hussy: true,
	hutch: true,
	huzza: true,
	hwyls: true,
	hydra: true,
	hydro: true,
	hyena: true,
	hying: true,
	hylas: true,
	hymen: true,
	hymns: true,
	hyoid: true,
	hyped: true,
	hyper: true,
	hypes: true,
	hypha: true,
	hypos: true,
	hyrax: true,
	hyson: true,
	iambi: true,
	iambs: true,
	ichor: true,
	icier: true,
	icily: true,
	icing: true,
	icker: true,
	icons: true,
	ictic: true,
	ictus: true,
	ideal: true,
	ideas: true,
	ident: true,
	idiom: true,
	idiot: true,
	idled: true,
	idler: true,
	idles: true,
	idols: true,
	idyll: true,
	idyls: true,
	igged: true,
	igloo: true,
	iglus: true,
	ihram: true,
	ikats: true,
	ikons: true,
	ileac: true,
	ileal: true,
	ileum: true,
	ileus: true,
	iliac: true,
	iliad: true,
	ilial: true,
	ilium: true,
	iller: true,
	image: true,
	imago: true,
	imams: true,
	imaum: true,
	imbed: true,
	imbue: true,
	imide: true,
	imido: true,
	imids: true,
	imine: true,
	imino: true,
	immix: true,
	imped: true,
	impel: true,
	impis: true,
	imply: true,
	impro: true,
	inane: true,
	inapt: true,
	inarm: true,
	inbox: true,
	inbye: true,
	incog: true,
	incur: true,
	incus: true,
	index: true,
	indie: true,
	indol: true,
	indow: true,
	indri: true,
	indue: true,
	inept: true,
	inert: true,
	infer: true,
	infix: true,
	infos: true,
	infra: true,
	ingle: true,
	ingot: true,
	inion: true,
	inked: true,
	inker: true,
	inkle: true,
	inlay: true,
	inlet: true,
	inned: true,
	inner: true,
	input: true,
	inrun: true,
	inset: true,
	inter: true,
	intis: true,
	intro: true,
	inure: true,
	inurn: true,
	invar: true,
	iodic: true,
	iodid: true,
	iodin: true,
	ionic: true,
	iotas: true,
	irade: true,
	irate: true,
	irids: true,
	iring: true,
	irked: true,
	iroko: true,
	irone: true,
	irons: true,
	irony: true,
	isbas: true,
	isled: true,
	isles: true,
	islet: true,
	issei: true,
	issue: true,
	istle: true,
	itchy: true,
	items: true,
	ither: true,
	ivied: true,
	ivies: true,
	ivory: true,
	ixias: true,
	ixnay: true,
	ixora: true,
	ixtle: true,
	izard: true,
	izars: true,
	jabot: true,
	jacal: true,
	jacks: true,
	jacky: true,
	jaded: true,
	jades: true,
	jager: true,
	jaggs: true,
	jaggy: true,
	jagra: true,
	jails: true,
	jakes: true,
	jalap: true,
	jalop: true,
	jambe: true,
	jambs: true,
	jammy: true,
	janes: true,
	janny: true,
	janty: true,
	japan: true,
	japed: true,
	japer: true,
	japes: true,
	jarls: true,
	jatos: true,
	jauks: true,
	jaunt: true,
	jaups: true,
	javas: true,
	jawan: true,
	jawed: true,
	jazzy: true,
	jeans: true,
	jebel: true,
	jeeps: true,
	jeers: true,
	jeeze: true,
	jefes: true,
	jehad: true,
	jehus: true,
	jells: true,
	jelly: true,
	jemmy: true,
	jenny: true,
	jerid: true,
	jerks: true,
	jerky: true,
	jerry: true,
	jesse: true,
	jests: true,
	jetes: true,
	jeton: true,
	jetty: true,
	jewel: true,
	jibba: true,
	jibbs: true,
	jibed: true,
	jiber: true,
	jibes: true,
	jiffs: true,
	jiffy: true,
	jiggy: true,
	jihad: true,
	jills: true,
	jilts: true,
	jimmy: true,
	jimpy: true,
	jingo: true,
	jinks: true,
	jinni: true,
	jinns: true,
	jirds: true,
	jived: true,
	jiver: true,
	jives: true,
	jivey: true,
	jnana: true,
	jocko: true,
	jocks: true,
	jocky: true,
	joeys: true,
	johns: true,
	joins: true,
	joint: true,
	joist: true,
	joked: true,
	joker: true,
	jokes: true,
	jokey: true,
	joles: true,
	jolly: true,
	jolts: true,
	jolty: true,
	jomon: true,
	jones: true,
	jooks: true,
	joram: true,
	jorts: true,
	jorum: true,
	jotas: true,
	jotty: true,
	joual: true,
	jouks: true,
	joule: true,
	joust: true,
	jowar: true,
	jowed: true,
	jowls: true,
	jowly: true,
	joyed: true,
	jubas: true,
	jubes: true,
	jucos: true,
	judas: true,
	judge: true,
	judgy: true,
	judos: true,
	jugal: true,
	jugum: true,
	juice: true,
	juicy: true,
	jujus: true,
	juked: true,
	jukes: true,
	jukus: true,
	julep: true,
	jumar: true,
	jumbo: true,
	jumps: true,
	jumpy: true,
	junco: true,
	junks: true,
	junky: true,
	junta: true,
	junto: true,
	jupes: true,
	jupon: true,
	jural: true,
	jurat: true,
	jurel: true,
	juror: true,
	justs: true,
	jutes: true,
	jutty: true,
	juvie: true,
	kabab: true,
	kabar: true,
	kabob: true,
	kadis: true,
	kafir: true,
	kagus: true,
	kaiak: true,
	kaifs: true,
	kails: true,
	kains: true,
	kakas: true,
	kakis: true,
	kalam: true,
	kales: true,
	kalif: true,
	kalpa: true,
	kames: true,
	kamik: true,
	kanas: true,
	kanes: true,
	kanji: true,
	kanzu: true,
	kaons: true,
	kapas: true,
	kaphs: true,
	kapok: true,
	kapow: true,
	kappa: true,
	kapus: true,
	kaput: true,
	karat: true,
	karma: true,
	karns: true,
	karoo: true,
	karri: true,
	karst: true,
	karts: true,
	kasha: true,
	katas: true,
	kauri: true,
	kaury: true,
	kavas: true,
	kayak: true,
	kayos: true,
	kazoo: true,
	kbars: true,
	kebab: true,
	kebar: true,
	kebob: true,
	kecks: true,
	kedge: true,
	keefs: true,
	keeks: true,
	keels: true,
	keens: true,
	keeps: true,
	keets: true,
	keeve: true,
	kefir: true,
	keirs: true,
	kelep: true,
	kelim: true,
	kelly: true,
	kelps: true,
	kelpy: true,
	kelts: true,
	kemps: true,
	kempt: true,
	kempy: true,
	kenaf: true,
	kench: true,
	kendo: true,
	kenos: true,
	kente: true,
	kepis: true,
	kerbs: true,
	kerfs: true,
	kerne: true,
	kerns: true,
	kerry: true,
	ketas: true,
	ketch: true,
	ketol: true,
	kevel: true,
	kevil: true,
	kexes: true,
	keyed: true,
	keyer: true,
	khadi: true,
	khafs: true,
	khaki: true,
	khans: true,
	khaph: true,
	khats: true,
	kheda: true,
	kheth: true,
	khets: true,
	khoum: true,
	kiack: true,
	kiang: true,
	kibbe: true,
	kibbi: true,
	kibei: true,
	kibes: true,
	kibla: true,
	kicks: true,
	kicky: true,
	kiddo: true,
	kiddy: true,
	kiefs: true,
	kiers: true,
	kilim: true,
	kills: true,
	kilns: true,
	kilos: true,
	kilts: true,
	kilty: true,
	kinas: true,
	kinds: true,
	kines: true,
	kings: true,
	kinin: true,
	kinks: true,
	kinky: true,
	kinos: true,
	kiosk: true,
	kippa: true,
	kirks: true,
	kirns: true,
	kissy: true,
	kists: true,
	kited: true,
	kiter: true,
	kites: true,
	kithe: true,
	kiths: true,
	kitty: true,
	kivas: true,
	kiwis: true,
	klick: true,
	klieg: true,
	kliks: true,
	klong: true,
	kloof: true,
	kluge: true,
	klutz: true,
	knack: true,
	knaps: true,
	knars: true,
	knaur: true,
	knave: true,
	knawe: true,
	knead: true,
	kneed: true,
	kneel: true,
	knees: true,
	knell: true,
	knelt: true,
	knife: true,
	knish: true,
	knits: true,
	knobs: true,
	knock: true,
	knoll: true,
	knops: true,
	knosp: true,
	knots: true,
	knout: true,
	known: true,
	knows: true,
	knurl: true,
	knurr: true,
	knurs: true,
	koala: true,
	koans: true,
	kobos: true,
	koels: true,
	kofta: true,
	kohls: true,
	koine: true,
	kojis: true,
	kokam: true,
	kokum: true,
	kolas: true,
	kolos: true,
	kombu: true,
	konks: true,
	kooks: true,
	kooky: true,
	kopek: true,
	kophs: true,
	kopje: true,
	koppa: true,
	korai: true,
	koras: true,
	korat: true,
	korma: true,
	korun: true,
	kotos: true,
	kotow: true,
	kraal: true,
	kraft: true,
	krais: true,
	krait: true,
	kraut: true,
	krays: true,
	kreep: true,
	krewe: true,
	krill: true,
	krona: true,
	krone: true,
	kroon: true,
	krubi: true,
	kubie: true,
	kudos: true,
	kudus: true,
	kudzu: true,
	kufis: true,
	kugel: true,
	kukri: true,
	kulak: true,
	kulfi: true,
	kumis: true,
	kumys: true,
	kurta: true,
	kurus: true,
	kusso: true,
	kvass: true,
	kvell: true,
	kyack: true,
	kyaks: true,
	kyars: true,
	kyats: true,
	kylin: true,
	kylix: true,
	kyrie: true,
	kytes: true,
	kythe: true,
	laari: true,
	label: true,
	labia: true,
	labor: true,
	labra: true,
	laced: true,
	lacer: true,
	laces: true,
	lacey: true,
	lacks: true,
	laddy: true,
	laded: true,
	laden: true,
	lader: true,
	lades: true,
	ladle: true,
	laevo: true,
	lagan: true,
	lager: true,
	lahal: true,
	lahar: true,
	laich: true,
	laics: true,
	laigh: true,
	laird: true,
	lairs: true,
	lairy: true,
	laith: true,
	laity: true,
	laked: true,
	laker: true,
	lakes: true,
	lakhs: true,
	lalls: true,
	lamas: true,
	lambs: true,
	lamby: true,
	lamed: true,
	lamer: true,
	lames: true,
	lamia: true,
	lamps: true,
	lanai: true,
	lance: true,
	lanch: true,
	lands: true,
	lanes: true,
	lanky: true,
	lapel: true,
	lapin: true,
	lapis: true,
	lapse: true,
	larch: true,
	lards: true,
	lardy: true,
	laree: true,
	lares: true,
	large: true,
	largo: true,
	laris: true,
	larks: true,
	larky: true,
	larns: true,
	larnt: true,
	larum: true,
	larva: true,
	lased: true,
	laser: true,
	lases: true,
	lassi: true,
	lasso: true,
	lassy: true,
	lasts: true,
	latch: true,
	lated: true,
	laten: true,
	later: true,
	latex: true,
	lathe: true,
	lathi: true,
	laths: true,
	lathy: true,
	latke: true,
	latte: true,
	lauan: true,
	lauds: true,
	laugh: true,
	laura: true,
	lavas: true,
	laved: true,
	laver: true,
	laves: true,
	lawed: true,
	lawns: true,
	lawny: true,
	laxer: true,
	laxes: true,
	laxly: true,
	layed: true,
	layer: true,
	layin: true,
	layup: true,
	lazar: true,
	lazed: true,
	lazes: true,
	leach: true,
	leads: true,
	leady: true,
	leafs: true,
	leafy: true,
	leaks: true,
	leaky: true,
	leans: true,
	leant: true,
	leaps: true,
	leapt: true,
	learn: true,
	lears: true,
	leary: true,
	lease: true,
	leash: true,
	least: true,
	leave: true,
	leavy: true,
	leben: true,
	ledes: true,
	ledge: true,
	ledgy: true,
	leech: true,
	leeks: true,
	leers: true,
	leery: true,
	leets: true,
	lefts: true,
	lefty: true,
	legal: true,
	leger: true,
	leges: true,
	leggy: true,
	legit: true,
	lehrs: true,
	lehua: true,
	leman: true,
	lemma: true,
	lemon: true,
	lemur: true,
	lends: true,
	lenes: true,
	lenis: true,
	lenos: true,
	lense: true,
	lento: true,
	leone: true,
	leper: true,
	lepta: true,
	letch: true,
	lethe: true,
	letup: true,
	leuds: true,
	levas: true,
	levee: true,
	level: true,
	lever: true,
	levin: true,
	lewis: true,
	lexes: true,
	lexis: true,
	liana: true,
	liane: true,
	liang: true,
	liard: true,
	liars: true,
	libel: true,
	liber: true,
	libra: true,
	libri: true,
	lichi: true,
	licht: true,
	licit: true,
	licks: true,
	lidar: true,
	lidos: true,
	liege: true,
	liens: true,
	liers: true,
	lieus: true,
	lieve: true,
	lifer: true,
	lifts: true,
	ligan: true,
	liger: true,
	light: true,
	liked: true,
	liken: true,
	liker: true,
	likes: true,
	lilac: true,
	lilts: true,
	liman: true,
	limas: true,
	limba: true,
	limbi: true,
	limbo: true,
	limbs: true,
	limby: true,
	limed: true,
	limen: true,
	limes: true,
	limey: true,
	limit: true,
	limns: true,
	limos: true,
	limpa: true,
	limps: true,
	linac: true,
	lindy: true,
	lined: true,
	linen: true,
	liner: true,
	lines: true,
	liney: true,
	linga: true,
	lingo: true,
	lings: true,
	lingy: true,
	linin: true,
	links: true,
	linky: true,
	linns: true,
	linny: true,
	linos: true,
	lints: true,
	linty: true,
	linum: true,
	lions: true,
	lipas: true,
	lipid: true,
	lipin: true,
	lipos: true,
	lippy: true,
	liras: true,
	lirot: true,
	lisle: true,
	lisps: true,
	lists: true,
	litai: true,
	litas: true,
	liter: true,
	lites: true,
	lithe: true,
	litho: true,
	litre: true,
	lived: true,
	liven: true,
	liver: true,
	lives: true,
	livid: true,
	livre: true,
	llama: true,
	llano: true,
	loach: true,
	loads: true,
	loafs: true,
	loams: true,
	loamy: true,
	loans: true,
	loath: true,
	lobar: true,
	lobby: true,
	lobed: true,
	lobes: true,
	lobos: true,
	local: true,
	loche: true,
	lochs: true,
	locie: true,
	locis: true,
	locks: true,
	locos: true,
	locum: true,
	locus: true,
	loden: true,
	lodes: true,
	lodge: true,
	loess: true,
	lofts: true,
	lofty: true,
	logan: true,
	loges: true,
	loggy: true,
	logia: true,
	logic: true,
	login: true,
	logoi: true,
	logon: true,
	logos: true,
	loids: true,
	loins: true,
	lolls: true,
	lolly: true,
	loner: true,
	longe: true,
	longs: true,
	looby: true,
	looed: true,
	looey: true,
	loofa: true,
	loofs: true,
	looie: true,
	looks: true,
	looky: true,
	looms: true,
	loons: true,
	loony: true,
	loops: true,
	loopy: true,
	loose: true,
	loots: true,
	loped: true,
	loper: true,
	lopes: true,
	loppy: true,
	loral: true,
	loran: true,
	lords: true,
	lores: true,
	loris: true,
	lorry: true,
	losel: true,
	loser: true,
	loses: true,
	lossy: true,
	lotah: true,
	lotas: true,
	lotic: true,
	lotos: true,
	lotte: true,
	lotto: true,
	lotus: true,
	lough: true,
	louie: true,
	louis: true,
	louma: true,
	loupe: true,
	loups: true,
	lours: true,
	loury: true,
	louse: true,
	lousy: true,
	louts: true,
	lovat: true,
	loved: true,
	lover: true,
	loves: true,
	lovey: true,
	lowed: true,
	lower: true,
	lowes: true,
	lowly: true,
	lowse: true,
	loxed: true,
	loxes: true,
	loyal: true,
	luaus: true,
	lubed: true,
	lubes: true,
	luces: true,
	lucid: true,
	lucks: true,
	lucky: true,
	lucre: true,
	ludes: true,
	ludic: true,
	ludos: true,
	luffa: true,
	luffs: true,
	luged: true,
	luger: true,
	luges: true,
	lulls: true,
	lulus: true,
	lumas: true,
	lumen: true,
	lumps: true,
	lumpy: true,
	lunar: true,
	lunas: true,
	lunch: true,
	lunes: true,
	lunet: true,
	lunge: true,
	lungi: true,
	lungs: true,
	lunks: true,
	lunts: true,
	lupin: true,
	lupus: true,
	lurch: true,
	lured: true,
	lurer: true,
	lures: true,
	lurid: true,
	lurks: true,
	lusts: true,
	lusty: true,
	lusus: true,
	lutea: true,
	luted: true,
	lutes: true,
	luvvy: true,
	luxer: true,
	luxes: true,
	lweis: true,
	lyard: true,
	lyart: true,
	lyase: true,
	lycea: true,
	lycee: true,
	lying: true,
	lymph: true,
	lynch: true,
	lyres: true,
	lyric: true,
	lysed: true,
	lyses: true,
	lysin: true,
	lysis: true,
	lyssa: true,
	lytic: true,
	lytta: true,
	maars: true,
	mabes: true,
	macaw: true,
	maced: true,
	macer: true,
	maces: true,
	mache: true,
	macho: true,
	machs: true,
	macks: true,
	macle: true,
	macon: true,
	macro: true,
	madam: true,
	madly: true,
	madre: true,
	mafia: true,
	mafic: true,
	mages: true,
	magic: true,
	magma: true,
	magot: true,
	magus: true,
	mahoe: true,
	mahua: true,
	mahwa: true,
	maids: true,
	maile: true,
	maill: true,
	mails: true,
	maims: true,
	mains: true,
	mairs: true,
	maist: true,
	maize: true,
	major: true,
	makar: true,
	maker: true,
	makes: true,
	makis: true,
	makos: true,
	malar: true,
	males: true,
	malic: true,
	malls: true,
	malms: true,
	malmy: true,
	malts: true,
	malty: true,
	mamas: true,
	mamba: true,
	mambo: true,
	mamee: true,
	mamey: true,
	mamie: true,
	mamma: true,
	mammy: true,
	manas: true,
	manat: true,
	maneb: true,
	maned: true,
	manes: true,
	manga: true,
	mange: true,
	mango: true,
	mangy: true,
	mania: true,
	manic: true,
	manky: true,
	manly: true,
	manna: true,
	manor: true,
	manos: true,
	manse: true,
	manta: true,
	manus: true,
	maple: true,
	maqui: true,
	maras: true,
	march: true,
	marcs: true,
	mares: true,
	marge: true,
	maria: true,
	marka: true,
	marks: true,
	marls: true,
	marly: true,
	marry: true,
	marse: true,
	marsh: true,
	marts: true,
	marvy: true,
	masas: true,
	maser: true,
	mashy: true,
	masks: true,
	mason: true,
	massa: true,
	masse: true,
	massy: true,
	masts: true,
	match: true,
	mated: true,
	mater: true,
	mates: true,
	matey: true,
	maths: true,
	matin: true,
	matte: true,
	matts: true,
	matza: true,
	matzo: true,
	mauds: true,
	mauls: true,
	maund: true,
	mausy: true,
	mauts: true,
	mauve: true,
	mauzy: true,
	maven: true,
	mavie: true,
	mavin: true,
	mavis: true,
	mawed: true,
	maxed: true,
	maxes: true,
	maxim: true,
	maxis: true,
	mayan: true,
	mayas: true,
	maybe: true,
	mayed: true,
	mayor: true,
	mayos: true,
	mayst: true,
	mazed: true,
	mazer: true,
	mazes: true,
	mbira: true,
	meads: true,
	meals: true,
	mealy: true,
	means: true,
	meant: true,
	meany: true,
	meats: true,
	meaty: true,
	mecca: true,
	mechs: true,
	medal: true,
	media: true,
	medic: true,
	medii: true,
	meeds: true,
	meets: true,
	meiny: true,
	melds: true,
	melee: true,
	melic: true,
	mells: true,
	melon: true,
	melts: true,
	melty: true,
	memes: true,
	memos: true,
	menad: true,
	mends: true,
	mensa: true,
	mense: true,
	mensh: true,
	menta: true,
	mento: true,
	menus: true,
	meous: true,
	meows: true,
	merch: true,
	mercs: true,
	mercy: true,
	merer: true,
	meres: true,
	merge: true,
	merit: true,
	merks: true,
	merle: true,
	merls: true,
	merry: true,
	mesas: true,
	meshy: true,
	mesic: true,
	mesne: true,
	meson: true,
	messy: true,
	metal: true,
	meted: true,
	meter: true,
	metes: true,
	meths: true,
	metis: true,
	metol: true,
	metre: true,
	metro: true,
	mewed: true,
	mewls: true,
	mezes: true,
	mezzo: true,
	miaou: true,
	miaow: true,
	miasm: true,
	miaul: true,
	micas: true,
	miche: true,
	micky: true,
	micra: true,
	micro: true,
	middy: true,
	midge: true,
	midis: true,
	midst: true,
	miens: true,
	miffs: true,
	miffy: true,
	miggs: true,
	might: true,
	miked: true,
	mikes: true,
	mikra: true,
	mikva: true,
	milch: true,
	milds: true,
	miler: true,
	miles: true,
	milia: true,
	milks: true,
	milky: true,
	mille: true,
	mills: true,
	milos: true,
	milpa: true,
	milts: true,
	milty: true,
	mimed: true,
	mimeo: true,
	mimer: true,
	mimes: true,
	mimic: true,
	minae: true,
	minas: true,
	mince: true,
	mincy: true,
	minds: true,
	mined: true,
	miner: true,
	mines: true,
	mingy: true,
	minim: true,
	minis: true,
	minke: true,
	minks: true,
	minny: true,
	minor: true,
	mints: true,
	minty: true,
	minus: true,
	mired: true,
	mires: true,
	mirex: true,
	mirid: true,
	mirin: true,
	mirks: true,
	mirky: true,
	mirth: true,
	mirza: true,
	misdo: true,
	miser: true,
	mises: true,
	misos: true,
	missy: true,
	mists: true,
	misty: true,
	miter: true,
	mites: true,
	mitis: true,
	mitre: true,
	mitts: true,
	mixed: true,
	mixer: true,
	mixes: true,
	mixup: true,
	mizen: true,
	moans: true,
	moats: true,
	mocha: true,
	mochi: true,
	mocks: true,
	modal: true,
	model: true,
	modem: true,
	modes: true,
	modus: true,
	moggy: true,
	mogul: true,
	mohel: true,
	mohos: true,
	mohur: true,
	moils: true,
	moira: true,
	moire: true,
	moist: true,
	mojos: true,
	mokes: true,
	molal: true,
	molar: true,
	molas: true,
	molds: true,
	moldy: true,
	moles: true,
	molls: true,
	molly: true,
	molto: true,
	molts: true,
	molys: true,
	momes: true,
	momma: true,
	mommy: true,
	momus: true,
	monad: true,
	monas: true,
	monde: true,
	mondo: true,
	money: true,
	mongo: true,
	monic: true,
	monie: true,
	monks: true,
	monos: true,
	monte: true,
	month: true,
	monty: true,
	mooch: true,
	moods: true,
	moody: true,
	mooed: true,
	mooks: true,
	moola: true,
	mools: true,
	moons: true,
	moony: true,
	moors: true,
	moory: true,
	moose: true,
	moots: true,
	moped: true,
	moper: true,
	mopes: true,
	mopey: true,
	moppy: true,
	morae: true,
	moral: true,
	moras: true,
	moray: true,
	morel: true,
	mores: true,
	morns: true,
	moron: true,
	morph: true,
	morro: true,
	morse: true,
	morts: true,
	mosey: true,
	mosks: true,
	mosso: true,
	mossy: true,
	moste: true,
	mosts: true,
	motel: true,
	motes: true,
	motet: true,
	motey: true,
	moths: true,
	mothy: true,
	motif: true,
	motor: true,
	motte: true,
	motto: true,
	motts: true,
	mouch: true,
	moues: true,
	mould: true,
	moult: true,
	mound: true,
	mount: true,
	mourn: true,
	mouse: true,
	mousy: true,
	mouth: true,
	moved: true,
	mover: true,
	moves: true,
	movie: true,
	mowed: true,
	mower: true,
	moxas: true,
	moxie: true,
	mozos: true,
	mucho: true,
	mucid: true,
	mucin: true,
	mucks: true,
	mucky: true,
	mucor: true,
	mucro: true,
	mucus: true,
	muddy: true,
	mudra: true,
	muffs: true,
	mufti: true,
	muggs: true,
	muggy: true,
	muhly: true,
	mujik: true,
	mulch: true,
	mulct: true,
	muled: true,
	mules: true,
	muley: true,
	mulie: true,
	mulla: true,
	mulls: true,
	mumms: true,
	mummy: true,
	mumps: true,
	mumsy: true,
	mumus: true,
	munch: true,
	mungo: true,
	mungs: true,
	munis: true,
	muons: true,
	mural: true,
	muras: true,
	mured: true,
	mures: true,
	murex: true,
	murid: true,
	murks: true,
	murky: true,
	murra: true,
	murre: true,
	murrs: true,
	murry: true,
	musca: true,
	mused: true,
	muser: true,
	muses: true,
	mushy: true,
	music: true,
	musks: true,
	musky: true,
	musos: true,
	mussy: true,
	musth: true,
	musts: true,
	musty: true,
	mutch: true,
	muted: true,
	muter: true,
	mutes: true,
	muton: true,
	mutts: true,
	muxed: true,
	muxes: true,
	muzak: true,
	muzzy: true,
	mynah: true,
	mynas: true,
	myoid: true,
	myoma: true,
	myope: true,
	myopy: true,
	myrrh: true,
	mysid: true,
	myths: true,
	mythy: true,
	naans: true,
	nabes: true,
	nabis: true,
	nabob: true,
	nacho: true,
	nacre: true,
	nadas: true,
	nadir: true,
	naevi: true,
	naffs: true,
	nagas: true,
	naggy: true,
	naiad: true,
	naifs: true,
	nails: true,
	naira: true,
	nairu: true,
	naive: true,
	naked: true,
	nakfa: true,
	nalas: true,
	naled: true,
	named: true,
	namer: true,
	names: true,
	nanas: true,
	nanna: true,
	nanny: true,
	nanos: true,
	napas: true,
	napes: true,
	nappa: true,
	nappe: true,
	nappy: true,
	narco: true,
	narcs: true,
	nards: true,
	nares: true,
	naric: true,
	naris: true,
	narks: true,
	narky: true,
	nasal: true,
	nasty: true,
	natal: true,
	natch: true,
	nates: true,
	natty: true,
	naval: true,
	navar: true,
	navel: true,
	naves: true,
	navvy: true,
	nawab: true,
	nazis: true,
	neaps: true,
	nears: true,
	neath: true,
	neats: true,
	necks: true,
	neddy: true,
	needs: true,
	needy: true,
	neems: true,
	neeps: true,
	negus: true,
	neifs: true,
	neigh: true,
	neist: true,
	nelly: true,
	nemas: true,
	nenes: true,
	neons: true,
	nerds: true,
	nerdy: true,
	nerol: true,
	nerts: true,
	nertz: true,
	nerve: true,
	nervy: true,
	nests: true,
	netop: true,
	netts: true,
	netty: true,
	neuks: true,
	neume: true,
	neums: true,
	never: true,
	neves: true,
	nevus: true,
	newbs: true,
	newel: true,
	newer: true,
	newie: true,
	newly: true,
	newsy: true,
	newts: true,
	nexts: true,
	nexus: true,
	ngwee: true,
	nicad: true,
	nicer: true,
	niche: true,
	nicks: true,
	nicol: true,
	nidal: true,
	nided: true,
	nides: true,
	nidus: true,
	niece: true,
	nieve: true,
	niffs: true,
	niffy: true,
	nifty: true,
	nighs: true,
	night: true,
	nihil: true,
	nikah: true,
	nills: true,
	nimbi: true,
	niner: true,
	nines: true,
	ninja: true,
	ninny: true,
	ninon: true,
	ninth: true,
	nipas: true,
	nippy: true,
	niqab: true,
	nisei: true,
	nisus: true,
	niter: true,
	nites: true,
	nitid: true,
	niton: true,
	nitre: true,
	nitro: true,
	nitty: true,
	nival: true,
	nixed: true,
	nixes: true,
	nixie: true,
	nizam: true,
	nobby: true,
	noble: true,
	nobly: true,
	nocks: true,
	nodal: true,
	noddy: true,
	nodes: true,
	nodus: true,
	noels: true,
	noggs: true,
	nohow: true,
	noils: true,
	noily: true,
	noirs: true,
	noise: true,
	noisy: true,
	nolos: true,
	nomad: true,
	nomas: true,
	nomen: true,
	nomes: true,
	nomoi: true,
	nomos: true,
	nonas: true,
	nonce: true,
	nones: true,
	nonet: true,
	nonis: true,
	nonyl: true,
	nooks: true,
	noons: true,
	noose: true,
	nopal: true,
	noria: true,
	noris: true,
	norms: true,
	north: true,
	nosed: true,
	noses: true,
	nosey: true,
	notal: true,
	notch: true,
	noted: true,
	noter: true,
	notes: true,
	notum: true,
	nouns: true,
	novae: true,
	novas: true,
	novel: true,
	noway: true,
	nowts: true,
	noyau: true,
	nubby: true,
	nubia: true,
	nucha: true,
	nuder: true,
	nudes: true,
	nudge: true,
	nudie: true,
	nudzh: true,
	nuffs: true,
	nuked: true,
	nukes: true,
	nulls: true,
	numbs: true,
	numen: true,
	nummy: true,
	nurds: true,
	nurls: true,
	nurse: true,
	nutso: true,
	nutsy: true,
	nutty: true,
	nyala: true,
	nylon: true,
	nymph: true,
	oaked: true,
	oaken: true,
	oakum: true,
	oared: true,
	oases: true,
	oasis: true,
	oasts: true,
	oaten: true,
	oater: true,
	oaths: true,
	oaves: true,
	obeah: true,
	obeli: true,
	obese: true,
	obeys: true,
	obias: true,
	obits: true,
	objet: true,
	oboes: true,
	obole: true,
	oboli: true,
	obols: true,
	occur: true,
	ocean: true,
	ocher: true,
	oches: true,
	ochre: true,
	ochry: true,
	ocker: true,
	ocrea: true,
	octad: true,
	octal: true,
	octan: true,
	octet: true,
	octyl: true,
	oculi: true,
	odahs: true,
	odder: true,
	oddly: true,
	odeon: true,
	odeum: true,
	odist: true,
	odium: true,
	odors: true,
	odour: true,
	odyle: true,
	odyls: true,
	offal: true,
	offed: true,
	offer: true,
	often: true,
	ofter: true,
	ogams: true,
	ogeed: true,
	ogees: true,
	ogham: true,
	ogive: true,
	ogled: true,
	ogler: true,
	ogles: true,
	ogres: true,
	ohias: true,
	ohing: true,
	ohmic: true,
	oidia: true,
	oiled: true,
	oiler: true,
	oinks: true,
	okapi: true,
	okays: true,
	okehs: true,
	okras: true,
	olden: true,
	older: true,
	oldie: true,
	oleic: true,
	olein: true,
	oleos: true,
	oleum: true,
	olios: true,
	olive: true,
	ollas: true,
	ollie: true,
	ology: true,
	omasa: true,
	omber: true,
	ombre: true,
	omega: true,
	omens: true,
	omers: true,
	omits: true,
	oncet: true,
	onery: true,
	onion: true,
	onium: true,
	onlay: true,
	onset: true,
	ontic: true,
	oohed: true,
	oomph: true,
	oorie: true,
	ootid: true,
	oozed: true,
	oozes: true,
	opahs: true,
	opals: true,
	opens: true,
	opera: true,
	opine: true,
	oping: true,
	opium: true,
	opsin: true,
	opted: true,
	optic: true,
	orach: true,
	oracy: true,
	orals: true,
	orang: true,
	orate: true,
	orbed: true,
	orbit: true,
	orcas: true,
	orcin: true,
	order: true,
	ordos: true,
	oread: true,
	organ: true,
	orgic: true,
	oribi: true,
	oriel: true,
	orles: true,
	orlop: true,
	ormer: true,
	ornis: true,
	orpin: true,
	orris: true,
	ortho: true,
	orzos: true,
	oscar: true,
	osier: true,
	osmic: true,
	osmol: true,
	ossia: true,
	ostia: true,
	otaku: true,
	other: true,
	ottar: true,
	otter: true,
	ottos: true,
	ought: true,
	ounce: true,
	ouphe: true,
	ouphs: true,
	ourie: true,
	ousel: true,
	ousts: true,
	outby: true,
	outdo: true,
	outed: true,
	outer: true,
	outgo: true,
	outre: true,
	outro: true,
	ouzel: true,
	ouzos: true,
	ovals: true,
	ovary: true,
	ovate: true,
	ovens: true,
	overs: true,
	overt: true,
	ovine: true,
	ovoid: true,
	ovoli: true,
	ovolo: true,
	ovule: true,
	owies: true,
	owing: true,
	owlet: true,
	owned: true,
	owner: true,
	owsen: true,
	oxbow: true,
	oxers: true,
	oxeye: true,
	oxide: true,
	oxids: true,
	oxime: true,
	oxims: true,
	oxlip: true,
	oxter: true,
	oyers: true,
	ozone: true,
	paans: true,
	pacas: true,
	paced: true,
	pacer: true,
	paces: true,
	pacey: true,
	pacha: true,
	packs: true,
	pacts: true,
	paddy: true,
	padis: true,
	padle: true,
	padre: true,
	padri: true,
	paean: true,
	paeon: true,
	pagan: true,
	paged: true,
	pager: true,
	pages: true,
	pagod: true,
	paiks: true,
	pails: true,
	pains: true,
	paint: true,
	pairs: true,
	paisa: true,
	paise: true,
	palea: true,
	paled: true,
	paler: true,
	pales: true,
	palet: true,
	palis: true,
	palls: true,
	pally: true,
	palms: true,
	palmy: true,
	palpi: true,
	palps: true,
	palsa: true,
	palsy: true,
	pampa: true,
	panda: true,
	pandy: true,
	paned: true,
	panel: true,
	panes: true,
	panga: true,
	pangs: true,
	panic: true,
	panne: true,
	pansy: true,
	panto: true,
	pants: true,
	panty: true,
	papal: true,
	papas: true,
	papaw: true,
	paper: true,
	pappi: true,
	pappy: true,
	parae: true,
	paras: true,
	parch: true,
	pardi: true,
	pards: true,
	pardy: true,
	pared: true,
	paren: true,
	pareo: true,
	parer: true,
	pares: true,
	pareu: true,
	parge: true,
	pargo: true,
	paris: true,
	parka: true,
	parks: true,
	parky: true,
	parle: true,
	parol: true,
	parrs: true,
	parry: true,
	parse: true,
	parts: true,
	party: true,
	parve: true,
	parvo: true,
	paseo: true,
	pases: true,
	pasha: true,
	pashm: true,
	paska: true,
	passe: true,
	pasta: true,
	paste: true,
	pasts: true,
	pasty: true,
	patch: true,
	pated: true,
	paten: true,
	pater: true,
	pates: true,
	paths: true,
	patin: true,
	patio: true,
	patly: true,
	patsy: true,
	patty: true,
	pauas: true,
	pause: true,
	pavan: true,
	paved: true,
	paver: true,
	paves: true,
	pavid: true,
	pavin: true,
	pavis: true,
	pawed: true,
	pawer: true,
	pawky: true,
	pawls: true,
	pawns: true,
	paxes: true,
	payed: true,
	payee: true,
	payer: true,
	payor: true,
	peace: true,
	peach: true,
	peage: true,
	peags: true,
	peaks: true,
	peaky: true,
	peals: true,
	peans: true,
	pearl: true,
	pears: true,
	peart: true,
	pease: true,
	peats: true,
	peaty: true,
	peavy: true,
	pecan: true,
	pechs: true,
	pecks: true,
	pecky: true,
	pedal: true,
	pedes: true,
	pedro: true,
	peeks: true,
	peels: true,
	peens: true,
	peeps: true,
	peers: true,
	peery: true,
	peeve: true,
	peins: true,
	peise: true,
	pekan: true,
	pekes: true,
	pekin: true,
	pekoe: true,
	pelau: true,
	peles: true,
	pelfs: true,
	pelon: true,
	pelts: true,
	penal: true,
	pence: true,
	pends: true,
	penes: true,
	pengo: true,
	penis: true,
	penna: true,
	penne: true,
	penni: true,
	penny: true,
	peons: true,
	peony: true,
	pepla: true,
	pepos: true,
	peppy: true,
	perch: true,
	percs: true,
	perdu: true,
	perdy: true,
	perea: true,
	peres: true,
	peril: true,
	peris: true,
	perks: true,
	perky: true,
	perms: true,
	perps: true,
	perry: true,
	perse: true,
	pervo: true,
	pervs: true,
	pervy: true,
	pesky: true,
	pesos: true,
	pesto: true,
	pests: true,
	pesty: true,
	petal: true,
	peter: true,
	petit: true,
	petti: true,
	petto: true,
	petty: true,
	pewee: true,
	pewit: true,
	phage: true,
	phase: true,
	phial: true,
	phish: true,
	phizz: true,
	phlox: true,
	phone: true,
	phono: true,
	phons: true,
	phony: true,
	photo: true,
	phots: true,
	phpht: true,
	phuts: true,
	phyla: true,
	phyle: true,
	piani: true,
	piano: true,
	pians: true,
	pibal: true,
	pical: true,
	picas: true,
	picks: true,
	picky: true,
	picot: true,
	picul: true,
	piece: true,
	piers: true,
	pieta: true,
	piety: true,
	piezo: true,
	piggy: true,
	pigmy: true,
	piing: true,
	pikas: true,
	piked: true,
	piker: true,
	pikes: true,
	pikis: true,
	pilaf: true,
	pilar: true,
	pilau: true,
	pilaw: true,
	pilea: true,
	piled: true,
	pilei: true,
	piles: true,
	pilis: true,
	pills: true,
	pilot: true,
	pilus: true,
	pimas: true,
	pimps: true,
	pinas: true,
	pinch: true,
	pined: true,
	pines: true,
	piney: true,
	pingo: true,
	pings: true,
	pinko: true,
	pinks: true,
	pinky: true,
	pinna: true,
	pinny: true,
	pinon: true,
	pinot: true,
	pinta: true,
	pinto: true,
	pints: true,
	pinup: true,
	pions: true,
	pious: true,
	pipal: true,
	pipas: true,
	piped: true,
	piper: true,
	pipes: true,
	pipet: true,
	pipit: true,
	pique: true,
	pirns: true,
	pirog: true,
	pisco: true,
	pisos: true,
	piste: true,
	pitas: true,
	pitch: true,
	piths: true,
	pithy: true,
	piton: true,
	pitot: true,
	pitta: true,
	pivot: true,
	pixel: true,
	pixes: true,
	pixie: true,
	pizza: true,
	place: true,
	plack: true,
	plage: true,
	plaid: true,
	plain: true,
	plait: true,
	plane: true,
	plank: true,
	plans: true,
	plant: true,
	plash: true,
	plasm: true,
	plate: true,
	plats: true,
	platy: true,
	playa: true,
	plays: true,
	plaza: true,
	plead: true,
	pleas: true,
	pleat: true,
	plebe: true,
	plebs: true,
	plena: true,
	pleon: true,
	plews: true,
	plica: true,
	plied: true,
	plier: true,
	plies: true,
	plink: true,
	plods: true,
	plonk: true,
	plops: true,
	plots: true,
	plotz: true,
	plows: true,
	ploye: true,
	ploys: true,
	pluck: true,
	plugs: true,
	plumb: true,
	plume: true,
	plump: true,
	plums: true,
	plumy: true,
	plunk: true,
	pluot: true,
	plush: true,
	plyer: true,
	poach: true,
	poboy: true,
	pocks: true,
	pocky: true,
	podgy: true,
	podia: true,
	poems: true,
	poesy: true,
	poets: true,
	pogey: true,
	pogos: true,
	poilu: true,
	poind: true,
	point: true,
	poise: true,
	poked: true,
	poker: true,
	pokes: true,
	pokey: true,
	polar: true,
	poled: true,
	poler: true,
	poles: true,
	polio: true,
	polis: true,
	polka: true,
	polls: true,
	polos: true,
	polyp: true,
	polys: true,
	pombe: true,
	pomes: true,
	pomos: true,
	pomps: true,
	ponce: true,
	ponds: true,
	pones: true,
	pongo: true,
	pongs: true,
	pongy: true,
	ponzu: true,
	pooch: true,
	poods: true,
	pooed: true,
	poohs: true,
	pooja: true,
	pooka: true,
	pools: true,
	poons: true,
	poops: true,
	poopy: true,
	poori: true,
	popes: true,
	poppa: true,
	poppy: true,
	popsy: true,
	porch: true,
	pored: true,
	pores: true,
	porgy: true,
	porin: true,
	porks: true,
	porky: true,
	porno: true,
	porns: true,
	porny: true,
	ports: true,
	posed: true,
	poser: true,
	poses: true,
	posey: true,
	posit: true,
	posse: true,
	posts: true,
	potsy: true,
	potto: true,
	potty: true,
	pouch: true,
	pouff: true,
	poufs: true,
	poult: true,
	pound: true,
	pours: true,
	pouts: true,
	pouty: true,
	power: true,
	poxed: true,
	poxes: true,
	poyou: true,
	praam: true,
	prahu: true,
	prams: true,
	prana: true,
	prang: true,
	prank: true,
	praos: true,
	prase: true,
	prate: true,
	prats: true,
	praus: true,
	prawn: true,
	prays: true,
	preed: true,
	preen: true,
	prees: true,
	preop: true,
	preps: true,
	presa: true,
	prese: true,
	press: true,
	prest: true,
	prexy: true,
	preys: true,
	price: true,
	prick: true,
	pricy: true,
	pride: true,
	pried: true,
	prier: true,
	pries: true,
	prigs: true,
	prill: true,
	prima: true,
	prime: true,
	primi: true,
	primo: true,
	primp: true,
	prims: true,
	prink: true,
	print: true,
	prion: true,
	prior: true,
	prise: true,
	prism: true,
	priss: true,
	privy: true,
	prize: true,
	proas: true,
	probe: true,
	probs: true,
	prods: true,
	proem: true,
	profs: true,
	progs: true,
	prole: true,
	promo: true,
	proms: true,
	prone: true,
	prong: true,
	proof: true,
	props: true,
	prose: true,
	proso: true,
	pross: true,
	prost: true,
	prosy: true,
	proud: true,
	prove: true,
	prowl: true,
	prows: true,
	proxy: true,
	prude: true,
	prune: true,
	pruta: true,
	pryer: true,
	psalm: true,
	pseud: true,
	pshaw: true,
	psoae: true,
	psoai: true,
	psoas: true,
	psych: true,
	pubes: true,
	pubic: true,
	pubis: true,
	puces: true,
	pucka: true,
	pucks: true,
	pudge: true,
	pudgy: true,
	pudic: true,
	pudus: true,
	puffs: true,
	puffy: true,
	puggy: true,
	pujah: true,
	pujas: true,
	puked: true,
	pukes: true,
	pukey: true,
	pukka: true,
	pulao: true,
	pulas: true,
	puled: true,
	puler: true,
	pules: true,
	pulik: true,
	pulis: true,
	pulka: true,
	pulks: true,
	pulls: true,
	pulps: true,
	pulpy: true,
	pulse: true,
	pumas: true,
	pumps: true,
	punas: true,
	punch: true,
	pungs: true,
	punji: true,
	punka: true,
	punks: true,
	punky: true,
	punny: true,
	punto: true,
	punts: true,
	punty: true,
	pupae: true,
	pupal: true,
	pupas: true,
	pupil: true,
	puppy: true,
	pupus: true,
	purda: true,
	puree: true,
	purer: true,
	purge: true,
	purin: true,
	puris: true,
	purls: true,
	purrs: true,
	purse: true,
	pursy: true,
	purty: true,
	puses: true,
	pushy: true,
	pussy: true,
	puton: true,
	putti: true,
	putto: true,
	putts: true,
	putty: true,
	pygmy: true,
	pyins: true,
	pylon: true,
	pyoid: true,
	pyran: true,
	pyres: true,
	pyric: true,
	pyros: true,
	pyxes: true,
	pyxie: true,
	pyxis: true,
	pzazz: true,
	qadis: true,
	qaids: true,
	qajaq: true,
	qanat: true,
	qapik: true,
	qibla: true,
	qophs: true,
	quack: true,
	quads: true,
	quaff: true,
	quags: true,
	quail: true,
	quais: true,
	quake: true,
	quaky: true,
	quale: true,
	qualm: true,
	quant: true,
	quare: true,
	quark: true,
	quart: true,
	quash: true,
	quasi: true,
	quass: true,
	quate: true,
	quays: true,
	qubit: true,
	quean: true,
	queen: true,
	queer: true,
	quell: true,
	quern: true,
	query: true,
	quest: true,
	queue: true,
	queys: true,
	quick: true,
	quids: true,
	quiet: true,
	quiff: true,
	quill: true,
	quilt: true,
	quins: true,
	quint: true,
	quips: true,
	quipu: true,
	quire: true,
	quirk: true,
	quirt: true,
	quite: true,
	quits: true,
	quods: true,
	quoin: true,
	quoit: true,
	quoll: true,
	quota: true,
	quote: true,
	quoth: true,
	qursh: true,
	rabat: true,
	rabbi: true,
	rabic: true,
	rabid: true,
	raced: true,
	racer: true,
	races: true,
	racks: true,
	racon: true,
	radar: true,
	radii: true,
	radio: true,
	radix: true,
	radon: true,
	raffs: true,
	rafts: true,
	ragas: true,
	raged: true,
	ragee: true,
	rager: true,
	rages: true,
	ragga: true,
	raggs: true,
	raggy: true,
	ragis: true,
	raias: true,
	raids: true,
	rails: true,
	rains: true,
	rainy: true,
	raise: true,
	raita: true,
	rajah: true,
	rajas: true,
	rajes: true,
	raked: true,
	rakee: true,
	raker: true,
	rakes: true,
	rakis: true,
	rakus: true,
	rales: true,
	rally: true,
	ralph: true,
	ramal: true,
	ramee: true,
	ramen: true,
	ramet: true,
	ramie: true,
	ramin: true,
	rammy: true,
	ramps: true,
	ramus: true,
	rance: true,
	ranch: true,
	rands: true,
	randy: true,
	ranee: true,
	range: true,
	rangs: true,
	rangy: true,
	ranid: true,
	ranis: true,
	ranks: true,
	rants: true,
	raped: true,
	raper: true,
	rapes: true,
	raphe: true,
	rapid: true,
	rared: true,
	rarer: true,
	rares: true,
	rased: true,
	raser: true,
	rases: true,
	rasps: true,
	raspy: true,
	ratal: true,
	ratan: true,
	ratch: true,
	rated: true,
	ratel: true,
	rater: true,
	rates: true,
	rathe: true,
	ratio: true,
	ratos: true,
	ratty: true,
	raved: true,
	ravel: true,
	raven: true,
	raver: true,
	raves: true,
	ravey: true,
	ravin: true,
	rawer: true,
	rawin: true,
	rawly: true,
	raxed: true,
	raxes: true,
	rayah: true,
	rayas: true,
	rayed: true,
	rayon: true,
	razed: true,
	razee: true,
	razer: true,
	razes: true,
	razor: true,
	reach: true,
	react: true,
	readd: true,
	reads: true,
	ready: true,
	reais: true,
	realm: true,
	reals: true,
	reams: true,
	reaps: true,
	rearm: true,
	rears: true,
	reata: true,
	reave: true,
	rebar: true,
	rebbe: true,
	rebec: true,
	rebel: true,
	rebid: true,
	rebop: true,
	rebus: true,
	rebut: true,
	rebuy: true,
	recap: true,
	recce: true,
	recit: true,
	recks: true,
	recon: true,
	recta: true,
	recti: true,
	recto: true,
	recur: true,
	recut: true,
	redan: true,
	redds: true,
	reddy: true,
	reded: true,
	redes: true,
	redia: true,
	redid: true,
	redip: true,
	redly: true,
	redon: true,
	redos: true,
	redox: true,
	redry: true,
	redub: true,
	redux: true,
	redye: true,
	reeds: true,
	reedy: true,
	reefs: true,
	reefy: true,
	reeks: true,
	reeky: true,
	reels: true,
	reest: true,
	reeve: true,
	refed: true,
	refel: true,
	refer: true,
	refit: true,
	refix: true,
	refly: true,
	refry: true,
	regal: true,
	reges: true,
	regie: true,
	regma: true,
	regna: true,
	rehab: true,
	rehem: true,
	reifs: true,
	reify: true,
	reign: true,
	reiki: true,
	reink: true,
	reins: true,
	reive: true,
	rejig: true,
	rekey: true,
	relax: true,
	relay: true,
	relet: true,
	relic: true,
	relit: true,
	reman: true,
	remap: true,
	remet: true,
	remex: true,
	remit: true,
	remix: true,
	renal: true,
	rends: true,
	renew: true,
	renig: true,
	renin: true,
	renos: true,
	rente: true,
	rents: true,
	reoil: true,
	reorg: true,
	repay: true,
	repeg: true,
	repel: true,
	repin: true,
	reply: true,
	repos: true,
	repot: true,
	repps: true,
	repro: true,
	reran: true,
	rerig: true,
	rerun: true,
	resat: true,
	resaw: true,
	resay: true,
	resee: true,
	reses: true,
	reset: true,
	resew: true,
	resid: true,
	resin: true,
	resit: true,
	resod: true,
	resow: true,
	rests: true,
	retag: true,
	retax: true,
	retch: true,
	retem: true,
	retia: true,
	retie: true,
	retro: true,
	retry: true,
	reuse: true,
	revel: true,
	revet: true,
	revue: true,
	rewan: true,
	rewax: true,
	rewed: true,
	rewet: true,
	rewin: true,
	rewon: true,
	rexes: true,
	rezes: true,
	rheas: true,
	rheme: true,
	rheum: true,
	rhino: true,
	rhomb: true,
	rhumb: true,
	rhyme: true,
	rhyta: true,
	rials: true,
	riant: true,
	riata: true,
	ribby: true,
	ribes: true,
	riced: true,
	ricer: true,
	rices: true,
	ricin: true,
	ricks: true,
	rider: true,
	rides: true,
	ridge: true,
	ridgy: true,
	riels: true,
	rifer: true,
	riffs: true,
	rifle: true,
	rifts: true,
	right: true,
	rigid: true,
	rigor: true,
	riled: true,
	riles: true,
	riley: true,
	rille: true,
	rills: true,
	rimed: true,
	rimer: true,
	rimes: true,
	rinds: true,
	rindy: true,
	rings: true,
	rinks: true,
	rinse: true,
	rioja: true,
	riots: true,
	riped: true,
	ripen: true,
	riper: true,
	ripes: true,
	risen: true,
	riser: true,
	rises: true,
	rishi: true,
	risks: true,
	risky: true,
	risus: true,
	rites: true,
	ritzy: true,
	rival: true,
	rived: true,
	riven: true,
	river: true,
	rives: true,
	rivet: true,
	riyal: true,
	roach: true,
	roads: true,
	roams: true,
	roans: true,
	roars: true,
	roast: true,
	roate: true,
	robed: true,
	robes: true,
	robin: true,
	roble: true,
	robot: true,
	rocks: true,
	rocky: true,
	rodeo: true,
	rodes: true,
	roger: true,
	rogue: true,
	roils: true,
	roily: true,
	roles: true,
	rolfs: true,
	rolls: true,
	roman: true,
	romeo: true,
	romps: true,
	ronde: true,
	rondo: true,
	ronin: true,
	roods: true,
	roofs: true,
	rooks: true,
	rooky: true,
	rooms: true,
	roomy: true,
	roose: true,
	roost: true,
	roots: true,
	rooty: true,
	roped: true,
	roper: true,
	ropes: true,
	ropey: true,
	roque: true,
	rosed: true,
	roses: true,
	roset: true,
	roshi: true,
	rosin: true,
	rosti: true,
	rotas: true,
	rotch: true,
	rotes: true,
	rotis: true,
	rotls: true,
	rotor: true,
	rotos: true,
	rotte: true,
	rouen: true,
	roues: true,
	rouge: true,
	rough: true,
	round: true,
	roups: true,
	roupy: true,
	rouse: true,
	roust: true,
	route: true,
	routh: true,
	routs: true,
	roved: true,
	roven: true,
	rover: true,
	roves: true,
	rowan: true,
	rowdy: true,
	rowed: true,
	rowel: true,
	rowen: true,
	rower: true,
	rowth: true,
	royal: true,
	ruana: true,
	rubby: true,
	rubel: true,
	rubes: true,
	ruble: true,
	rubli: true,
	rubus: true,
	ruche: true,
	rucks: true,
	rudds: true,
	ruddy: true,
	ruder: true,
	ruers: true,
	ruffe: true,
	ruffs: true,
	rugae: true,
	rugal: true,
	rugby: true,
	ruing: true,
	ruins: true,
	rukhs: true,
	ruled: true,
	ruler: true,
	rules: true,
	rumba: true,
	rumen: true,
	rumly: true,
	rummy: true,
	rumor: true,
	rumps: true,
	runes: true,
	rungs: true,
	runic: true,
	runny: true,
	runts: true,
	runty: true,
	rupee: true,
	rural: true,
	ruses: true,
	rushy: true,
	rusks: true,
	rusts: true,
	rusty: true,
	ruths: true,
	rutin: true,
	rutty: true,
	ryked: true,
	rykes: true,
	rynds: true,
	ryots: true,
	sabal: true,
	sabed: true,
	saber: true,
	sabes: true,
	sabin: true,
	sabir: true,
	sable: true,
	sabot: true,
	sabra: true,
	sabre: true,
	sacks: true,
	sacra: true,
	sades: true,
	sadhe: true,
	sadhu: true,
	sadis: true,
	sadly: true,
	safer: true,
	safes: true,
	sagas: true,
	sager: true,
	sages: true,
	saggy: true,
	sagos: true,
	sagum: true,
	sahib: true,
	saice: true,
	saids: true,
	saiga: true,
	sails: true,
	sains: true,
	saint: true,
	saith: true,
	sajou: true,
	saker: true,
	sakes: true,
	sakis: true,
	sakti: true,
	salad: true,
	salal: true,
	salat: true,
	salep: true,
	sales: true,
	salic: true,
	sally: true,
	salmi: true,
	salol: true,
	salon: true,
	salpa: true,
	salps: true,
	salsa: true,
	salts: true,
	salty: true,
	salut: true,
	salve: true,
	salvo: true,
	samba: true,
	sambo: true,
	samek: true,
	samey: true,
	samfu: true,
	samps: true,
	sands: true,
	sandy: true,
	saned: true,
	saner: true,
	sanes: true,
	sanga: true,
	sangh: true,
	santo: true,
	sapid: true,
	sapor: true,
	sappy: true,
	saran: true,
	sards: true,
	saree: true,
	sarge: true,
	sargo: true,
	sarin: true,
	saris: true,
	sarks: true,
	sarky: true,
	sarod: true,
	saros: true,
	sasin: true,
	sassy: true,
	satay: true,
	sated: true,
	satem: true,
	sates: true,
	satin: true,
	satis: true,
	satyr: true,
	sauce: true,
	sauch: true,
	saucy: true,
	saugh: true,
	sauls: true,
	sault: true,
	sauna: true,
	saury: true,
	saute: true,
	saved: true,
	saver: true,
	saves: true,
	savin: true,
	savor: true,
	savoy: true,
	savvy: true,
	sawed: true,
	sawer: true,
	saxes: true,
	sayed: true,
	sayer: true,
	sayid: true,
	sayst: true,
	scabs: true,
	scads: true,
	scags: true,
	scald: true,
	scale: true,
	scall: true,
	scalp: true,
	scaly: true,
	scamp: true,
	scams: true,
	scans: true,
	scant: true,
	scape: true,
	scare: true,
	scarf: true,
	scarp: true,
	scars: true,
	scart: true,
	scary: true,
	scats: true,
	scatt: true,
	scaup: true,
	scaur: true,
	scena: true,
	scend: true,
	scene: true,
	scent: true,
	schav: true,
	schmo: true,
	schul: true,
	schwa: true,
	scion: true,
	scoff: true,
	scold: true,
	scone: true,
	scoop: true,
	scoot: true,
	scopa: true,
	scope: true,
	scops: true,
	score: true,
	scorn: true,
	scots: true,
	scour: true,
	scout: true,
	scowl: true,
	scows: true,
	scrag: true,
	scram: true,
	scran: true,
	scrap: true,
	scree: true,
	screw: true,
	scrim: true,
	scrip: true,
	scrob: true,
	scrod: true,
	scrub: true,
	scrum: true,
	scuba: true,
	scudi: true,
	scudo: true,
	scuds: true,
	scuff: true,
	sculk: true,
	scull: true,
	sculp: true,
	scums: true,
	scups: true,
	scurf: true,
	scuta: true,
	scute: true,
	scuts: true,
	scuzz: true,
	seals: true,
	seams: true,
	seamy: true,
	sears: true,
	seats: true,
	sebum: true,
	secco: true,
	sects: true,
	sedan: true,
	seder: true,
	sedge: true,
	sedgy: true,
	sedum: true,
	seeds: true,
	seedy: true,
	seeks: true,
	seels: true,
	seely: true,
	seems: true,
	seeps: true,
	seepy: true,
	seers: true,
	segni: true,
	segno: true,
	segos: true,
	segue: true,
	seifs: true,
	seine: true,
	seise: true,
	seism: true,
	seiza: true,
	seize: true,
	selah: true,
	selfs: true,
	selle: true,
	sells: true,
	selva: true,
	semee: true,
	semen: true,
	semes: true,
	semis: true,
	sends: true,
	senes: true,
	sengi: true,
	senna: true,
	senor: true,
	sensa: true,
	sense: true,
	sente: true,
	senti: true,
	sents: true,
	sepal: true,
	sepia: true,
	sepic: true,
	sepoy: true,
	septa: true,
	septs: true,
	serac: true,
	serai: true,
	seral: true,
	sered: true,
	serer: true,
	seres: true,
	serfs: true,
	serge: true,
	serif: true,
	serin: true,
	serow: true,
	serry: true,
	serum: true,
	serve: true,
	servo: true,
	setae: true,
	setal: true,
	seton: true,
	setts: true,
	setup: true,
	seven: true,
	sever: true,
	sewan: true,
	sewar: true,
	sewed: true,
	sewer: true,
	sexed: true,
	sexer: true,
	sexes: true,
	sexto: true,
	sexts: true,
	shack: true,
	shade: true,
	shads: true,
	shady: true,
	shaft: true,
	shags: true,
	shahs: true,
	shake: true,
	shako: true,
	shaky: true,
	shale: true,
	shall: true,
	shalt: true,
	shaly: true,
	shame: true,
	shams: true,
	shank: true,
	shape: true,
	shard: true,
	share: true,
	shark: true,
	sharn: true,
	sharp: true,
	shart: true,
	shaul: true,
	shave: true,
	shawl: true,
	shawm: true,
	shawn: true,
	shaws: true,
	shays: true,
	sheaf: true,
	sheal: true,
	shear: true,
	sheas: true,
	sheds: true,
	sheen: true,
	sheep: true,
	sheer: true,
	sheet: true,
	sheik: true,
	shelf: true,
	shell: true,
	shend: true,
	shent: true,
	sheol: true,
	sherd: true,
	shero: true,
	shewn: true,
	shews: true,
	shied: true,
	shiel: true,
	shier: true,
	shies: true,
	shift: true,
	shill: true,
	shily: true,
	shims: true,
	shine: true,
	shins: true,
	shiny: true,
	ships: true,
	shire: true,
	shirk: true,
	shirr: true,
	shirt: true,
	shist: true,
	shits: true,
	shiur: true,
	shiva: true,
	shive: true,
	shivs: true,
	shlep: true,
	shlub: true,
	shmoe: true,
	shoal: true,
	shoat: true,
	shock: true,
	shoed: true,
	shoer: true,
	shoes: true,
	shogi: true,
	shogs: true,
	shoji: true,
	shojo: true,
	shone: true,
	shook: true,
	shool: true,
	shoon: true,
	shoos: true,
	shoot: true,
	shops: true,
	shore: true,
	shorl: true,
	shorn: true,
	short: true,
	shote: true,
	shots: true,
	shott: true,
	shout: true,
	shove: true,
	shown: true,
	shows: true,
	showy: true,
	shoyu: true,
	shred: true,
	shrew: true,
	shris: true,
	shrub: true,
	shrug: true,
	shtik: true,
	shtum: true,
	shuck: true,
	shuln: true,
	shuls: true,
	shuns: true,
	shunt: true,
	shura: true,
	shush: true,
	shute: true,
	shuts: true,
	shwas: true,
	shyer: true,
	shyly: true,
	sials: true,
	sibbs: true,
	sibyl: true,
	sices: true,
	sicko: true,
	sicks: true,
	sided: true,
	sides: true,
	sidhe: true,
	sidle: true,
	siege: true,
	sieur: true,
	sieve: true,
	sifts: true,
	sighs: true,
	sight: true,
	sigil: true,
	sigla: true,
	sigma: true,
	signa: true,
	signs: true,
	sikas: true,
	siker: true,
	sikes: true,
	silds: true,
	silex: true,
	silks: true,
	silky: true,
	sills: true,
	silly: true,
	silos: true,
	silts: true,
	silty: true,
	silva: true,
	simar: true,
	simas: true,
	simps: true,
	since: true,
	sines: true,
	sinew: true,
	singe: true,
	sings: true,
	sinhs: true,
	sinks: true,
	sinus: true,
	siped: true,
	sipes: true,
	sired: true,
	siree: true,
	siren: true,
	sires: true,
	sirra: true,
	sirup: true,
	sisal: true,
	sises: true,
	sissy: true,
	sitar: true,
	sited: true,
	sites: true,
	situp: true,
	situs: true,
	siver: true,
	sixer: true,
	sixes: true,
	sixmo: true,
	sixte: true,
	sixth: true,
	sixty: true,
	sizar: true,
	sized: true,
	sizer: true,
	sizes: true,
	skags: true,
	skald: true,
	skank: true,
	skate: true,
	skats: true,
	skean: true,
	skeds: true,
	skeed: true,
	skeen: true,
	skees: true,
	skeet: true,
	skegs: true,
	skein: true,
	skell: true,
	skelm: true,
	skelp: true,
	skene: true,
	skeps: true,
	skews: true,
	skids: true,
	skied: true,
	skier: true,
	skies: true,
	skiey: true,
	skiff: true,
	skill: true,
	skimp: true,
	skims: true,
	skink: true,
	skins: true,
	skint: true,
	skips: true,
	skirl: true,
	skirr: true,
	skirt: true,
	skite: true,
	skits: true,
	skive: true,
	skoal: true,
	skols: true,
	skort: true,
	skosh: true,
	skuas: true,
	skulk: true,
	skull: true,
	skunk: true,
	skyed: true,
	skyey: true,
	slabs: true,
	slack: true,
	slags: true,
	slain: true,
	slake: true,
	slams: true,
	slang: true,
	slank: true,
	slant: true,
	slaps: true,
	slash: true,
	slate: true,
	slats: true,
	slaty: true,
	slave: true,
	slaws: true,
	slays: true,
	sleds: true,
	sleek: true,
	sleep: true,
	sleet: true,
	slept: true,
	slews: true,
	slice: true,
	slick: true,
	slide: true,
	slier: true,
	slily: true,
	slime: true,
	slims: true,
	slimy: true,
	sling: true,
	slink: true,
	slipe: true,
	slips: true,
	slipt: true,
	slits: true,
	slobs: true,
	sloes: true,
	slogs: true,
	sloid: true,
	slojd: true,
	slomo: true,
	sloop: true,
	slope: true,
	slops: true,
	slosh: true,
	sloth: true,
	slots: true,
	slows: true,
	sloyd: true,
	slubs: true,
	slued: true,
	slues: true,
	sluff: true,
	slugs: true,
	slump: true,
	slums: true,
	slung: true,
	slunk: true,
	slurb: true,
	slurp: true,
	slurs: true,
	slush: true,
	sluts: true,
	slyer: true,
	slyly: true,
	slype: true,
	smack: true,
	small: true,
	smalt: true,
	smarm: true,
	smart: true,
	smash: true,
	smaze: true,
	smear: true,
	smeek: true,
	smell: true,
	smelt: true,
	smerk: true,
	smews: true,
	smile: true,
	smirk: true,
	smite: true,
	smith: true,
	smock: true,
	smogs: true,
	smoke: true,
	smoky: true,
	smolt: true,
	smorg: true,
	smote: true,
	smush: true,
	smuts: true,
	snack: true,
	snafu: true,
	snags: true,
	snail: true,
	snake: true,
	snaky: true,
	snaps: true,
	snare: true,
	snarf: true,
	snark: true,
	snarl: true,
	snash: true,
	snath: true,
	snaws: true,
	sneak: true,
	sneap: true,
	sneck: true,
	sneds: true,
	sneer: true,
	snell: true,
	snibs: true,
	snick: true,
	snide: true,
	sniff: true,
	snipe: true,
	snips: true,
	snits: true,
	snobs: true,
	snogs: true,
	snood: true,
	snook: true,
	snool: true,
	snoop: true,
	snoot: true,
	snore: true,
	snort: true,
	snots: true,
	snout: true,
	snows: true,
	snowy: true,
	snubs: true,
	snuck: true,
	snuff: true,
	snugs: true,
	snyes: true,
	soaks: true,
	soaps: true,
	soapy: true,
	soare: true,
	soars: true,
	soave: true,
	sobas: true,
	sober: true,
	socas: true,
	soces: true,
	socko: true,
	socks: true,
	socle: true,
	sodas: true,
	soddy: true,
	sodic: true,
	sodom: true,
	sofar: true,
	sofas: true,
	softa: true,
	softs: true,
	softy: true,
	soggy: true,
	soils: true,
	sojas: true,
	sojus: true,
	sokes: true,
	sokol: true,
	solan: true,
	solar: true,
	solas: true,
	soldi: true,
	soldo: true,
	soled: true,
	solei: true,
	soles: true,
	solid: true,
	solon: true,
	solos: true,
	solum: true,
	solus: true,
	solve: true,
	soman: true,
	somas: true,
	sonar: true,
	sonde: true,
	sones: true,
	songs: true,
	sonic: true,
	sonly: true,
	sonny: true,
	sonsy: true,
	sooey: true,
	sooks: true,
	sooth: true,
	soots: true,
	sooty: true,
	sophs: true,
	sophy: true,
	sopor: true,
	soppy: true,
	soras: true,
	sorbs: true,
	sords: true,
	sored: true,
	sorel: true,
	sorer: true,
	sores: true,
	sorgo: true,
	sorns: true,
	sorry: true,
	sorts: true,
	sorus: true,
	soths: true,
	sotol: true,
	sough: true,
	souks: true,
	souls: true,
	sound: true,
	soups: true,
	soupy: true,
	sours: true,
	souse: true,
	south: true,
	sowar: true,
	sowed: true,
	sower: true,
	soyas: true,
	soyuz: true,
	sozin: true,
	space: true,
	spacy: true,
	spade: true,
	spado: true,
	spaed: true,
	spaes: true,
	spahi: true,
	spail: true,
	spait: true,
	spake: true,
	spale: true,
	spall: true,
	spams: true,
	spang: true,
	spank: true,
	spans: true,
	spare: true,
	spark: true,
	spars: true,
	spasm: true,
	spate: true,
	spats: true,
	spawn: true,
	spays: true,
	speak: true,
	spean: true,
	spear: true,
	speck: true,
	specs: true,
	speed: true,
	speel: true,
	speer: true,
	speil: true,
	speir: true,
	spell: true,
	spelt: true,
	spend: true,
	spent: true,
	sperm: true,
	spews: true,
	spica: true,
	spice: true,
	spicy: true,
	spied: true,
	spiel: true,
	spier: true,
	spies: true,
	spiff: true,
	spike: true,
	spiky: true,
	spile: true,
	spill: true,
	spilt: true,
	spine: true,
	spins: true,
	spiny: true,
	spire: true,
	spirt: true,
	spiry: true,
	spite: true,
	spits: true,
	spitz: true,
	spivs: true,
	splat: true,
	splay: true,
	split: true,
	spode: true,
	spoil: true,
	spoke: true,
	spoof: true,
	spook: true,
	spool: true,
	spoon: true,
	spoor: true,
	spore: true,
	sport: true,
	spots: true,
	spout: true,
	sprag: true,
	sprat: true,
	spray: true,
	spree: true,
	sprig: true,
	sprit: true,
	sprog: true,
	sprue: true,
	sprug: true,
	spuds: true,
	spued: true,
	spues: true,
	spume: true,
	spumy: true,
	spunk: true,
	spurn: true,
	spurs: true,
	spurt: true,
	sputa: true,
	squab: true,
	squad: true,
	squat: true,
	squeg: true,
	squib: true,
	squid: true,
	stabs: true,
	stack: true,
	stade: true,
	staff: true,
	stage: true,
	stags: true,
	stagy: true,
	staid: true,
	staig: true,
	stain: true,
	stair: true,
	stake: true,
	stale: true,
	stalk: true,
	stall: true,
	stamp: true,
	stand: true,
	stane: true,
	stang: true,
	stank: true,
	staph: true,
	stare: true,
	stark: true,
	stars: true,
	start: true,
	stash: true,
	state: true,
	stats: true,
	stave: true,
	stays: true,
	stead: true,
	steak: true,
	steal: true,
	steam: true,
	steed: true,
	steek: true,
	steel: true,
	steep: true,
	steer: true,
	stein: true,
	stela: true,
	stele: true,
	stems: true,
	steno: true,
	stent: true,
	steps: true,
	stere: true,
	stern: true,
	stets: true,
	stews: true,
	stewy: true,
	stich: true,
	stick: true,
	stied: true,
	sties: true,
	stiff: true,
	stile: true,
	still: true,
	stilt: true,
	stime: true,
	stimy: true,
	sting: true,
	stink: true,
	stint: true,
	stipe: true,
	stirk: true,
	stirp: true,
	stirs: true,
	stoae: true,
	stoai: true,
	stoas: true,
	stoat: true,
	stobs: true,
	stock: true,
	stogy: true,
	stoic: true,
	stoke: true,
	stole: true,
	stoma: true,
	stomp: true,
	stone: true,
	stonk: true,
	stony: true,
	stood: true,
	stook: true,
	stool: true,
	stoop: true,
	stope: true,
	stops: true,
	stopt: true,
	store: true,
	stork: true,
	storm: true,
	story: true,
	stoss: true,
	stots: true,
	stott: true,
	stoup: true,
	stour: true,
	stout: true,
	stove: true,
	stowp: true,
	stows: true,
	strap: true,
	straw: true,
	stray: true,
	strep: true,
	strew: true,
	stria: true,
	strip: true,
	strop: true,
	strow: true,
	stroy: true,
	strum: true,
	strut: true,
	stubs: true,
	stuck: true,
	studs: true,
	study: true,
	stuff: true,
	stull: true,
	stump: true,
	stums: true,
	stung: true,
	stunk: true,
	stuns: true,
	stunt: true,
	stupa: true,
	stupe: true,
	sturt: true,
	styed: true,
	styes: true,
	style: true,
	styli: true,
	stymy: true,
	suave: true,
	subah: true,
	subas: true,
	suber: true,
	sucks: true,
	sucky: true,
	sucre: true,
	sudds: true,
	sudor: true,
	sudsy: true,
	suede: true,
	suers: true,
	suete: true,
	suets: true,
	suety: true,
	sugar: true,
	sughs: true,
	suing: true,
	suint: true,
	suite: true,
	suits: true,
	sukhs: true,
	sulci: true,
	sulfa: true,
	sulfo: true,
	sulks: true,
	sulky: true,
	sully: true,
	sulus: true,
	sumac: true,
	sumis: true,
	summa: true,
	sumos: true,
	sumps: true,
	sunna: true,
	sunns: true,
	sunny: true,
	sunup: true,
	super: true,
	supes: true,
	supra: true,
	surah: true,
	sural: true,
	suras: true,
	surds: true,
	surer: true,
	surfs: true,
	surfy: true,
	surge: true,
	surgy: true,
	surly: true,
	surra: true,
	sused: true,
	suses: true,
	sushi: true,
	sutra: true,
	sutta: true,
	swabs: true,
	swage: true,
	swags: true,
	swail: true,
	swain: true,
	swale: true,
	swami: true,
	swamp: true,
	swamy: true,
	swang: true,
	swank: true,
	swans: true,
	swaps: true,
	sward: true,
	sware: true,
	swarf: true,
	swarm: true,
	swart: true,
	swash: true,
	swath: true,
	swats: true,
	sways: true,
	swear: true,
	sweat: true,
	swede: true,
	sweep: true,
	sweer: true,
	sweet: true,
	swell: true,
	swept: true,
	swift: true,
	swigs: true,
	swile: true,
	swill: true,
	swims: true,
	swine: true,
	swing: true,
	swink: true,
	swipe: true,
	swirl: true,
	swish: true,
	swiss: true,
	swith: true,
	swive: true,
	swobs: true,
	swoon: true,
	swoop: true,
	swops: true,
	sword: true,
	swore: true,
	sworn: true,
	swots: true,
	swoun: true,
	swung: true,
	sycee: true,
	syces: true,
	sycon: true,
	sykes: true,
	sylis: true,
	sylph: true,
	sylva: true,
	synch: true,
	syncs: true,
	synod: true,
	synth: true,
	syphs: true,
	syren: true,
	syrup: true,
	sysop: true,
	tabby: true,
	taber: true,
	tabes: true,
	tabid: true,
	tabla: true,
	table: true,
	taboo: true,
	tabor: true,
	tabun: true,
	tabus: true,
	tacan: true,
	taces: true,
	tacet: true,
	tache: true,
	tachs: true,
	tacit: true,
	tacks: true,
	tacky: true,
	tacos: true,
	tacts: true,
	taels: true,
	taffy: true,
	tafia: true,
	tahrs: true,
	taiga: true,
	taiko: true,
	tails: true,
	tains: true,
	taint: true,
	tajes: true,
	takas: true,
	taken: true,
	taker: true,
	takes: true,
	takin: true,
	talar: true,
	talas: true,
	talcs: true,
	talcy: true,
	taler: true,
	tales: true,
	talks: true,
	talky: true,
	talls: true,
	tally: true,
	talon: true,
	taluk: true,
	talus: true,
	tamal: true,
	tamed: true,
	tamer: true,
	tames: true,
	tamis: true,
	tammy: true,
	tamps: true,
	tanga: true,
	tango: true,
	tangs: true,
	tangy: true,
	tanka: true,
	tanks: true,
	tansy: true,
	tanto: true,
	tapas: true,
	taped: true,
	taper: true,
	tapes: true,
	tapir: true,
	tapis: true,
	tardo: true,
	tardy: true,
	tared: true,
	tares: true,
	targa: true,
	targe: true,
	tarns: true,
	taroc: true,
	tarok: true,
	taros: true,
	tarot: true,
	tarps: true,
	tarre: true,
	tarry: true,
	tarsi: true,
	tarts: true,
	tarty: true,
	tased: true,
	tases: true,
	tasks: true,
	tasse: true,
	tasso: true,
	taste: true,
	tasty: true,
	tatar: true,
	tater: true,
	tates: true,
	tatty: true,
	taunt: true,
	tauon: true,
	taupe: true,
	tauts: true,
	tawed: true,
	tawer: true,
	tawie: true,
	tawny: true,
	tawse: true,
	taxed: true,
	taxer: true,
	taxes: true,
	taxis: true,
	taxol: true,
	taxon: true,
	taxus: true,
	tayra: true,
	tazza: true,
	tazze: true,
	teach: true,
	teaks: true,
	teals: true,
	teams: true,
	tears: true,
	teary: true,
	tease: true,
	teats: true,
	techs: true,
	techy: true,
	tecta: true,
	teddy: true,
	teels: true,
	teems: true,
	teens: true,
	teeny: true,
	teeth: true,
	teffs: true,
	teggs: true,
	tegua: true,
	tegus: true,
	teiid: true,
	teind: true,
	teins: true,
	telae: true,
	telco: true,
	teles: true,
	telex: true,
	telia: true,
	telic: true,
	tells: true,
	telly: true,
	teloi: true,
	telos: true,
	tempi: true,
	tempo: true,
	temps: true,
	tempt: true,
	tench: true,
	tends: true,
	tendu: true,
	tenet: true,
	tenge: true,
	tenia: true,
	tenne: true,
	tenno: true,
	tenny: true,
	tenon: true,
	tenor: true,
	tense: true,
	tenth: true,
	tents: true,
	tenty: true,
	tepal: true,
	tepas: true,
	tepee: true,
	tepid: true,
	tepoy: true,
	terai: true,
	terce: true,
	teres: true,
	terga: true,
	terms: true,
	terne: true,
	terns: true,
	terra: true,
	terry: true,
	terse: true,
	tesla: true,
	testa: true,
	tests: true,
	testy: true,
	teths: true,
	tetra: true,
	tetri: true,
	teuch: true,
	teugh: true,
	tewed: true,
	texas: true,
	texts: true,
	thack: true,
	thali: true,
	thane: true,
	thank: true,
	tharm: true,
	thaws: true,
	thebe: true,
	theca: true,
	theft: true,
	thegn: true,
	thein: true,
	their: true,
	theme: true,
	thens: true,
	there: true,
	therm: true,
	these: true,
	thesp: true,
	theta: true,
	thews: true,
	thewy: true,
	thick: true,
	thief: true,
	thigh: true,
	thill: true,
	thine: true,
	thing: true,
	think: true,
	thins: true,
	thiol: true,
	third: true,
	thirl: true,
	thole: true,
	thong: true,
	thorn: true,
	thoro: true,
	thorp: true,
	those: true,
	thous: true,
	thraw: true,
	three: true,
	threw: true,
	thrip: true,
	throb: true,
	throe: true,
	throw: true,
	thrum: true,
	thuds: true,
	thugs: true,
	thuja: true,
	thumb: true,
	thump: true,
	thunk: true,
	thurl: true,
	thuya: true,
	thyme: true,
	thymi: true,
	thymy: true,
	tians: true,
	tiara: true,
	tibia: true,
	tical: true,
	ticks: true,
	tidal: true,
	tided: true,
	tides: true,
	tiers: true,
	tiffs: true,
	tiger: true,
	tight: true,
	tigon: true,
	tikes: true,
	tikis: true,
	tikka: true,
	tilak: true,
	tilde: true,
	tiled: true,
	tiler: true,
	tiles: true,
	tills: true,
	tilth: true,
	tilts: true,
	timed: true,
	timer: true,
	times: true,
	timid: true,
	tinct: true,
	tinea: true,
	tined: true,
	tines: true,
	tinge: true,
	tings: true,
	tinny: true,
	tints: true,
	tipis: true,
	tippy: true,
	tipsy: true,
	tired: true,
	tires: true,
	tirls: true,
	tiros: true,
	titan: true,
	titch: true,
	titer: true,
	tithe: true,
	titis: true,
	title: true,
	titre: true,
	titty: true,
	tiyin: true,
	tiyns: true,
	tizes: true,
	tizzy: true,
	toads: true,
	toady: true,
	toast: true,
	tocks: true,
	tocos: true,
	today: true,
	toddy: true,
	toeas: true,
	toffs: true,
	toffy: true,
	tofts: true,
	tofus: true,
	togae: true,
	togas: true,
	togue: true,
	toile: true,
	toils: true,
	toits: true,
	tokay: true,
	toked: true,
	token: true,
	toker: true,
	tokes: true,
	tolan: true,
	tolar: true,
	tolas: true,
	toled: true,
	toles: true,
	tolls: true,
	tolts: true,
	tolus: true,
	tolyl: true,
	toman: true,
	tombs: true,
	tomes: true,
	tommy: true,
	tonal: true,
	tondi: true,
	tondo: true,
	toned: true,
	toner: true,
	tones: true,
	toney: true,
	tonga: true,
	tongs: true,
	tonic: true,
	tonne: true,
	tonus: true,
	tools: true,
	toons: true,
	tooth: true,
	toots: true,
	topaz: true,
	toped: true,
	topee: true,
	toper: true,
	topes: true,
	tophe: true,
	tophi: true,
	tophs: true,
	topic: true,
	topis: true,
	topoi: true,
	topos: true,
	toque: true,
	torah: true,
	toras: true,
	torch: true,
	torcs: true,
	tores: true,
	toric: true,
	torii: true,
	toros: true,
	torot: true,
	torrs: true,
	torse: true,
	torsi: true,
	torsk: true,
	torso: true,
	torta: true,
	torte: true,
	torts: true,
	torus: true,
	tosas: true,
	total: true,
	toted: true,
	totem: true,
	toter: true,
	totes: true,
	touch: true,
	tough: true,
	tours: true,
	touse: true,
	touts: true,
	towed: true,
	towel: true,
	tower: true,
	towie: true,
	towns: true,
	towny: true,
	toxic: true,
	toxin: true,
	toyed: true,
	toyer: true,
	toyon: true,
	toyos: true,
	trace: true,
	track: true,
	tract: true,
	trade: true,
	tragi: true,
	traik: true,
	trail: true,
	train: true,
	trait: true,
	tramp: true,
	trams: true,
	trank: true,
	tranq: true,
	trans: true,
	traps: true,
	trapt: true,
	trash: true,
	trass: true,
	trave: true,
	trawl: true,
	trayf: true,
	trays: true,
	tread: true,
	treat: true,
	treed: true,
	treen: true,
	trees: true,
	trefa: true,
	treks: true,
	trems: true,
	trend: true,
	tress: true,
	trets: true,
	trews: true,
	treyf: true,
	treys: true,
	triac: true,
	triad: true,
	trial: true,
	tribe: true,
	trice: true,
	trick: true,
	tried: true,
	trier: true,
	tries: true,
	trigo: true,
	trigs: true,
	trike: true,
	trill: true,
	trims: true,
	trine: true,
	triol: true,
	trios: true,
	tripe: true,
	trips: true,
	trite: true,
	troak: true,
	trock: true,
	trode: true,
	trogs: true,
	trois: true,
	troke: true,
	troll: true,
	tromp: true,
	trona: true,
	trone: true,
	troop: true,
	trooz: true,
	trope: true,
	troth: true,
	trots: true,
	trout: true,
	trove: true,
	trows: true,
	troys: true,
	truce: true,
	truck: true,
	trued: true,
	truer: true,
	trues: true,
	trugs: true,
	trull: true,
	truly: true,
	trump: true,
	trunk: true,
	truss: true,
	trust: true,
	truth: true,
	tryma: true,
	tryst: true,
	tsade: true,
	tsadi: true,
	tsars: true,
	tsked: true,
	tsuba: true,
	tsubo: true,
	tubae: true,
	tubal: true,
	tubas: true,
	tubby: true,
	tubed: true,
	tuber: true,
	tubes: true,
	tucks: true,
	tufas: true,
	tuffs: true,
	tufts: true,
	tufty: true,
	tuile: true,
	tules: true,
	tulip: true,
	tulle: true,
	tulsi: true,
	tumid: true,
	tummy: true,
	tumor: true,
	tumps: true,
	tunas: true,
	tuned: true,
	tuner: true,
	tunes: true,
	tungs: true,
	tunic: true,
	tunny: true,
	tupik: true,
	tuque: true,
	turbo: true,
	turds: true,
	turfs: true,
	turfy: true,
	turks: true,
	turns: true,
	turps: true,
	turrs: true,
	tushy: true,
	tusks: true,
	tusky: true,
	tutee: true,
	tutor: true,
	tutti: true,
	tutty: true,
	tutus: true,
	tuxes: true,
	tuyer: true,
	twaes: true,
	twain: true,
	twang: true,
	tweak: true,
	tweed: true,
	tween: true,
	tweep: true,
	tweer: true,
	tweet: true,
	twerk: true,
	twerp: true,
	twice: true,
	twier: true,
	twigs: true,
	twill: true,
	twine: true,
	twink: true,
	twins: true,
	twiny: true,
	twirl: true,
	twirp: true,
	twist: true,
	twits: true,
	twixt: true,
	twyer: true,
	tyees: true,
	tyers: true,
	tying: true,
	tyiyn: true,
	tykes: true,
	tyned: true,
	tynes: true,
	typal: true,
	typed: true,
	types: true,
	typey: true,
	typic: true,
	typos: true,
	typps: true,
	tyred: true,
	tyres: true,
	tyros: true,
	tythe: true,
	tzars: true,
	udder: true,
	udons: true,
	uhlan: true,
	ukase: true,
	ulama: true,
	ulans: true,
	ulcer: true,
	ulema: true,
	ulnad: true,
	ulnae: true,
	ulnar: true,
	ulnas: true,
	ulpan: true,
	ultra: true,
	ulvas: true,
	umami: true,
	umbel: true,
	umber: true,
	umbos: true,
	umbra: true,
	umiac: true,
	umiak: true,
	umiaq: true,
	ummah: true,
	ummas: true,
	ummed: true,
	umped: true,
	umphs: true,
	umpty: true,
	unais: true,
	unapt: true,
	unarm: true,
	unary: true,
	unaus: true,
	unban: true,
	unbar: true,
	unbid: true,
	unbox: true,
	uncap: true,
	uncia: true,
	uncle: true,
	uncos: true,
	uncoy: true,
	uncus: true,
	uncut: true,
	undee: true,
	under: true,
	undid: true,
	undos: true,
	undue: true,
	unfed: true,
	unfit: true,
	unfix: true,
	ungot: true,
	unhat: true,
	unhip: true,
	unica: true,
	unify: true,
	union: true,
	unite: true,
	units: true,
	unity: true,
	unjam: true,
	unlay: true,
	unled: true,
	unlet: true,
	unlit: true,
	unman: true,
	unmet: true,
	unmew: true,
	unmix: true,
	unpeg: true,
	unpen: true,
	unpin: true,
	unrig: true,
	unrip: true,
	unsaw: true,
	unsay: true,
	unsee: true,
	unset: true,
	unsew: true,
	unsex: true,
	untie: true,
	until: true,
	unwed: true,
	unwet: true,
	unwit: true,
	unwon: true,
	unzip: true,
	upbow: true,
	upbye: true,
	updos: true,
	updry: true,
	upend: true,
	uplit: true,
	upped: true,
	upper: true,
	upset: true,
	uraei: true,
	urare: true,
	urari: true,
	urase: true,
	urate: true,
	urban: true,
	urbia: true,
	ureal: true,
	ureas: true,
	uredo: true,
	ureic: true,
	urged: true,
	urger: true,
	urges: true,
	urial: true,
	urine: true,
	urped: true,
	ursae: true,
	ursid: true,
	usage: true,
	users: true,
	usher: true,
	using: true,
	usnea: true,
	usque: true,
	usual: true,
	usurp: true,
	usury: true,
	uteri: true,
	utile: true,
	utter: true,
	uveal: true,
	uveas: true,
	uvula: true,
	vacua: true,
	vagal: true,
	vague: true,
	vagus: true,
	vails: true,
	vairs: true,
	vakil: true,
	vales: true,
	valet: true,
	valid: true,
	valor: true,
	valse: true,
	value: true,
	valve: true,
	vamps: true,
	vampy: true,
	vanda: true,
	vaned: true,
	vanes: true,
	vangs: true,
	vaped: true,
	vaper: true,
	vapes: true,
	vapid: true,
	vapor: true,
	varas: true,
	varec: true,
	varia: true,
	varix: true,
	varna: true,
	varus: true,
	varve: true,
	vasal: true,
	vases: true,
	vasts: true,
	vasty: true,
	vatic: true,
	vatus: true,
	vault: true,
	vaunt: true,
	veals: true,
	vealy: true,
	veena: true,
	veeps: true,
	veers: true,
	veery: true,
	vegan: true,
	vegas: true,
	veges: true,
	vegie: true,
	veils: true,
	veins: true,
	veiny: true,
	velar: true,
	velds: true,
	veldt: true,
	velum: true,
	venae: true,
	venal: true,
	vends: true,
	venge: true,
	venin: true,
	venom: true,
	vents: true,
	venue: true,
	verbs: true,
	verge: true,
	verse: true,
	verso: true,
	verst: true,
	verts: true,
	vertu: true,
	verve: true,
	vesta: true,
	vests: true,
	vetch: true,
	vexed: true,
	vexer: true,
	vexes: true,
	vexil: true,
	vials: true,
	viand: true,
	vibes: true,
	vicar: true,
	viced: true,
	vices: true,
	vichy: true,
	video: true,
	viers: true,
	views: true,
	viewy: true,
	viffs: true,
	vigas: true,
	vigia: true,
	vigil: true,
	vigor: true,
	viler: true,
	villa: true,
	villi: true,
	vills: true,
	vimen: true,
	vinal: true,
	vinas: true,
	vinca: true,
	vined: true,
	vines: true,
	vinic: true,
	vinos: true,
	vinyl: true,
	viola: true,
	viols: true,
	viper: true,
	viral: true,
	vireo: true,
	vires: true,
	virga: true,
	virid: true,
	virls: true,
	virtu: true,
	virus: true,
	visas: true,
	vised: true,
	vises: true,
	visit: true,
	visor: true,
	vista: true,
	vitae: true,
	vital: true,
	vitta: true,
	vivas: true,
	vivat: true,
	vivid: true,
	vixen: true,
	vizir: true,
	vizor: true,
	vleis: true,
	vlogs: true,
	vocab: true,
	vocal: true,
	voces: true,
	vodka: true,
	vodou: true,
	vodun: true,
	vogie: true,
	vogue: true,
	voice: true,
	voids: true,
	voila: true,
	voile: true,
	volar: true,
	voled: true,
	voles: true,
	volks: true,
	volta: true,
	volte: true,
	volti: true,
	volts: true,
	volva: true,
	vomer: true,
	vomit: true,
	voted: true,
	voter: true,
	votes: true,
	vouch: true,
	vowed: true,
	vowel: true,
	vower: true,
	voxel: true,
	vroom: true,
	vrouw: true,
	vrows: true,
	vuggs: true,
	vuggy: true,
	vughs: true,
	vulgo: true,
	vulns: true,
	vulva: true,
	vying: true,
	wacke: true,
	wacko: true,
	wacks: true,
	wacky: true,
	waddy: true,
	waded: true,
	wader: true,
	wades: true,
	wadis: true,
	wafer: true,
	waffs: true,
	wafts: true,
	waged: true,
	wager: true,
	wages: true,
	wagon: true,
	wahoo: true,
	waifs: true,
	wails: true,
	wains: true,
	wairs: true,
	waist: true,
	waits: true,
	waive: true,
	waked: true,
	waken: true,
	waker: true,
	wakes: true,
	waled: true,
	waler: true,
	wales: true,
	walie: true,
	walis: true,
	walks: true,
	walla: true,
	walls: true,
	wally: true,
	waltz: true,
	wames: true,
	wamus: true,
	wands: true,
	waned: true,
	wanes: true,
	waney: true,
	wanly: true,
	wants: true,
	wards: true,
	wared: true,
	wares: true,
	warez: true,
	warks: true,
	warms: true,
	warns: true,
	warps: true,
	warts: true,
	warty: true,
	washy: true,
	wasps: true,
	waspy: true,
	waste: true,
	wasts: true,
	watap: true,
	watch: true,
	water: true,
	watts: true,
	waugh: true,
	wauks: true,
	wauls: true,
	waved: true,
	waver: true,
	waves: true,
	wavey: true,
	wawls: true,
	waxed: true,
	waxen: true,
	waxer: true,
	waxes: true,
	weald: true,
	weals: true,
	weans: true,
	wears: true,
	weary: true,
	weave: true,
	webby: true,
	weber: true,
	wecht: true,
	wedel: true,
	wedge: true,
	wedgy: true,
	weeds: true,
	weedy: true,
	weeks: true,
	weens: true,
	weeny: true,
	weeps: true,
	weepy: true,
	weest: true,
	weets: true,
	wefts: true,
	weigh: true,
	weird: true,
	weirs: true,
	wekas: true,
	welch: true,
	welds: true,
	wells: true,
	welly: true,
	welsh: true,
	welts: true,
	wench: true,
	wends: true,
	wenny: true,
	wests: true,
	wetas: true,
	wetly: true,
	whack: true,
	whale: true,
	whamo: true,
	whams: true,
	whang: true,
	whaps: true,
	wharf: true,
	whats: true,
	whaup: true,
	wheal: true,
	wheat: true,
	wheel: true,
	wheen: true,
	wheep: true,
	whelk: true,
	whelm: true,
	whelp: true,
	whens: true,
	where: true,
	whets: true,
	whews: true,
	wheys: true,
	which: true,
	whids: true,
	whiff: true,
	whigs: true,
	while: true,
	whims: true,
	whine: true,
	whins: true,
	whiny: true,
	whips: true,
	whipt: true,
	whirl: true,
	whirr: true,
	whirs: true,
	whish: true,
	whisk: true,
	whist: true,
	white: true,
	whits: true,
	whity: true,
	whizz: true,
	whole: true,
	whomp: true,
	whoof: true,
	whoop: true,
	whops: true,
	whore: true,
	whorl: true,
	whort: true,
	whose: true,
	whoso: true,
	whump: true,
	whups: true,
	whyda: true,
	wicca: true,
	wicks: true,
	widdy: true,
	widen: true,
	wider: true,
	wides: true,
	widow: true,
	width: true,
	wield: true,
	wifed: true,
	wifes: true,
	wifey: true,
	wifty: true,
	wigan: true,
	wiggy: true,
	wight: true,
	wikis: true,
	wilco: true,
	wilds: true,
	wiled: true,
	wiles: true,
	wills: true,
	willy: true,
	wilts: true,
	wimps: true,
	wimpy: true,
	wince: true,
	winch: true,
	winds: true,
	windy: true,
	wined: true,
	wines: true,
	winey: true,
	wings: true,
	wingy: true,
	winks: true,
	winos: true,
	winze: true,
	wiped: true,
	wiper: true,
	wipes: true,
	wired: true,
	wirer: true,
	wires: true,
	wirra: true,
	wised: true,
	wiser: true,
	wises: true,
	wisha: true,
	wisps: true,
	wispy: true,
	wists: true,
	witan: true,
	witch: true,
	wited: true,
	wites: true,
	withe: true,
	withy: true,
	witty: true,
	wived: true,
	wiver: true,
	wives: true,
	wizen: true,
	wizes: true,
	woads: true,
	woald: true,
	wodge: true,
	woful: true,
	woken: true,
	woker: true,
	wolds: true,
	wolfs: true,
	woman: true,
	wombs: true,
	womby: true,
	women: true,
	womyn: true,
	wonks: true,
	wonky: true,
	wonts: true,
	woods: true,
	woody: true,
	wooed: true,
	wooer: true,
	woofs: true,
	wools: true,
	wooly: true,
	woops: true,
	woopy: true,
	woosh: true,
	woozy: true,
	words: true,
	wordy: true,
	works: true,
	world: true,
	worms: true,
	wormy: true,
	worry: true,
	worse: true,
	worst: true,
	worth: true,
	worts: true,
	would: true,
	wound: true,
	woven: true,
	wowed: true,
	wowee: true,
	wrack: true,
	wrang: true,
	wraps: true,
	wrapt: true,
	wrath: true,
	wreak: true,
	wreck: true,
	wrens: true,
	wrest: true,
	wrick: true,
	wried: true,
	wrier: true,
	wries: true,
	wring: true,
	wrist: true,
	write: true,
	writs: true,
	wrong: true,
	wrote: true,
	wroth: true,
	wrung: true,
	wryer: true,
	wryly: true,
	wuddy: true,
	wurst: true,
	wushu: true,
	wussy: true,
	wyled: true,
	wyles: true,
	wynds: true,
	wynns: true,
	wyted: true,
	wytes: true,
	xebec: true,
	xenia: true,
	xenic: true,
	xenon: true,
	xeric: true,
	xerox: true,
	xerus: true,
	xylan: true,
	xylem: true,
	xylol: true,
	xylyl: true,
	xysti: true,
	xysts: true,
	yabby: true,
	yacht: true,
	yacks: true,
	yaffs: true,
	yager: true,
	yages: true,
	yagis: true,
	yahoo: true,
	yaird: true,
	yamen: true,
	yamun: true,
	yangs: true,
	yanks: true,
	yapok: true,
	yapon: true,
	yappy: true,
	yarak: true,
	yards: true,
	yarer: true,
	yarns: true,
	yauds: true,
	yauld: true,
	yaups: true,
	yawed: true,
	yawey: true,
	yawls: true,
	yawns: true,
	yawps: true,
	yclad: true,
	yeahs: true,
	yeans: true,
	yearn: true,
	years: true,
	yeast: true,
	yecch: true,
	yechs: true,
	yechy: true,
	yeesh: true,
	yeggs: true,
	yelks: true,
	yells: true,
	yelps: true,
	yenta: true,
	yente: true,
	yerba: true,
	yerks: true,
	yeses: true,
	yetis: true,
	yetts: true,
	yeuks: true,
	yeuky: true,
	yield: true,
	yikes: true,
	yills: true,
	yince: true,
	yipes: true,
	yirds: true,
	yirrs: true,
	yirth: true,
	ylems: true,
	yobbo: true,
	yobby: true,
	yocks: true,
	yodel: true,
	yodhs: true,
	yodle: true,
	yogas: true,
	yogee: true,
	yoghs: true,
	yogic: true,
	yogin: true,
	yogis: true,
	yoked: true,
	yokel: true,
	yokes: true,
	yolks: true,
	yolky: true,
	yomim: true,
	yomps: true,
	yonic: true,
	yonis: true,
	yonks: true,
	yoofs: true,
	yores: true,
	young: true,
	yourn: true,
	yours: true,
	youse: true,
	youth: true,
	yowed: true,
	yowes: true,
	yowie: true,
	yowls: true,
	yowza: true,
	yuans: true,
	yucas: true,
	yucca: true,
	yucch: true,
	yucks: true,
	yucky: true,
	yugas: true,
	yuked: true,
	yukes: true,
	yukky: true,
	yulan: true,
	yules: true,
	yummy: true,
	yupon: true,
	yuppy: true,
	yurta: true,
	yurts: true,
	yuzus: true,
	zaida: true,
	zaidy: true,
	zaire: true,
	zakat: true,
	zamia: true,
	zanza: true,
	zappy: true,
	zarfs: true,
	zaxes: true,
	zayin: true,
	zazen: true,
	zeals: true,
	zebec: true,
	zebra: true,
	zebus: true,
	zedas: true,
	zeins: true,
	zendo: true,
	zerks: true,
	zeros: true,
	zests: true,
	zesty: true,
	zetas: true,
	zibet: true,
	zilch: true,
	zills: true,
	zinco: true,
	zincs: true,
	zincy: true,
	zineb: true,
	zines: true,
	zings: true,
	zingy: true,
	zinky: true,
	zippo: true,
	zippy: true,
	ziram: true,
	zitis: true,
	zizit: true,
	zlote: true,
	zloty: true,
	zoeae: true,
	zoeal: true,
	zoeas: true,
	zombi: true,
	zonae: true,
	zonal: true,
	zoned: true,
	zoner: true,
	zones: true,
	zonks: true,
	zooey: true,
	zooid: true,
	zooks: true,
	zooms: true,
	zoons: true,
	zooty: true,
	zoril: true,
	zoris: true,
	zouks: true,
	zowee: true,
	zowie: true,
	zuppa: true,
	zuzim: true,
	zymes: true,
};

export default dictionaryWords;
