const humanWords: { [key: string]: boolean } = {
	"aback": true,
	"abash": true,
	"abate": true,
	"abide": true,
	"abled": true,
	"abler": true,
	"abode": true,
	"abort": true,
	"about": true,
	"above": true,
	"abuse": true,
	"abyss": true,
	"ached": true,
	"aches": true,
	"acids": true,
	"acing": true,
	"acorn": true,
	"acres": true,
	"acted": true,
	"actor": true,
	"acute": true,
	"adapt": true,
	"added": true,
	"adept": true,
	"admin": true,
	"admit": true,
	"adobe": true,
	"adopt": true,
	"adore": true,
	"adorn": true,
	"adult": true,
	"affix": true,
	"after": true,
	"again": true,
	"agape": true,
	"agave": true,
	"agent": true,
	"agers": true,
	"aggro": true,
	"agile": true,
	"aging": true,
	"agony": true,
	"agree": true,
	"ahead": true,
	"ahold": true,
	"aided": true,
	"aimed": true,
	"aioli": true,
	"aired": true,
	"aisle": true,
	"alarm": true,
	"alibi": true,
	"alien": true,
	"align": true,
	"alley": true,
	"allot": true,
	"allow": true,
	"alloy": true,
	"alone": true,
	"along": true,
	"aloof": true,
	"aloud": true,
	"alpha": true,
	"altar": true,
	"alter": true,
	"amass": true,
	"amaze": true,
	"amber": true,
	"amend": true,
	"amino": true,
	"amiss": true,
	"amity": true,
	"among": true,
	"ample": true,
	"angel": true,
	"anger": true,
	"angle": true,
	"angry": true,
	"angst": true,
	"ankle": true,
	"annoy": true,
	"antsy": true,
	"anvil": true,
	"apart": true,
	"aphid": true,
	"apple": true,
	"apply": true,
	"apron": true,
	"arbor": true,
	"areas": true,
	"arena": true,
	"argue": true,
	"array": true,
	"arrow": true,
	"arson": true,
	"artsy": true,
	"ashes": true,
	"aside": true,
	"asked": true,
	"asker": true,
	"askew": true,
	"aspen": true,
	"asset": true,
	"atlas": true,
	"atoms": true,
	"atone": true,
	"attic": true,
	"audio": true,
	"audit": true,
	"auras": true,
	"avoid": true,
	"avows": true,
	"await": true,
	"awake": true,
	"award": true,
	"aware": true,
	"awful": true,
	"awoke": true,
	"axiom": true,
	"bacon": true,
	"badge": true,
	"badly": true,
	"bagel": true,
	"baggy": true,
	"bails": true,
	"baker": true,
	"bakes": true,
	"balls": true,
	"bands": true,
	"bangs": true,
	"banjo": true,
	"banks": true,
	"based": true,
	"basic": true,
	"basil": true,
	"basin": true,
	"basis": true,
	"basks": true,
	"baton": true,
	"beard": true,
	"beast": true,
	"began": true,
	"begin": true,
	"beige": true,
	"being": true,
	"belay": true,
	"belch": true,
	"below": true,
	"bezel": true,
	"bicep": true,
	"bidet": true,
	"biked": true,
	"biker": true,
	"binge": true,
	"bingo": true,
	"birch": true,
	"birds": true,
	"birth": true,
	"bison": true,
	"biter": true,
	"black": true,
	"blade": true,
	"blame": true,
	"bland": true,
	"blank": true,
	"bleep": true,
	"blend": true,
	"blimp": true,
	"blind": true,
	"bling": true,
	"blink": true,
	"blitz": true,
	"bloat": true,
	"block": true,
	"blogs": true,
	"blood": true,
	"bloom": true,
	"blown": true,
	"blues": true,
	"blunt": true,
	"blurb": true,
	"blurt": true,
	"blush": true,
	"board": true,
	"boast": true,
	"boats": true,
	"bonds": true,
	"bones": true,
	"bongo": true,
	"books": true,
	"booth": true,
	"boots": true,
	"bored": true,
	"botch": true,
	"bound": true,
	"bowed": true,
	"bowel": true,
	"bowls": true,
	"boxer": true,
	"boxes": true,
	"brace": true,
	"braid": true,
	"brain": true,
	"brake": true,
	"brand": true,
	"brass": true,
	"brats": true,
	"brawl": true,
	"brawn": true,
	"bread": true,
	"break": true,
	"brews": true,
	"bribe": true,
	"brick": true,
	"bride": true,
	"brief": true,
	"brine": true,
	"bring": true,
	"brink": true,
	"brisk": true,
	"broad": true,
	"broil": true,
	"broke": true,
	"brood": true,
	"brook": true,
	"broth": true,
	"brown": true,
	"brows": true,
	"brunt": true,
	"brush": true,
	"brute": true,
	"buddy": true,
	"budge": true,
	"bugle": true,
	"build": true,
	"built": true,
	"bulbs": true,
	"bulge": true,
	"bulky": true,
	"bunch": true,
	"bundt": true,
	"bunny": true,
	"burly": true,
	"burns": true,
	"burnt": true,
	"burps": true,
	"buses": true,
	"bushy": true,
	"busts": true,
	"busty": true,
	"butts": true,
	"buyer": true,
	"buzzy": true,
	"bytes": true,
	"cabin": true,
	"cable": true,
	"cacao": true,
	"cache": true,
	"cacti": true,
	"caddy": true,
	"cadet": true,
	"caged": true,
	"cages": true,
	"cakes": true,
	"cakey": true,
	"calls": true,
	"calms": true,
	"camel": true,
	"canal": true,
	"candy": true,
	"canes": true,
	"canny": true,
	"canoe": true,
	"canon": true,
	"caper": true,
	"capri": true,
	"carat": true,
	"cared": true,
	"cares": true,
	"caret": true,
	"cargo": true,
	"carry": true,
	"carts": true,
	"carve": true,
	"cases": true,
	"casks": true,
	"caste": true,
	"catch": true,
	"cater": true,
	"cause": true,
	"cedar": true,
	"cello": true,
	"cells": true,
	"cents": true,
	"chain": true,
	"chair": true,
	"chalk": true,
	"champ": true,
	"chant": true,
	"chaos": true,
	"charm": true,
	"chart": true,
	"chase": true,
	"cheap": true,
	"cheat": true,
	"check": true,
	"cheek": true,
	"cheer": true,
	"chess": true,
	"chest": true,
	"chews": true,
	"chewy": true,
	"chick": true,
	"chief": true,
	"child": true,
	"chill": true,
	"chime": true,
	"chimp": true,
	"chips": true,
	"chive": true,
	"chode": true,
	"choir": true,
	"choke": true,
	"chomp": true,
	"chord": true,
	"chore": true,
	"chose": true,
	"chuck": true,
	"chugs": true,
	"chump": true,
	"chunk": true,
	"churn": true,
	"chute": true,
	"cider": true,
	"cigar": true,
	"cinch": true,
	"circa": true,
	"cited": true,
	"cites": true,
	"civic": true,
	"civil": true,
	"clack": true,
	"claim": true,
	"clamp": true,
	"clams": true,
	"clank": true,
	"claps": true,
	"clash": true,
	"clasp": true,
	"class": true,
	"claws": true,
	"clays": true,
	"clean": true,
	"clear": true,
	"cleat": true,
	"cleft": true,
	"clerk": true,
	"click": true,
	"cliff": true,
	"climb": true,
	"clink": true,
	"cloak": true,
	"clock": true,
	"close": true,
	"cloth": true,
	"clots": true,
	"cloud": true,
	"clout": true,
	"clove": true,
	"clown": true,
	"cluck": true,
	"clued": true,
	"clues": true,
	"clump": true,
	"clunk": true,
	"coach": true,
	"coast": true,
	"cocoa": true,
	"coded": true,
	"coder": true,
	"codes": true,
	"coils": true,
	"coins": true,
	"colic": true,
	"colon": true,
	"color": true,
	"combo": true,
	"comes": true,
	"comfy": true,
	"comic": true,
	"comma": true,
	"conch": true,
	"condo": true,
	"coned": true,
	"cones": true,
	"coney": true,
	"conga": true,
	"conic": true,
	"cooks": true,
	"coped": true,
	"corgi": true,
	"corky": true,
	"corny": true,
	"costs": true,
	"couch": true,
	"could": true,
	"count": true,
	"court": true,
	"coven": true,
	"cover": true,
	"crack": true,
	"craft": true,
	"cramp": true,
	"crane": true,
	"crank": true,
	"crash": true,
	"crass": true,
	"crate": true,
	"crave": true,
	"crawl": true,
	"craze": true,
	"crazy": true,
	"creak": true,
	"cream": true,
	"creek": true,
	"creep": true,
	"crept": true,
	"crest": true,
	"cribs": true,
	"cried": true,
	"cries": true,
	"crime": true,
	"crimp": true,
	"crisp": true,
	"croak": true,
	"crook": true,
	"cross": true,
	"crowd": true,
	"crown": true,
	"crude": true,
	"cruel": true,
	"crumb": true,
	"crush": true,
	"crust": true,
	"crypt": true,
	"cubby": true,
	"cubed": true,
	"cubic": true,
	"cuffs": true,
	"cumin": true,
	"cupid": true,
	"curbs": true,
	"cured": true,
	"cures": true,
	"curls": true,
	"curly": true,
	"curry": true,
	"curse": true,
	"curve": true,
	"curvy": true,
	"cushy": true,
	"cusps": true,
	"cuter": true,
	"cutie": true,
	"cycle": true,
	"cynic": true,
	"daddy": true,
	"daily": true,
	"dairy": true,
	"daisy": true,
	"dance": true,
	"dandy": true,
	"dared": true,
	"dares": true,
	"dated": true,
	"dates": true,
	"daunt": true,
	"dazed": true,
	"dealt": true,
	"death": true,
	"debug": true,
	"debut": true,
	"decaf": true,
	"decal": true,
	"decay": true,
	"decks": true,
	"decor": true,
	"decoy": true,
	"deity": true,
	"delay": true,
	"delta": true,
	"demon": true,
	"demos": true,
	"denim": true,
	"dense": true,
	"dents": true,
	"depot": true,
	"depth": true,
	"desks": true,
	"deter": true,
	"detox": true,
	"deuce": true,
	"devil": true,
	"diary": true,
	"diced": true,
	"dicey": true,
	"digit": true,
	"dimly": true,
	"dined": true,
	"diner": true,
	"dingo": true,
	"dingy": true,
	"dirty": true,
	"disco": true,
	"ditch": true,
	"ditsy": true,
	"ditzy": true,
	"diver": true,
	"dives": true,
	"dizzy": true,
	"docks": true,
	"dodge": true,
	"doggo": true,
	"doggy": true,
	"dogma": true,
	"doily": true,
	"doing": true,
	"doles": true,
	"dolls": true,
	"dolly": true,
	"donor": true,
	"donut": true,
	"dooms": true,
	"doors": true,
	"doozy": true,
	"dorky": true,
	"dorms": true,
	"dosed": true,
	"doses": true,
	"doted": true,
	"dotes": true,
	"doubt": true,
	"dough": true,
	"dowel": true,
	"dowry": true,
	"dozed": true,
	"dozen": true,
	"draft": true,
	"drags": true,
	"drain": true,
	"drama": true,
	"drank": true,
	"drape": true,
	"drawl": true,
	"drawn": true,
	"draws": true,
	"dread": true,
	"dream": true,
	"dress": true,
	"dried": true,
	"drier": true,
	"dries": true,
	"drift": true,
	"drill": true,
	"drink": true,
	"drips": true,
	"drive": true,
	"drone": true,
	"drool": true,
	"droop": true,
	"drops": true,
	"drown": true,
	"drugs": true,
	"drums": true,
	"drunk": true,
	"dryer": true,
	"dryly": true,
	"ducks": true,
	"ducts": true,
	"dudes": true,
	"duels": true,
	"duets": true,
	"duffs": true,
	"dummy": true,
	"dumps": true,
	"dunce": true,
	"dunes": true,
	"dunks": true,
	"duped": true,
	"dusts": true,
	"dusty": true,
	"duvet": true,
	"dwarf": true,
	"dwell": true,
	"dying": true,
	"eager": true,
	"eagle": true,
	"eared": true,
	"early": true,
	"earth": true,
	"eased": true,
	"easel": true,
	"easer": true,
	"eases": true,
	"eaten": true,
	"eater": true,
	"ebony": true,
	"edges": true,
	"eerie": true,
	"egged": true,
	"eight": true,
	"eject": true,
	"elate": true,
	"elbow": true,
	"elder": true,
	"elect": true,
	"elegy": true,
	"elite": true,
	"elope": true,
	"elude": true,
	"elves": true,
	"embed": true,
	"ember": true,
	"emery": true,
	"emits": true,
	"emote": true,
	"empty": true,
	"enact": true,
	"ended": true,
	"enema": true,
	"enemy": true,
	"enjoy": true,
	"ensue": true,
	"enter": true,
	"entry": true,
	"epoch": true,
	"epoxy": true,
	"equal": true,
	"equip": true,
	"erase": true,
	"erect": true,
	"error": true,
	"erupt": true,
	"essay": true,
	"ethyl": true,
	"evade": true,
	"event": true,
	"every": true,
	"evict": true,
	"exact": true,
	"excel": true,
	"exert": true,
	"exile": true,
	"exist": true,
	"exits": true,
	"expel": true,
	"extra": true,
	"fable": true,
	"faced": true,
	"faces": true,
	"facet": true,
	"facts": true,
	"faded": true,
	"fades": true,
	"fails": true,
	"faint": true,
	"fairy": true,
	"faith": true,
	"faked": true,
	"faker": true,
	"falls": true,
	"false": true,
	"fancy": true,
	"farms": true,
	"farts": true,
	"fatal": true,
	"fated": true,
	"fates": true,
	"fatty": true,
	"favor": true,
	"faxes": true,
	"fears": true,
	"feast": true,
	"feats": true,
	"fecal": true,
	"feces": true,
	"feeds": true,
	"feels": true,
	"feign": true,
	"fella": true,
	"felon": true,
	"femur": true,
	"fence": true,
	"feral": true,
	"ferry": true,
	"fetal": true,
	"fetch": true,
	"fetus": true,
	"fever": true,
	"fewer": true,
	"fiber": true,
	"field": true,
	"fiend": true,
	"fiery": true,
	"fifth": true,
	"fifty": true,
	"fight": true,
	"filed": true,
	"files": true,
	"filet": true,
	"fills": true,
	"films": true,
	"filth": true,
	"final": true,
	"finch": true,
	"finds": true,
	"fined": true,
	"finer": true,
	"fines": true,
	"fired": true,
	"fires": true,
	"firms": true,
	"first": true,
	"fishy": true,
	"fists": true,
	"fixed": true,
	"fixer": true,
	"fixes": true,
	"fizzy": true,
	"flack": true,
	"flags": true,
	"flail": true,
	"flair": true,
	"flake": true,
	"flaky": true,
	"flame": true,
	"flank": true,
	"flarp": true,
	"flash": true,
	"flask": true,
	"flats": true,
	"flaws": true,
	"fleas": true,
	"flees": true,
	"fleet": true,
	"flesh": true,
	"flick": true,
	"flies": true,
	"fling": true,
	"flint": true,
	"flips": true,
	"flirt": true,
	"float": true,
	"flock": true,
	"flood": true,
	"floof": true,
	"floor": true,
	"flops": true,
	"flora": true,
	"floss": true,
	"flour": true,
	"flown": true,
	"flows": true,
	"fluff": true,
	"fluid": true,
	"flunk": true,
	"flush": true,
	"flute": true,
	"foamy": true,
	"focal": true,
	"focus": true,
	"foggy": true,
	"folks": true,
	"fonts": true,
	"force": true,
	"forge": true,
	"forms": true,
	"forth": true,
	"forty": true,
	"fouls": true,
	"found": true,
	"fours": true,
	"foxes": true,
	"foyer": true,
	"frail": true,
	"frame": true,
	"fraud": true,
	"frays": true,
	"freak": true,
	"freed": true,
	"frees": true,
	"fresh": true,
	"frets": true,
	"friar": true,
	"fried": true,
	"frier": true,
	"fries": true,
	"frill": true,
	"frisk": true,
	"fritz": true,
	"frizz": true,
	"front": true,
	"frost": true,
	"frown": true,
	"froze": true,
	"fruit": true,
	"fudge": true,
	"fudgy": true,
	"fuels": true,
	"fully": true,
	"fumed": true,
	"fumes": true,
	"funds": true,
	"fungi": true,
	"funky": true,
	"funny": true,
	"furry": true,
	"fussy": true,
	"futon": true,
	"fuzzy": true,
	"gabby": true,
	"gains": true,
	"gamer": true,
	"games": true,
	"gamma": true,
	"gases": true,
	"gasps": true,
	"gassy": true,
	"gauge": true,
	"gazed": true,
	"gazes": true,
	"gears": true,
	"gecko": true,
	"geeks": true,
	"geeky": true,
	"geese": true,
	"genes": true,
	"genre": true,
	"germs": true,
	"ghost": true,
	"giant": true,
	"girls": true,
	"girly": true,
	"given": true,
	"giver": true,
	"gives": true,
	"gizmo": true,
	"gland": true,
	"glare": true,
	"glass": true,
	"glaze": true,
	"gleam": true,
	"glean": true,
	"glide": true,
	"gloom": true,
	"glomp": true,
	"glued": true,
	"glues": true,
	"gnarl": true,
	"gnome": true,
	"goals": true,
	"goats": true,
	"gofer": true,
	"going": true,
	"goods": true,
	"goody": true,
	"gooey": true,
	"goofs": true,
	"goofy": true,
	"goose": true,
	"gorge": true,
	"gouge": true,
	"gourd": true,
	"grade": true,
	"graft": true,
	"grail": true,
	"grain": true,
	"grams": true,
	"grand": true,
	"grant": true,
	"grape": true,
	"graph": true,
	"grasp": true,
	"grass": true,
	"grate": true,
	"grave": true,
	"gravy": true,
	"graze": true,
	"great": true,
	"greed": true,
	"greek": true,
	"green": true,
	"greet": true,
	"grief": true,
	"grime": true,
	"grimy": true,
	"grind": true,
	"grins": true,
	"groan": true,
	"groin": true,
	"groom": true,
	"gross": true,
	"group": true,
	"grout": true,
	"growl": true,
	"grown": true,
	"grows": true,
	"grump": true,
	"grunt": true,
	"guard": true,
	"guava": true,
	"guide": true,
	"guild": true,
	"gummy": true,
	"gusts": true,
	"gyoza": true,
	"habit": true,
	"haiku": true,
	"halls": true,
	"hands": true,
	"handy": true,
	"hangs": true,
	"happy": true,
	"hardy": true,
	"harsh": true,
	"haste": true,
	"hasty": true,
	"hatch": true,
	"hated": true,
	"hater": true,
	"hates": true,
	"haunt": true,
	"haven": true,
	"havoc": true,
	"hazel": true,
	"heads": true,
	"heard": true,
	"heart": true,
	"heath": true,
	"heave": true,
	"heavy": true,
	"hedge": true,
	"hefty": true,
	"heist": true,
	"helix": true,
	"hello": true,
	"hence": true,
	"herbs": true,
	"hiked": true,
	"hiker": true,
	"hikes": true,
	"hilly": true,
	"hinge": true,
	"hippo": true,
	"hippy": true,
	"hired": true,
	"hires": true,
	"hissy": true,
	"hitch": true,
	"hobby": true,
	"hoist": true,
	"holds": true,
	"holes": true,
	"holey": true,
	"holly": true,
	"homes": true,
	"honed": true,
	"hoped": true,
	"hopes": true,
	"hoppy": true,
	"horse": true,
	"hosed": true,
	"hotel": true,
	"hound": true,
	"hours": true,
	"house": true,
	"hover": true,
	"howdy": true,
	"howls": true,
	"human": true,
	"humid": true,
	"humor": true,
	"humph": true,
	"humps": true,
	"hunch": true,
	"hunks": true,
	"hunky": true,
	"hunts": true,
	"husky": true,
	"hyena": true,
	"hyped": true,
	"hyper": true,
	"hypes": true,
	"icier": true,
	"icing": true,
	"icons": true,
	"ideal": true,
	"ideas": true,
	"idiom": true,
	"idiot": true,
	"idled": true,
	"idles": true,
	"idols": true,
	"igloo": true,
	"image": true,
	"imply": true,
	"inane": true,
	"index": true,
	"inert": true,
	"inner": true,
	"input": true,
	"inset": true,
	"ionic": true,
	"irate": true,
	"irony": true,
	"issue": true,
	"itchy": true,
	"items": true,
	"ivory": true,
	"jaded": true,
	"jaunt": true,
	"jeans": true,
	"jelly": true,
	"jerky": true,
	"jewel": true,
	"joint": true,
	"joked": true,
	"joker": true,
	"jokes": true,
	"jokey": true,
	"jolly": true,
	"jorts": true,
	"joust": true,
	"judge": true,
	"juice": true,
	"juicy": true,
	"jumpy": true,
	"juror": true,
	"karma": true,
	"kayak": true,
	"kazoo": true,
	"kebab": true,
	"kempt": true,
	"keyed": true,
	"khaki": true,
	"kicks": true,
	"kiddo": true,
	"kinds": true,
	"kings": true,
	"kiosk": true,
	"kites": true,
	"kitty": true,
	"klutz": true,
	"knack": true,
	"knead": true,
	"kneel": true,
	"knees": true,
	"knelt": true,
	"knife": true,
	"knits": true,
	"knobs": true,
	"knock": true,
	"knots": true,
	"known": true,
	"knows": true,
	"koala": true,
	"kudos": true,
	"label": true,
	"labor": true,
	"laced": true,
	"laces": true,
	"lacey": true,
	"lager": true,
	"lakes": true,
	"lamps": true,
	"lance": true,
	"lands": true,
	"lanes": true,
	"lanky": true,
	"lapel": true,
	"lapse": true,
	"large": true,
	"laser": true,
	"lasso": true,
	"lasts": true,
	"latch": true,
	"later": true,
	"latex": true,
	"lathe": true,
	"layer": true,
	"leach": true,
	"leads": true,
	"leaky": true,
	"leaps": true,
	"leapt": true,
	"learn": true,
	"lease": true,
	"leash": true,
	"least": true,
	"leave": true,
	"leech": true,
	"lefty": true,
	"legal": true,
	"lemon": true,
	"lemur": true,
	"lends": true,
	"level": true,
	"lever": true,
	"licks": true,
	"light": true,
	"liked": true,
	"liken": true,
	"lilac": true,
	"limbo": true,
	"limbs": true,
	"limit": true,
	"lined": true,
	"linen": true,
	"liner": true,
	"lines": true,
	"lingo": true,
	"links": true,
	"lions": true,
	"lists": true,
	"lived": true,
	"liven": true,
	"liver": true,
	"lives": true,
	"livid": true,
	"llama": true,
	"loans": true,
	"loath": true,
	"local": true,
	"lodge": true,
	"lofts": true,
	"lofty": true,
	"logic": true,
	"looks": true,
	"loony": true,
	"loops": true,
	"loopy": true,
	"loose": true,
	"loser": true,
	"loses": true,
	"lousy": true,
	"loved": true,
	"lover": true,
	"loves": true,
	"lovey": true,
	"lower": true,
	"loyal": true,
	"lucky": true,
	"lulls": true,
	"lunar": true,
	"lunch": true,
	"lunge": true,
	"lungs": true,
	"lurch": true,
	"lured": true,
	"lures": true,
	"lymph": true,
	"lyric": true,
	"macro": true,
	"magic": true,
	"mails": true,
	"major": true,
	"makes": true,
	"mamma": true,
	"mango": true,
	"mania": true,
	"manic": true,
	"manor": true,
	"maple": true,
	"marry": true,
	"marsh": true,
	"match": true,
	"mauve": true,
	"maxed": true,
	"maxim": true,
	"maybe": true,
	"mayor": true,
	"means": true,
	"meant": true,
	"meats": true,
	"meaty": true,
	"mecca": true,
	"medal": true,
	"media": true,
	"medic": true,
	"melds": true,
	"melon": true,
	"melts": true,
	"mercy": true,
	"merge": true,
	"merit": true,
	"merry": true,
	"messy": true,
	"metal": true,
	"meter": true,
	"midst": true,
	"might": true,
	"miles": true,
	"milky": true,
	"mince": true,
	"minds": true,
	"mined": true,
	"miner": true,
	"mines": true,
	"minor": true,
	"mints": true,
	"minty": true,
	"minus": true,
	"mists": true,
	"misty": true,
	"mixed": true,
	"mixer": true,
	"mixes": true,
	"moans": true,
	"moats": true,
	"mocha": true,
	"mochi": true,
	"modal": true,
	"model": true,
	"modem": true,
	"modes": true,
	"mogul": true,
	"moist": true,
	"molar": true,
	"molds": true,
	"moldy": true,
	"moles": true,
	"mommy": true,
	"money": true,
	"month": true,
	"moody": true,
	"moral": true,
	"morph": true,
	"motel": true,
	"motor": true,
	"motto": true,
	"mound": true,
	"mount": true,
	"mourn": true,
	"mouth": true,
	"moved": true,
	"mover": true,
	"moves": true,
	"movie": true,
	"mowed": true,
	"mower": true,
	"mucus": true,
	"mummy": true,
	"mumps": true,
	"munch": true,
	"mural": true,
	"mused": true,
	"mushy": true,
	"music": true,
	"muted": true,
	"naked": true,
	"named": true,
	"names": true,
	"nanny": true,
	"nasal": true,
	"nasty": true,
	"navel": true,
	"needs": true,
	"nerds": true,
	"nerdy": true,
	"never": true,
	"nicer": true,
	"niche": true,
	"niece": true,
	"nifty": true,
	"night": true,
	"ninja": true,
	"nitro": true,
	"noble": true,
	"noise": true,
	"noisy": true,
	"nomad": true,
	"noose": true,
	"north": true,
	"nosey": true,
	"noted": true,
	"notes": true,
	"novel": true,
	"nudge": true,
	"nuked": true,
	"nukes": true,
	"numbs": true,
	"nutty": true,
	"oasis": true,
	"obeys": true,
	"occur": true,
	"ocean": true,
	"oddly": true,
	"odors": true,
	"offer": true,
	"often": true,
	"ogres": true,
	"okays": true,
	"older": true,
	"olive": true,
	"omits": true,
	"oozed": true,
	"oozes": true,
	"opals": true,
	"opens": true,
	"opera": true,
	"orbit": true,
	"order": true,
	"organ": true,
	"other": true,
	"otter": true,
	"ought": true,
	"ounce": true,
	"outed": true,
	"outer": true,
	"ovary": true,
	"ovens": true,
	"overt": true,
	"owned": true,
	"owner": true,
	"oxide": true,
	"paced": true,
	"pacer": true,
	"paces": true,
	"packs": true,
	"pacts": true,
	"pager": true,
	"pages": true,
	"paint": true,
	"pairs": true,
	"panda": true,
	"panel": true,
	"panes": true,
	"panic": true,
	"paper": true,
	"parka": true,
	"parks": true,
	"parse": true,
	"parts": true,
	"party": true,
	"pasta": true,
	"paste": true,
	"patch": true,
	"patty": true,
	"pause": true,
	"paved": true,
	"paver": true,
	"paves": true,
	"peace": true,
	"peach": true,
	"peaks": true,
	"pearl": true,
	"pears": true,
	"pedal": true,
	"penny": true,
	"pesky": true,
	"pesto": true,
	"petal": true,
	"phase": true,
	"phone": true,
	"piano": true,
	"piece": true,
	"piggy": true,
	"piled": true,
	"piles": true,
	"pills": true,
	"pilot": true,
	"pinch": true,
	"pined": true,
	"pines": true,
	"pinky": true,
	"pinto": true,
	"pints": true,
	"piper": true,
	"pipes": true,
	"pizza": true,
	"place": true,
	"plaid": true,
	"plain": true,
	"plane": true,
	"plank": true,
	"plans": true,
	"plant": true,
	"plate": true,
	"plays": true,
	"pleat": true,
	"plops": true,
	"plots": true,
	"plums": true,
	"plush": true,
	"poems": true,
	"point": true,
	"poise": true,
	"poked": true,
	"poker": true,
	"polar": true,
	"polka": true,
	"polls": true,
	"ponds": true,
	"pooch": true,
	"poppy": true,
	"porch": true,
	"pores": true,
	"posed": true,
	"poser": true,
	"poses": true,
	"potty": true,
	"pouch": true,
	"pound": true,
	"pours": true,
	"pouts": true,
	"power": true,
	"prawn": true,
	"press": true,
	"price": true,
	"pride": true,
	"pried": true,
	"prime": true,
	"primp": true,
	"print": true,
	"prior": true,
	"probe": true,
	"prone": true,
	"prong": true,
	"proof": true,
	"props": true,
	"prose": true,
	"prove": true,
	"prowl": true,
	"proxy": true,
	"prude": true,
	"prune": true,
	"psych": true,
	"puffy": true,
	"pulpy": true,
	"pulse": true,
	"punch": true,
	"puppy": true,
	"purse": true,
	"pushy": true,
	"quack": true,
	"quake": true,
	"qualm": true,
	"quart": true,
	"queen": true,
	"quick": true,
	"quiet": true,
	"quill": true,
	"quilt": true,
	"quips": true,
	"quirk": true,
	"quite": true,
	"quota": true,
	"quote": true,
	"raced": true,
	"racer": true,
	"races": true,
	"racks": true,
	"radar": true,
	"radii": true,
	"radio": true,
	"radon": true,
	"rafts": true,
	"raged": true,
	"rages": true,
	"rails": true,
	"rains": true,
	"rainy": true,
	"raise": true,
	"raked": true,
	"rally": true,
	"ramen": true,
	"ranch": true,
	"range": true,
	"ranks": true,
	"rants": true,
	"rapid": true,
	"rates": true,
	"ratio": true,
	"raven": true,
	"razor": true,
	"reach": true,
	"react": true,
	"reads": true,
	"ready": true,
	"realm": true,
	"reams": true,
	"reaps": true,
	"rebel": true,
	"refer": true,
	"regal": true,
	"reign": true,
	"relic": true,
	"remix": true,
	"repay": true,
	"repel": true,
	"reply": true,
	"retro": true,
	"retry": true,
	"reuse": true,
	"rhino": true,
	"rhyme": true,
	"ridge": true,
	"rifle": true,
	"rifts": true,
	"right": true,
	"rigid": true,
	"rigor": true,
	"rinse": true,
	"ripen": true,
	"riper": true,
	"risen": true,
	"riser": true,
	"rises": true,
	"risks": true,
	"risky": true,
	"rival": true,
	"river": true,
	"roast": true,
	"robot": true,
	"rocks": true,
	"rocky": true,
	"rodeo": true,
	"roles": true,
	"rooms": true,
	"roomy": true,
	"roost": true,
	"roots": true,
	"rough": true,
	"round": true,
	"route": true,
	"rower": true,
	"royal": true,
	"rugby": true,
	"ruins": true,
	"ruled": true,
	"ruler": true,
	"rules": true,
	"rumor": true,
	"runts": true,
	"rural": true,
	"rusts": true,
	"rusty": true,
	"saber": true,
	"sadly": true,
	"saggy": true,
	"salad": true,
	"sales": true,
	"salsa": true,
	"salts": true,
	"salty": true,
	"sandy": true,
	"sassy": true,
	"sauce": true,
	"saucy": true,
	"sauna": true,
	"saved": true,
	"saver": true,
	"saves": true,
	"savor": true,
	"savvy": true,
	"scald": true,
	"scale": true,
	"scant": true,
	"scary": true,
	"scene": true,
	"scent": true,
	"scoff": true,
	"scold": true,
	"scone": true,
	"scoop": true,
	"scoot": true,
	"scope": true,
	"score": true,
	"scorn": true,
	"scour": true,
	"scout": true,
	"scowl": true,
	"scrap": true,
	"screw": true,
	"scrub": true,
	"scrum": true,
	"scuff": true,
	"sculp": true,
	"seams": true,
	"seats": true,
	"sedan": true,
	"seems": true,
	"segue": true,
	"sense": true,
	"sepia": true,
	"serif": true,
	"serve": true,
	"setup": true,
	"seven": true,
	"sever": true,
	"sewer": true,
	"shack": true,
	"shade": true,
	"shady": true,
	"shaft": true,
	"shaky": true,
	"shall": true,
	"shame": true,
	"shape": true,
	"shard": true,
	"shart": true,
	"share": true,
	"shark": true,
	"sharp": true,
	"shave": true,
	"shawl": true,
	"shear": true,
	"sheds": true,
	"sheen": true,
	"sheep": true,
	"sheer": true,
	"sheet": true,
	"shelf": true,
	"shell": true,
	"shift": true,
	"shine": true,
	"shiny": true,
	"shock": true,
	"shoes": true,
	"shone": true,
	"shook": true,
	"short": true,
	"shove": true,
	"shown": true,
	"shows": true,
	"showy": true,
	"shred": true,
	"shrew": true,
	"shrub": true,
	"shrug": true,
	"shuns": true,
	"shush": true,
	"sided": true,
	"sides": true,
	"sifts": true,
	"sight": true,
	"sigma": true,
	"signs": true,
	"silky": true,
	"silly": true,
	"since": true,
	"siren": true,
	"sites": true,
	"sixth": true,
	"sixty": true,
	"sized": true,
	"sizer": true,
	"sizes": true,
	"skate": true,
	"skies": true,
	"skits": true,
	"skunk": true,
	"slack": true,
	"slang": true,
	"slash": true,
	"slate": true,
	"slats": true,
	"sleek": true,
	"sleep": true,
	"slice": true,
	"slick": true,
	"slide": true,
	"slime": true,
	"slimy": true,
	"sling": true,
	"slink": true,
	"slobs": true,
	"sloth": true,
	"slump": true,
	"slurp": true,
	"smack": true,
	"small": true,
	"smash": true,
	"smear": true,
	"smell": true,
	"smelt": true,
	"smile": true,
	"smirk": true,
	"smock": true,
	"smoke": true,
	"smurf": true,
	"snack": true,
	"snafu": true,
	"snags": true,
	"snail": true,
	"snake": true,
	"snaps": true,
	"snare": true,
	"snark": true,
	"snarl": true,
	"sneak": true,
	"sniff": true,
	"snore": true,
	"snort": true,
	"snout": true,
	"snowy": true,
	"sober": true,
	"socks": true,
	"soggy": true,
	"soils": true,
	"solid": true,
	"songs": true,
	"sonic": true,
	"sorry": true,
	"sorts": true,
	"sound": true,
	"soups": true,
	"soupy": true,
	"south": true,
	"space": true,
	"spade": true,
	"spank": true,
	"spans": true,
	"spare": true,
	"spark": true,
	"spars": true,
	"spasm": true,
	"spawn": true,
	"speak": true,
	"spear": true,
	"speck": true,
	"speed": true,
	"spell": true,
	"spelt": true,
	"spend": true,
	"spent": true,
	"spice": true,
	"spicy": true,
	"spied": true,
	"spike": true,
	"spiky": true,
	"spill": true,
	"spilt": true,
	"spine": true,
	"spins": true,
	"spiny": true,
	"spire": true,
	"spite": true,
	"spits": true,
	"split": true,
	"spoke": true,
	"spoof": true,
	"spook": true,
	"spool": true,
	"spoon": true,
	"spore": true,
	"spots": true,
	"spout": true,
	"spray": true,
	"spree": true,
	"spurs": true,
	"squad": true,
	"squat": true,
	"squid": true,
	"stack": true,
	"staff": true,
	"stage": true,
	"stair": true,
	"stake": true,
	"stale": true,
	"stalk": true,
	"stamp": true,
	"stand": true,
	"stank": true,
	"stare": true,
	"stark": true,
	"stars": true,
	"start": true,
	"stash": true,
	"state": true,
	"stats": true,
	"stays": true,
	"stead": true,
	"steak": true,
	"steal": true,
	"steam": true,
	"steed": true,
	"steel": true,
	"steep": true,
	"steer": true,
	"stems": true,
	"stent": true,
	"steps": true,
	"stern": true,
	"stews": true,
	"stick": true,
	"stiff": true,
	"still": true,
	"stilt": true,
	"sting": true,
	"stink": true,
	"stint": true,
	"stipe": true,
	"stock": true,
	"stoic": true,
	"stoke": true,
	"stole": true,
	"stomp": true,
	"stone": true,
	"stony": true,
	"stood": true,
	"stool": true,
	"stoop": true,
	"store": true,
	"stork": true,
	"storm": true,
	"story": true,
	"strap": true,
	"straw": true,
	"stray": true,
	"strum": true,
	"strut": true,
	"stubs": true,
	"stuck": true,
	"studs": true,
	"study": true,
	"stuff": true,
	"stump": true,
	"stung": true,
	"stunk": true,
	"stuns": true,
	"stunt": true,
	"style": true,
	"suave": true,
	"suede": true,
	"sugar": true,
	"sulky": true,
	"super": true,
	"surge": true,
	"sushi": true,
	"swamp": true,
	"swarm": true,
	"swear": true,
	"sweat": true,
	"sweep": true,
	"swell": true,
	"swept": true,
	"swift": true,
	"swims": true,
	"swine": true,
	"swipe": true,
	"swirl": true,
	"swish": true,
	"swoop": true,
	"sword": true,
	"swore": true,
	"sworn": true,
	"syren": true,
	"syrup": true,
	"table": true,
	"taboo": true,
	"tacky": true,
	"tacos": true,
	"taint": true,
	"taken": true,
	"takes": true,
	"talon": true,
	"taped": true,
	"tapes": true,
	"tardy": true,
	"tarry": true,
	"tarts": true,
	"tases": true,
	"tasks": true,
	"taste": true,
	"tasty": true,
	"taxed": true,
	"taxer": true,
	"taxes": true,
	"taxis": true,
	"teach": true,
	"tears": true,
	"teary": true,
	"tease": true,
	"teeny": true,
	"teeth": true,
	"tempt": true,
	"tenor": true,
	"tense": true,
	"tenth": true,
	"tents": true,
	"terms": true,
	"tests": true,
	"texts": true,
	"thank": true,
	"theft": true,
	"their": true,
	"theme": true,
	"there": true,
	"these": true,
	"thick": true,
	"thief": true,
	"thigh": true,
	"thing": true,
	"think": true,
	"thins": true,
	"third": true,
	"those": true,
	"three": true,
	"threw": true,
	"throw": true,
	"thyme": true,
	"tidal": true,
	"tided": true,
	"tides": true,
	"tiers": true,
	"tiger": true,
	"tight": true,
	"tilts": true,
	"timed": true,
	"timer": true,
	"times": true,
	"timid": true,
	"tinge": true,
	"tippy": true,
	"tipsy": true,
	"tired": true,
	"tires": true,
	"titan": true,
	"title": true,
	"toast": true,
	"today": true,
	"token": true,
	"tombs": true,
	"toned": true,
	"tones": true,
	"tonic": true,
	"tools": true,
	"topic": true,
	"torch": true,
	"torso": true,
	"total": true,
	"totem": true,
	"touch": true,
	"towel": true,
	"tower": true,
	"toxic": true,
	"toxin": true,
	"toyed": true,
	"trace": true,
	"track": true,
	"tract": true,
	"trade": true,
	"trail": true,
	"train": true,
	"trait": true,
	"tramp": true,
	"trash": true,
	"treat": true,
	"trees": true,
	"trend": true,
	"trial": true,
	"tribe": true,
	"tried": true,
	"trims": true,
	"tripe": true,
	"trips": true,
	"trite": true,
	"troop": true,
	"truck": true,
	"truly": true,
	"trunk": true,
	"trust": true,
	"truth": true,
	"tulip": true,
	"tummy": true,
	"tumor": true,
	"tuned": true,
	"tuner": true,
	"tunes": true,
	"tunic": true,
	"turbo": true,
	"turns": true,
	"tutor": true,
	"tweak": true,
	"tweet": true,
	"twice": true,
	"twigs": true,
	"twill": true,
	"twink": true,
	"twist": true,
	"typed": true,
	"types": true,
	"udder": true,
	"ulcer": true,
	"ultra": true,
	"under": true,
	"undid": true,
	"unify": true,
	"union": true,
	"unite": true,
	"units": true,
	"unity": true,
	"unpin": true,
	"untie": true,
	"until": true,
	"upend": true,
	"upper": true,
	"upset": true,
	"urban": true,
	"urged": true,
	"urges": true,
	"urine": true,
	"usage": true,
	"users": true,
	"usher": true,
	"using": true,
	"usual": true,
	"utter": true,
	"vague": true,
	"valet": true,
	"valid": true,
	"valor": true,
	"value": true,
	"valve": true,
	"vapor": true,
	"vases": true,
	"vault": true,
	"veers": true,
	"vegan": true,
	"veins": true,
	"veiny": true,
	"venom": true,
	"vents": true,
	"venue": true,
	"verbs": true,
	"verge": true,
	"verse": true,
	"vials": true,
	"video": true,
	"views": true,
	"vinyl": true,
	"viola": true,
	"viper": true,
	"viral": true,
	"virus": true,
	"visit": true,
	"visor": true,
	"vista": true,
	"vital": true,
	"vocal": true,
	"vodka": true,
	"voice": true,
	"vomit": true,
	"voted": true,
	"voter": true,
	"votes": true,
	"vouch": true,
	"vowed": true,
	"vowel": true,
	"waded": true,
	"wafer": true,
	"wager": true,
	"wages": true,
	"wagon": true,
	"wails": true,
	"waist": true,
	"waits": true,
	"waive": true,
	"walls": true,
	"waltz": true,
	"wants": true,
	"wards": true,
	"warns": true,
	"wasps": true,
	"waste": true,
	"watch": true,
	"water": true,
	"waved": true,
	"waver": true,
	"waves": true,
	"waxed": true,
	"weary": true,
	"weave": true,
	"wedge": true,
	"weeds": true,
	"weeks": true,
	"weeps": true,
	"weepy": true,
	"weird": true,
	"wells": true,
	"whack": true,
	"whale": true,
	"wharf": true,
	"wheat": true,
	"wheel": true,
	"where": true,
	"which": true,
	"while": true,
	"whine": true,
	"whirl": true,
	"whisk": true,
	"white": true,
	"whole": true,
	"whose": true,
	"wicks": true,
	"widen": true,
	"wider": true,
	"wides": true,
	"widow": true,
	"width": true,
	"wield": true,
	"wilts": true,
	"wimps": true,
	"wimpy": true,
	"wince": true,
	"winds": true,
	"windy": true,
	"wines": true,
	"wings": true,
	"wiped": true,
	"wiper": true,
	"wipes": true,
	"wired": true,
	"wires": true,
	"wiser": true,
	"witch": true,
	"witty": true,
	"woman": true,
	"women": true,
	"woods": true,
	"woody": true,
	"woozy": true,
	"words": true,
	"wordy": true,
	"works": true,
	"world": true,
	"worms": true,
	"wormy": true,
	"worry": true,
	"worse": true,
	"worst": true,
	"worth": true,
	"would": true,
	"wound": true,
	"woven": true,
	"wreck": true,
	"wrist": true,
	"write": true,
	"wrong": true,
	"wrote": true,
	"yacht": true,
	"yanks": true,
	"yappy": true,
	"yards": true,
	"yearn": true,
	"years": true,
	"yeast": true,
	"yield": true,
	"yodel": true,
	"young": true,
	"youth": true,
	"yummy": true,
	"zesty": true,
	"zippy": true,
	"zoned": true,
	"zones": true,
	"zooms": true
}

export default humanWords;