// TODO: 'interfaces' isn't exactly the correct name for this file's folder
enum MatchOutcome {
	HOST_WIN = 'hostWin',
	GUEST_WIN = 'guestWin',
	HOST_FORFEIT = 'hostForfeit',
	GUEST_FORFEIT = 'guestForfeit',
    DRAW = 'draw'
}

export default MatchOutcome;
