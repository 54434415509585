var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import styles from './style.module.css';
export default function Default(_a) {
    var _b = _a.color, color = _b === void 0 ? '#7f58af' : _b, _c = _a.size, size = _c === void 0 ? 80 : _c, className = _a.className, style = _a.style, rest = __rest(_a, ["color", "size", "className", "style"]);
    var circles = __spreadArray([], Array(12)).map(function (_, index) { return (_jsx("div", { style: { background: "" + color, width: size * 0.075, height: size * 0.075 } }, index)); });
    return (_jsx("div", __assign({ className: classNames(styles['lds-default'], className), style: __assign({ height: size, width: size }, style) }, rest, { children: circles }), void 0));
}
